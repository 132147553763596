@import '../../../styles/variable.scss';

.financeComplete-container {
  display: flex;
  flex-direction: row;

  .financeComplete-section {
    background: url('../../../assets/image/Waitpage.svg') center 40% / auto no-repeat;
    background-color: $colorBackground;
    background-color: $colorWhite;
    display: none;

    @media screen and (min-width: 768px) {
      height: calc(100vh - 80px);
      display: block;
    }
  }

  .documentComplete-section {
    background: url('../../../assets/image/document-complete.svg') center 40% / auto no-repeat;
    background-color: $colorBackground;
    background-color: $colorWhite;
    display: none;

    @media screen and (min-width: 768px) {
      height: calc(100vh - 80px);
      display: block;
    }
  }

  .financeComplete-page-details {
    .financeComplete-message {
      padding-top: 1.5rem;

      @media screen and (min-width: 768px) {
        padding: 1.5rem;
      }

      .font-emphasis {
        font-family: 'Poppins-Bold';
        font-size: 32px;
        font-weight: bold;
        line-height: 1.5;

        @media screen and (min-width: 768px) {
          font-size: 42px;
        }
      }

      .financeComplete-name {
        color: $colorLightBlue;
      }

      .documentComplete-message {
        color: $colorBlack;
      }

      .financeComplete-title {
        color: $colorDarkGrey;
        margin-bottom: 2rem;
      }

      .documentComplete-desc {
        font-family: 'Poppins-Regular';
        display: block;
        color: $colorDarkGrey;
        line-height: 1.5rem;
        font-size: 20px;
        margin: 2rem 4rem 2rem 0;
      }

      .financeComplete-subtitle {
        font-family: 'Poppins-Regular';
        font-size: 24px;
        font-weight: 500;
        color: $colorSubtitle;
        line-height: 1;
      }

      .financeComplete-listname {
        font-family: 'Poppins-Regular';
        font-size: 18px;
        color: $colorDarkGrey;
        line-height: 1;
      }
    }
  }
}