@import '../../styles/variable.scss';
.text-field {
  border-radius: 3px;
  border: solid 1px #bebebf;
  background-color: #ffff;
}

.btn-align {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .btn-search {
    border-radius: 4px;
    background-color: #00c1d5;
    color: #ffff;
  }
}
.amount-align {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: $colorDarkGrey;
  border: solid 3px #00aabb;
  background-color: #ffff;
  border-radius: 8px;
  text-align: center;
}
.currency-prefix {
  padding-right: 8px;
  font-size: 50%;
  top: -0.7em;
}
.loan-amount {
  color: #00aabb;
  font-family: 'Poppins-Bold';
  font-size: 42px;
  line-height: 1;
  border: none;
}
.input-group {
  .input-group-prepend {
    .maginifying-btn {
      padding: 0.375rem 0.75rem;
      position: inherit;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      // background-color: $colorBackground;
      background-clip: padding-box;
      border: none;
      transition: border-color 0.15s;
      border-right: 0;
    }
  }
}
.input-search {
  border-left: 0;
  background-color: $colorBackground;
}

.btn-link {
  color: #00aabb;
  font-size: 15px;
  font-family: 'Poppins-Medium';
  white-space: nowrap;
  &.account-exist-login {
    color: #0d6efd;
    font-size: 80%;
    font-family: 'Poppins-Regular';
    text-decoration: underline;
  }
  @media screen and (min-width: 600px) {
    font-size: 17px;
    font-family: 'Poppins-SemiBold';
  }
}

.btn-link-custom {
  color: $colorDarkGrey;
  font-size: 15px;
  font-family: 'Poppins-Medium';
  white-space: nowrap;
  background-color: #f3f7fa;
  &.account-exist-login {
    color: #0d6efd;
    font-size: 80%;
    font-family: 'Poppins-Regular';
    text-decoration: underline;
  }
  @media screen and (min-width: 600px) {
    font-size: 12px;
    font-family: 'Poppins-SemiBold';
  }
}
.btn-link-box-custom {
  padding: 20px 0px;
  border: 1px dashed #c2c6c8;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.doc-label {
  margin-bottom: 1rem;
}

.oi {
  color: $colorPrimaryBlue;
}

.btn-Next {
  color: #ffff;
  position: absolute;
  bottom: 5px;
  background-color: #00aabb;
  right: 30px;
  background-color: #00aabb;
}

.form-group {
  .form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.MuiGrid-container {
  .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 8px;
  }
  .MuiGrid-item {
    .MuiSlider-root {
      height: 20px;
      border-radius: 3px;
      .MuiSlider-rail {
        height: 4px;
        background-color: #e5e7e7;
        border-radius: 3px;
      }
      .MuiSlider-track {
        height: 4px;
        border-radius: 3px;
        background-color: #ffff;
      }
    }

    .MuiSlider-thumb {
      box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
      border: solid 1px rgba(255, 255, 255, 0.7);
      background-image: linear-gradient(to bottom, var(--white), #f9fafb);
    }
  }
}
.show-grid {
  .checkbox-section {
    .checkbox-container {
      display: block;
      position: relative;
      padding-left: 44px;
      padding-top: 6px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-family: 'Poppins-Regular';
      font-size: 14px;
      color: $colorDarkGrey;
    }
    .checkbox-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0px;
      left: -8px;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
      border: solid 1px #bebebf;
      background-image: linear-gradient(to bottom, #fff, #f9fafb);
    }
    .checkbox-container:hover input ~ .checkmark {
      opacity: 0.8;
    }

    /* When the checkbox is checked, add a blue background */
    .checkbox-container input:checked ~ .checkmark {
      border-color: #00aabb;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .checkbox-container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkbox-container .checkmark:after {
      left: 7px;
      top: 1px;
      width: 8px;
      height: 14px;
      border: solid #00aabb;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
.factory-footer {
  border-radius: 3px;
  border: solid 1px #a4a7aa !important;
  height: 3rem;
  font-family: 'Poppins-Medium';
  font-size: 14px;
  text-align: center;
  color: $colorDarkGrey !important;
  background-color: #ffffff !important;
}
