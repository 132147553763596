@import '../../styles/variable.scss';
.fieldset-section {
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 30px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
  .fieldset-label {
    color: #66696c;
    font-family: 'Poppins-Regular';
    font-size: 15px;
    letter-spacing: 0;
    line-height: 33px;
    width: auto;
  }
  .privacy-consent {
    max-height: 80px;
    overflow-y: auto;
    .privacy-collection-header {
      font-family: 'Poppins-SemiBold';
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      width: auto;
      color: $colorDarkGrey;
    }
    .privacy-collection {
      font-size: 12px;
      font-family: 'Poppins-Medium';
      color: $colorDarkGrey;
      line-height: 20px;
    }
    .privacy-content {
      font-size: 12px;
      font-family: 'Poppins-Regular';
      color: #66696c;
      line-height: 20px;
    }
    .general-consent {
      font-size: 12px;
      font-family: 'Poppins-Medium';
      color: $colorDarkGrey;
      line-height: 20px;
    }
    .btn-policy-path {
      color: #66696c;
      font-family: 'Poppins-SemiBold';
      white-space: nowrap;
      text-decoration: underline;
    }
  }
}
