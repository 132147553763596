@import '../../../styles/variable.scss';

.btn-upload-next {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 12px;
    border-top: 1px solid #f1f2f2;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
        padding: 20px 24px;
    }

    .btn-next-page {
        padding: 12px 22px;
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        background-color: $colorPrimaryBlue;
        white-space: nowrap;


        &:active {
            background-color: $colorPrimaryBlue !important;
            opacity: 0.6;
        }

        &:focus,
        &:hover {
            background-color: $colorhover !important;
            opacity: 0.6;
        }

        .right-icon {
            height: 8px;
            width: 8px;
            margin-left: 10px;
        }
    }
}