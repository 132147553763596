@import 'open-iconic/font/css/open-iconic-bootstrap.scss';
@import '../assets/fonts/poppins.scss';
@import './variable.scss';
@import '../app/components/Stepper/stepper.scss';

.body-wrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  background-color: $colorBackground;

  .estimation-loading-container {
    flex-grow: 1;
  }

  .btn-primary-call-to-action,
  .btn-next-page {
    background-color: $colorPrimaryBlue;
    font-family: 'Poppins-Medium';
    border-color: transparent;

    &.disabled {
      background-color: $colorButtonDisable !important;
    }

    &:not(:disabled):hover {
      background-color: $colorhover !important;
    }

    &:not(:disabled):active,
    &:not(:disabled):focus {
      background-color: $colorPrimaryBlue !important;
      opacity: 0.6;
    }
  }
}

.modal-backdrop {
  &.show {
    z-index: 2000;
  }
}

div[role='dialog'],
div[role='presentation'] {
  z-index: 2000;
}

div[role='tooltip'] {
  z-index: 2500;
}

.container {
  font-family: 'Poppins-Regular';
  line-height: 20px;
  letter-spacing: 0;
  color: $colorText;
  max-width: 1330px;

  @media screen and (max-width: 767.95px) {
    padding-left: 12px;
    padding-right: 12px;
  }

  &.view-port-container {
    // min-height: calc(100vh - 200px);
    flex-grow: 1;
  }

  &.estimate-conatiner {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }

  .content-body {
    background-color: $colorWhite;
    //box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 2px;
  }

  /* @media (max-width: 1199.98px) {
    max-width: 100%;
  } */
  .carcondition {
    font-family: 'Poppins-SemiBold';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $colorDarkGrey;
  }

  .content-wrapper {
    background-color: $colorLightGrey;

    @media screen and (min-width: 960px) {
      display: flex;
    }

    &.compare-loan-wrapper {
      display: block;

      @media screen and (min-width: 1099px) {
        display: flex;
      }
    }

    .sidebar {
      max-width: 300px;
      top: 74px;
      padding-right: 0px !important;
      z-index: auto;
      flex: 1;
      padding-top: 30px !important;
      height: 100%;

      @media screen and (max-width: 600px) {
        display: none;
        max-width: 100vw;
        width: 100vw;
        position: sticky;
      }
    }

    // changed from  120vh

    .content-container {
      //margin-left: 260px;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      flex: 1;

      &.content-container-wrapper {
        margin-top: 12px;
        margin-bottom: 50px;

        @media screen and (min-width: 980px) {
          max-width: 920px;
          margin: 30px auto 150px;
        }
      }

      .dashboard-spinner {
        text-align: center;
      }

      .page-header-container {
        position: initial;
        top: 115px;
        background-color: $colorLightGrey;
        z-index: 100;
        padding: 12px;

        @media screen and (min-width: 600px) {
          top: 74px;
          padding: 24px !important;
        }

        .page-header {
          font-family: 'Poppins-SemiBold';
          font-size: 1.75rem;
          line-height: 32px;
          letter-spacing: 0;
        }

        .page-description {
          color: $colorDisabledGrey;
        }
      }

      .content {
        border-radius: 3px;

        .sub-container-section {}
      }
    }

    & .estimation-content-section {
      padding-left: 0;
      padding-right: 0;
    }

    &.personaldetails-content-section {
      @media (min-width: 768px) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .lender-option-nav {
      display: none;
    }

    .stepper-compare-loan {
      display: none;

      @media screen and (max-width: 1099px) {
        display: block;
      }
    }
  }

  .container-footer {
    .car-applicants-footer-wrap {
      margin-bottom: 0;
    }

    .justify-content-end {
      .btn-next-page {
        //background-color: $colorPrimaryBlue;
        border-color: transparent;
        border: none;
        color: #fff;
        height: 3rem;
        padding: 0px 16px 0px 16px;
        white-space: nowrap;

        .right-icon {
          height: 12px;
        }

        &.disabled {
          background-color: $colorButtonDisable !important;
        }

        &.hover {
          background-color: $colorhover !important;
        }

        &.active,
        &.focus {
          background-color: $colorPrimaryBlue !important;
          opacity: 0.6;
        }
      }

      .btn-navigate-page {
        /* width: 211px; */
        height: 3rem;
        border-radius: 4px;
        background-color: $colorGreenSuccess;
        color: white;
        border: solid 1px $colorGreenSuccess;
        padding: 0px 32px 0px 32px;
      }

      .right-icon {
        //transform: rotate(180deg);
        height: 16px;
        padding-left: 2px;
      }
    }
  }
}

.skip-action-container {
  &.hidden {
    display: none;
  }

  .skip-action-section {
    position: fixed;
    bottom: 84px;
    width: 100%;
    z-index: 1;
    background-color: $colorBackground;
    justify-content: flex-start;
    max-width: 650px;
    margin-left: 55px;

    @media (max-width: 952.95px),
    (min-width: 992px) and (max-width: 1049.95px) {
      //width: 100%;
      max-width: 485px;
    }

    @media screen and (max-width: 767.95px) {
      justify-content: flex-start;
      // max-width: 90%;
    }

    @media (max-width: 641.95px) {
      max-width: 316px;
    }

    @media (max-width: 600px) {
      max-width: initial;
      width: calc(100% - 24px);
    }

    @media screen and (max-width: 597.95px) {
      margin-left: -56px;
    }

    .skip-action-align {
      text-align: right;
      padding: 16px 0;

      .notsure {
        margin-right: 30px;
        text-transform: capitalize;
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        color: $colorDarkGrey;
      }

      .btn-skip-page {
        // color: $colorSecondaryBlue;
        background-color: $colorSecondaryGrey;
        text-transform: capitalize;
        height: 41px;
        border-radius: 3px;

        &:hover,
        &:active {
          opacity: 0.6;
        }

        .skip-car-details {
          margin-left: 12px;
        }
      }

      &.contained-skip-align {
        text-align: end;
        padding: 0;
      }
    }

    &.contained-section {
      position: static;
      max-width: 100%;
      padding-right: 10%;
      width: 100%;
      margin-left: 0;
      left: 0;
      bottom: 0px;
      background-color: #ffff;
      // @media screen and (min-width: 600px) {
      //   padding-right: 30%;
      // }

      // @media screen and (min-width: 768px) {
      //   bottom: 74px;
      // }
    }
  }
}

.btn-primary {
  background-color: $colorPrimaryBlue;
  border: 1px solid $colorPrimaryBlue;
  border-color: transparent;
  color: $colorWhite;
  height: 3rem; //14jan changes
  padding: 0px 16px 0px 16px;

  &:disabled {
    background-color: $colorInactive;
  }

  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: rgba(0, 193, 213, 0.1) !important;
    color: $colorInactive;
    opacity: 0.8;
    border: 1px solid #bebebf;
  }
}

.price-slider-input {
  //height: 45px;
  border-radius: 3px;
  border: solid 1px #bebebf;
  background-color: #ffffff;
  text-align: center;
  margin: 0;

  &.term-slider-input {
    text-align: start;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: $colorDarkGrey;
  }

  &.invalid-min {
    color: red;
    border-color: red;
  }
}

.icon-info {
  background: transparent top center no-repeat;
  background-image: url('../assets/image/icon_info_v2.svg');
  width: 14px;
  height: 14px;
}

.info-container {
  position: relative;
  bottom: 0;
  border-top: 1px solid $colorTail;
  border-bottom: 1px solid $colorTail;
  display: flex;

  .icon-alert {
    background: transparent top center no-repeat;
    background-image: url('../assets/image/icon_alert.svg');
    width: 50px;
    height: 50px;
  }

  .info-content {
    .info-header {
      text-transform: uppercase;
      font-family: 'Poppins-SemiBold';
      font-size: 14px;
    }

    .info-body {
      font-family: 'Poppins-Regular';
      font-size: 14px;
      color: $colorDisabledGrey;
    }
  }
}

.footer-main {
  min-height: 12vh;
  background-color: $colorWhite;

  .footer-content {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -6px 6px 0 rgba(99, 115, 129, 0.05);
    background-color: #ffff;
    bottom: 0;
    z-index: 2000;

    .footer-content-body {
      font-family: 'Poppins-Regular';
      font-size: 15px;
      font-style: normal;
      color: #66686a;
      padding: 0;

      .footer-section {
        align-items: baseline;

        .display-logo {
          margin-left: 12%;

          @media screen and (max-width: 767.95px) {
            margin-left: 0;
          }
        }

        .align-center {
          text-align: center;
        }

        .repayment-container {
          text-align: center;
          line-height: 1;

          @media screen and (min-width: 767.95px) {
            margin-bottom: 0;
          }

          .range-value {
            font-family: 'Poppins-SemiBold';
            font-size: 28px;
            color: $colorDarkGrey;

            @media screen and (max-width: 767.95px) {
              font-size: 20px;
            }

            .currency-prefix {
              top: 5px;
              font-family: 'Poppins-Bold';
              font-size: 16px;
              color: #00aabb;
            }
          }
        }

        .controlled-show-section {
          @media screen and (max-width: 767.95px) {
            display: none;

            &.override-show {
              display: block;
              margin-top: 10px;
            }
          }
        }

        .toggle-details-action {
          position: fixed;
          left: calc(50% - 36px);
          bottom: 58px;
          // background-color: #330072;
          // color: white;
          background-color: #ffff;
          color: black;
          border-radius: 0;
          z-index: 1200;
          padding: 0 24px;

          &.is-expanded {
            bottom: 140px;
          }

          @media screen and (min-width: 768px) {
            display: none;
          }
        }

        .editloanamountbtn {
          //height: 36px;
          border-radius: 3px;
          border: solid 1px #bebebf;
          color: $colorDarkGrey;
          background-color: #ffffff;
          white-space: nowrap;
        }
      }

      .nav-footer-container {
        // display: flex;
        // justify-content: center;
        // flex-flow: row;
        // flex-direction: row;
        // position: relative;
        // float: right;
        // left: 40%;

        display: none;

        .nav-footer-prevpage {
          display: flex;
          flex-flow: row;

          .btn-prevtab {
            height: 3rem;
            border-radius: 4px;
            border: solid 1px #bebebf;
            font-family: 'Poppins-Medium';
            font-size: 14px;
            font-weight: 500;
            color: $colorDarkGrey;

            .left-icon {
              /*  position: absolute;
                left: 31px;
                top: 22%; */
            }
          }

          .btn-nexttab {
            height: 3rem;
            //position: absolute;
            border-radius: 4px;
            background-color: $colorPrimaryBlue;
            color: #ffffff;
            font-family: 'Poppins-Medium';
            font-size: 14px;
            font-weight: 500;

            .right-icon {
              height: 16px;
            }
          }

          .nav-footer-nextpage {}
        }
      }
    }
  }
}

.btn-outline-dark {
  height: 3rem;
  flex-wrap: nowrap;
}

.mandatory {
  color: red;
  padding: 10px;
  font-family: 'Poppins-Medium';
}

.date-picker-popover {
  z-index: 2000 !important;
}

.invalid-feedback {
  display: block;
}

.btn-tooltip {
  border-radius: 3px;
  padding: 0 5px;
  background-color: $colorSecondaryGrey;
  color: #000000;
  height: 24px;
  border-color: transparent;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.show-warning {
  border-radius: 3px;
  padding: 0 5px;
  background: #f4cc72;
  color: #000000;
  height: 24px;
  border-color: transparent;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.tooltip-mobile {
  background: url('../assets/image/Question.svg') center 50% / auto no-repeat;
  width: 30px;
  height: 30px;
}

.form-control {
  height: 48px;
  border-radius: 3px;
  // background-color: #ffffff;
  font-family: 'Poppins-Regular';
  font-size: 15px;
  border: 1px solid #c2c6c8;
}

.serverErrorblock {
  display: flex;
  flex-direction: column;
  align-items: center;

  .serverError {
    width: 125px;
    height: 120px;
    left: 25px;
    top: 0px;
    margin: 40px 0px;

    @media screen and (min-width: 768px) {
      width: 291px;
      height: 240px;
      margin: 80px 0px;
    }
  }

  .server-error-message {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    text-align: center;
    color: $colorDarkGrey;

    @media screen and (min-width: 768px) {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.quote-estimate-edit {
  padding-left: 22px;
  position: absolute;
  right: 20px;
  line-height: 16px;
  border-radius: 3px;
  color: $colorDarkGrey;
  font-family: 'Poppins-Medium';
  font-size: 14px;
  font-weight: 500;
  background: #f3f7fa url('../assets/image/Edit_Dashboard.svg') center left/auto no-repeat;
  background-size: 16px;
  height: 30px;
  width: 74px;
}

.btn-prev-page {
  padding: 12px 22px;
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: $colorBackground;
  color: $colorSubtitle;

  &:active {
    background-color: $colorButtonDisable !important;
    opacity: 0.6;
  }

  &:focus,
  &:hover {
    background-color: $colorButtonDisable !important;
    opacity: 0.6;
  }
}

.btn-add-section {
  background: #eef2f5;
  border-radius: 3px;
  padding: 7px 14px;
  margin-top: 10px;
  margin-bottom: 10px;

  &.disabled {
    background-color: $colorButtonDisable !important;
  }

  &:not(:disabled):hover {
    background-color: transparent !important;
  }

  &:not(:disabled):active,
  &:not(:disabled):focus {
    background-color: transparent !important;
    opacity: 0.6;
  }

  .add-details {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $colorDarkGrey;
    margin-bottom: 10px;
  }
}

.numberCircle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 6px;

  background: #00c1d5;
  border: 1px solid #00c1d5;
  color: white;
  text-align: center;

  font: 14px Arial, sans-serif;
  margin-right: 10px;
}

.step-header {
  background: #ffffff;
  border-radius: 3px 3px 0px 0px;
  padding: 10px 12px;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .step-header-text-container {
    display: flex;
    align-items: center;
    margin-right: 13px;

    .step-header-text {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .step-header-text-sub {
        font-size: 10px;
        line-height: 15px;
        color: $colorSubtitle;
      }

      .step-header-text-main {
        font-size: 14px;
        line-height: 21px;
        color: $colorDarkGrey;
      }
    }
  }

  .step-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}