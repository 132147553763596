@import '../../styles/variable.scss';

.login-modal-wrapper {
  &.edit-loan-amount-container {
    z-index: 2000;

    .modal-content {
      margin-bottom: 200px;
      max-width: 696px;
    }
  }
  .editloan-header-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    padding-left: 15px;
    @media screen and (min-width: 600px) {
      padding-left: 24px;
    }
    .editloan-header-section {
      font-family: 'Poppins-SemiBold';
      font-size: 17px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.41;
      letter-spacing: normal;
      color: $colorDarkGrey;
    }
    .editloan-header-subsection {
      font-family: 'Poppins-Regular';
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #66696c;
    }
  }
  .editloan-body {
    padding-left: 0px !important;
    padding: 0 !important;
    @media screen and (min-width: 576px) {
      padding: 10px !important;
    }
    .amount-range-selector,
    .estimate-range-slider {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: #25252a;
      font-family: 'Poppins-Regular';
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
      @media screen and (min-width: 768px) {
        flex-wrap: nowrap;
      }
      .amount-range-slider,
      .estimation-range-selector,
      .estimate-loan-amount {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        line-height: 1;

        @media screen and (min-width: 768px) {
          width: auto;
          flex-basis: 50%;
        }
      }
    }
    .estimate-range-slider {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 30px;
      .estimate-loan-amount {
        font-family: 'Poppins-SemiBold';
        font-size: 24px;
        font-weight: 600;
        color: $colorDarkGrey;
        padding-left: 0;
        @media screen and (min-width: 600px) {
          font-size: 36px;
          padding-left: 20px;
        }
        .loan-amt-label {
          font-family: 'Poppins-Regular';
          font-size: 15px;
          font-weight: normal;
          color: #66686a;
        }
      }
    }
    .estimate-slider {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 30px;
      .estimate-loan-amount {
        font-family: 'Poppins-SemiBold';
        font-size: 36px;
        font-weight: 600;
        color: $colorDarkGrey;
        .loan-amt-label {
          font-family: 'Poppins-Regular';
          font-size: 15px;
          font-weight: normal;
          color: #66686a;
        }
      }
      .residual-switch {
        padding-left: 0;
        white-space: nowrap;
        align-items: center;

        @media screen and (min-width: 992px) {
          padding-left: 10px;
        }

        .residual-switch-label {
          max-width: calc(100% - 0.5rem);
          white-space: pre-wrap;
          color: $colorDarkGrey;
          font-family: 'Poppins-SemiBold';
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 27px;
          margin-right: 63px;
          padding-right: 1rem;

          @media screen and (min-width: 1080px) {
            padding-right: 2rem;
            margin-right: 63px;
          }

          .include-residual {
            display: block;
          }
        }

        .custom-control-label {
          &::before {
            top: 15px;
            left: -0.2rem;
            width: 50px;
            height: 30px;
            border-radius: 16.5px;
            background-color: $colorTail;
          }

          &.checked {
            &::before {
              background-color: $colorResidualSwitch;
              border-color: $colorResidualSwitch;
            }
          }

          &::after {
            top: 18px;
            height: 24px;
            width: 24px;
            border-radius: 15px;
            background-color: #ffffff;
            box-shadow: -1px 0 4px 0 rgba(18, 78, 84, 0.36);
            background-color: #fff;
            // left: calc(-0.2rem + 13px);
            left: 0px;
          }
        }
        .custom-control-input:checked ~ .custom-control-label::after {
          background-color: $colorWhite;
          left: 5px;
        }
      }
    }
    .editloan-header {
      display: flex;
      flex-direction: row;

      .edit-loan-amount {
        font-size: 36px;
        color: $colorDarkGrey;
        font-family: 'Poppins-Bold';
        margin-bottom: 0;
        flex: 1;
        flex-basis: 50%;
        padding-left: 24px;
        .edit-loan-amount-height {
          line-height: 1px;
        }
        .loan-amt-label {
          font-family: 'Poppins-Regular';
          font-size: 16px;
          color: #66686a;
          font-weight: normal;
        }
      }
      .edit-payment-range {
        display: flex;
        flex-direction: column;
        .repayment-label {
          font-family: 'Poppins-Regular';
          font-size: 15px;
          color: #66686a;
        }
        .repayment-range {
          display: flex;
          flex-direction: row;
          font-size: 36px;
          color: $colorDarkGrey;
          font-family: 'Poppins-Bold';
          flex-wrap: wrap;
          line-height: 1;
        }
      }
      .edit-interest-range {
        display: flex;
        flex-direction: column;
        .intrest-label {
          font-family: 'Poppins-Regular';
          font-size: 15px;
          color: #66686a;
        }
        .intrest-range {
          display: flex;
          flex-direction: row;
          font-size: 36px;
          color: $colorDarkGrey;
          font-family: 'Poppins-Bold';
        }
      }
      .edit-loan-options {
        display: flex;
        flex-direction: column;
        font-size: 36px;
        color: $colorDarkGrey;
        font-family: 'Poppins-Bold';

        .loan-option-label {
          font-family: 'Poppins-Regular';
          font-size: 16px;
          color: #66686a;
        }
      }
    }
    .edit-price {
      display: flex;
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
      @media screen and (min-width: 600px) {
        flex-direction: row;
      }
      .purchase-price-slider {
        flex: 1;
        flex-basis: 100%;
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
        @media screen and (min-width: 600px) {
          flex: 1;
          flex-basis: 50%;
        }
      }
      .deposit-price-slider {
        flex: 1;
        flex-basis: 100%;
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
        @media screen and (min-width: 600px) {
          flex: 1;
          flex-basis: 50%;
        }
      }
    }
    .edit-term {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      flex-wrap: nowrap;
      @media screen and (min-width: 600px) {
        flex-direction: row;
        align-items: center;
      }
      .estimation-range-selector {
        flex: 1;
        flex-basis: 100%;
        margin-bottom: 10px;
        margin-left: 12px;
        @media screen and (min-width: 600px) {
          flex: 1;
          flex-basis: 50%;
          padding-left: 12px;
          margin-left: 0;
        }
        .pretto-slider-container {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          width: 230px;
          .pretto-slider-value {
            margin-left: 8px;
            max-width: fit-content;
          }
        }
      }
      .edit-loan-amount {
        font-size: 36px;
        color: $colorDarkGrey;
        font-family: 'Poppins-Bold';
        flex: 1;
        flex-basis: 100%;
        margin-left: 12px;
        margin-bottom: 10px;
        @media screen and (min-width: 600px) {
          flex: 1;
          flex-basis: 50%;
        }
        .edit-loan-amount-height {
          font-size: 30px;
          line-height: 45px;
          font-weight: 600;
        }
        .loan-amt-label {
          font-family: 'Poppins-Regular';
          font-size: 16px;
          color: #66686a;
          font-weight: normal;
        }
      }
    }
    .residual-section3 {
      margin-left: 0;
      margin-right: 0;
      flex-direction: column;
      @media screen and (min-width: 992.95px) {
        flex-direction: row;
        flex-wrap: nowrap;
      }
      .residual-switch {
        &.custom-switch {
          padding-left: 12px;
          flex: 1;
          flex-basis: 100%;
          @media screen and (min-width: 992.95px) {
            padding-left: 1rem;
            flex: 1;
            flex-basis: 50%;
          }
        }

        .residual-switch-label {
          /* width: 140px;
            height: 48px; */
          font-family: 'Poppins-SemiBold';
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: $colorDarkGrey;
          margin-right: 15%;
          @media screen and (min-width: 600px) {
            margin-right: 63px;
          }
        }
        .custom-control-label {
          &::before {
            left: -0.5rem;
            height: 30px;
            width: 50px;
            border-radius: 15px;
            // border-color: $colorWhite;
            background-color: $colorWhite;
          }

          &::after {
            height: 24px;
            width: 24px;
            border-radius: 15px;
            box-shadow: -1px 0 4px 0 rgba(35, 36, 37, 0.8);
            background-color: $colorInactive;
            left: calc(-0.2rem + 5px);

            //left: 0;
          }
        }

        .custom-control-input:checked ~ .custom-control-label.checked {
          &::before {
            background-color: $colorResidualSwitch;
            border-color: $colorResidualSwitch;
          }

          &::after {
            background-color: #ffffff;
            //left: 8px;
          }
        }
      }
      .repayment-freq {
        margin-left: 12px;
        margin-bottom: 20px;
        margin-top: 20px;
        flex: 1;
        flex-basis: 100%;
        @media screen and (min-width: 992.95px) {
          margin-top: 0;
          flex: 1;
          flex-basis: 50%;
        }
        .repayment-freq-label {
          font-family: 'Poppins-Medium';
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
        .btn-group-freg {
          border-radius: 3px;
          padding-top: 8px;
          margin-right: 10px;
          @media screen and (min-width: 992.95px) {
            margin-right: 20px;
          }
          .btn-freq {
            background-color: #f1f2f2;
            height: 30px;
            color: #66696c;
            padding: 0px 10px 0px 10px;
            line-height: 1.33;
            &.is-selected {
              border-radius: 3px;
              background: #28c9da !important;
              font-weight: 600;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              .freq-name {
                font-family: 'Poppins-Medium';
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
              }
            }

            &:focus,
            &:active {
              outline: none;
              background-color: #28c9da !important;
            }
            .freq-name {
              font-family: 'Poppins-Medium';
              font-size: 14px;
              font-weight: 500;
              color: $colorDarkGrey;
            }
          }
        }
      }
    }
  }
  .editloan-footer {
    font-family: 'Poppins-Medium';
    .footer-close {
      //width: 79px;
      height: 3rem;
      border-radius: 3px;
      border: solid 1px #bebebf;
      background-color: #ffffff;
      color: $colorDarkGrey;
    }
    .footer-update {
      //width: 84px;
      height: 3rem;
      border-radius: 4px;
      background-color: #330072;
    }
  }
  .price-slider-input {
    height: 50px;
    border-radius: 3px;
    border: solid 1px #bebebf;
    background-color: #ffffff;
  }
}
