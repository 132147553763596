@import '../../../styles//variable.scss';

.amount-broker {
  &.amount-broker-disabled-edit {
    color: $colorGreenSuccess;
    padding-left: 4px;
  }

  &.amount-broker-with-old-value {
    text-decoration-line: line-through;
  }
}
