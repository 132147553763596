@import '../../../../styles/variable.scss';
.finances-details-container {
  margin-bottom: 10px;
  margin-top: 2vh;
  border: none;
  @media screen and (min-width: 600px) {
    margin-bottom: 30px;
    margin-top: 30px !important;
  }

  .finances-details-header-container {
    // border: 1px solid #dee2e6 !important;
    background-color: $colorWhite !important;

    .finances-details-header {
      margin-bottom: 0;
      font-family: 'Poppins-SemiBold';
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0;
    }

    .finances-details-description {
      color: $colorSubtitle;
    }
    .finances-details-button {
      position: relative;
      float: right;
      border-radius: 3px;
      border: solid 1px #bebebf;
      margin: 10px 24px;
      height: 32px;
      background-color: #eef2f5;
      border-radius: 3px;
      .btn-income {
        margin: 10px 0px;
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #25282a;
        padding: 7px 14px;
        color: $colorDarkGrey;
      }
    }
  }
  .finances-details-track-verify {
    //height: 60px;
    border-radius: none;
    border: solid 1px rgba(5, 189, 127, 0.1);
    background-color: #e7f9f3;
    .finances-track-verify-body {
      .finances-track-verify-content {
        font-family: 'Poppins-Regular';
        color: $colorDarkGrey;
        size: 15px;
      }
      .finances-track-verify-button {
        width: 88px;
        height: 36px;
        position: relative;
        float: right;
        border-radius: 4px;
        background-color: $colorGreenSuccess;
      }
    }
  }
  .finances-body-container {
    padding: 0 !important;
    .finances-body-table {
      .finances-card-table-body {
        .finances-card-table-row {
          padding: 10px 0px;
          margin: 0px 1px;
          font-family: 'Poppins-Regular';
          font-size: 14px;
          color: $colorDarkGrey;
          vertical-align: none;
          border-bottom: 1px solid #dee2e6;
          border-top: none;
        }
        .finances-card-table-total {
          vertical-align: none;
          text-align: start;
          border-top: none;
          font-family: 'Poppins-SemiBold';
          font-size: 16px;
          font-weight: 600;
          color: $colorDarkGrey;
          &.finance-card-total-align {
            text-align: end;
          }
        }
        .finances-card-table-detail {
          vertical-align: none;
          border-bottom: 1px solid #dee2e6;
          border-top: none;
          font-family: 'Poppins-Medium';
          width: 15%;
          text-align: start;
          @media screen and (min-width: 767.95px) {
            text-align: end;
            white-space: nowrap;
          }

          &.btn-delete {
            padding-left: 0;
          }
          &:nth-child(1) {
            width: 40%;
          }
          &:nth-child(2) {
            width: 30%;
          }

          .finances-card-table-edit {
            color: #66686a;
            font-size: 13px;
          }
          .finances-card-table-delete {
            color: #f23457;
            font-size: 13px;
          }
        }
      }
    }
  }
}

.login-modal-wrapper {
  .finances-header-container {
    .finances-header-section {
      white-space: nowrap;
      width: 157px;
      height: 24px;
      font-family: 'Poppins-SemiBold';
      font-size: 17px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.41;
      letter-spacing: normal;
      color: #212b36;
    }
  }
  .show-grid {
    .asset-column {
      padding: 24px 20px;
      .finances-form-label {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
      }
      .footer-container {
        padding-bottom: 0;
        padding-top: 20px;
        font-family: 'Poppins-Medium';
        float: right;
        border-top: none;
        .footer-btn {
          //width: fit-content;
          height: 48px;
          width: 110px;
          border-radius: 3px;
          //background-color: #264667;
          &:disabled {
            background-color: $colorInactive;
            border-color: $colorInactive;
          }
          &:not(:disabled),
          &:not(:disabled):hover,
          &:not(:disabled):active {
            background-color: $colorPrimaryBlue !important;
            color: $colorWhite !important;
          }
        }
        .footer-cancel {
          //width: fit-content;
          height: 48px;
          width: 110px;
          border-radius: 3px;
          border: solid 1px #bebebf;
          background-color: #ffff;
          color: $colorDarkGrey;
        }
      }
      .add-income-col {
        .add-income-body-h1 {
          font-family: 'Poppins-SemiBold';
          font-size: 28px;
          font-weight: 600;
          color: $colorDarkGrey;
        }
      }
      .horizantal-line {
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: 0;
      }
    }
  }
}
.spouse-partner-owner {
  margin: 24px 20px;
}
