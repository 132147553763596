@import '../../../styles/variable.scss';

.current-employment-d {
  height: 16px;
  font-family: 'Poppins-SemiBold';
  font-size: 14px;
  font-weight: 600;
  color: $colorDarkGrey;
}

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  color: $colorDarkGrey;
  cursor: pointer;
  font-family: 'Poppins-Regular';
  font-size: 14px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
  width: 16px;
  height: 16px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: solid 1px #bebebf;
  background-image: linear-gradient(to bottom, #ffff, #f9fafb);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #00aabb;
  border: solid 1px #00aabb;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}
input [type='radio']:checked + label {
  font-family: 'Poppins-Medium';
}
/* .radio-container input:checked + label {
  font-family: 'Poppins-Medium';
  color: #f00;
} */
/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #00aabb;
}
