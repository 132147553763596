@import '../../../styles/variable.scss';

.email-save-quote-modal {
  .modal-dialog {
    @media screen and (min-width: 768px) {
      max-width: 650px;
    }

    @media screen and (min-width: 992px) {
      max-width: 800px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 1088px;
    }
  }

  .show_column {
    display: flex;
    flex-direction: row;
    .save-quote-section {
      top: 0px;
      align-self: flex-start;
      margin: 0px 0px;
      display: flex;
      flex-direction: column;
      padding: 70px 40px;
      background: #ffffff;
      flex-basis: 60%;
      @media screen and (max-width: 767.95px) {
        flex-basis: 100%;
      }

      .save-quote-col {
        .save-quote-body-h1 {
          font-family: 'Poppins-SemiBold';
          font-size: 30px;
          font-weight: 600;
          color: $colorDarkGrey;
          text-transform: capitalize;
        }
        .save-quote-header-desc {
          font-family: 'Poppins-Regular';
          font-size: 16px;
          line-height: 24px;
          color: #66696c;
        }
      }
      .save-quote-form {
        margin: 20px 0px;
        left: 0px;
        top: 89px;
        display: flex;
        flex-direction: column;
        padding: 0px;
        .save-quote-row {
          flex-wrap: nowrap;
          display: flex;
          flex-flow: row;
          @media screen and (max-width: 991.95px) {
            flex-wrap: wrap;
            flex-flow: column;
          }

          .redux-render-field {
            flex: 1;

            &:first-child {
              @media screen and (min-width: 992px) {
                margin-right: 30px;
              }
            }
            .finances-form-label {
              font-family: 'Poppins-Medium';
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              margin-bottom: 4px;
            }
          }

          &.save-modal-mobile {
            .redux-render-field {
              flex: 0.5;
            }
          }
        }

        .redux-render-field {
          .finances-form-label {
            font-family: 'Poppins-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 4px;
          }
          .input-group-prepend {
            .icon-prepend {
              background-color: transparent;
              border-right: none;
            }

            + input {
              border-left: none;
            }
          }

          .is-invalid {
            .input-group-prepend {
              .icon-prepend {
                border-color: #dc3545;
              }
            }

            input {
              &:hover,
              &:focus,
              &:active {
                outline: none;
                box-shadow: none;
                border-color: #dc3545;
              }
            }
          }

          input {
            &:hover,
            &:focus,
            &:active {
              outline: none;
              box-shadow: none;
              border-color: #ced4da;
            }
          }
        }
        .privacy-policy {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          color: $colorSubheadingDesc !important;
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 15px;
          padding-bottom: 10px;
          padding-top: 10px;
          .lock-img {
            vertical-align: bottom;
            margin-right: 2px;
          }
          .btn-policy-path {
            color: $colorSubheadingDesc;
            font-family: 'Poppins-SemiBold';
            white-space: nowrap;
            text-decoration: underline;
          }
        }
        .email-quote-footer {
          justify-content: flex-start;
          border-top: none;
          margin: 0;
          padding: 0;
          display: flex;
          flex-direction: row;
          .savequotesubmit {
            width: 179px;
            height: 56px;
            border-radius: 3px;
            background-color: #330072;
            padding: 15px 24px;
            background-color: $colorPrimaryBlue;
          }
          .savequotecancel {
            border-radius: 4px;
            color: $colorSubheadingDesc;
            width: 109px;
            height: 56px;
            left: 189px;
            top: 0px;
            padding: 15px 24px;
            background: #ffffff;
          }
        }
      }
    }
    .review-section {
      // background: transparent url('../../../assets/image/rectangle-copy.png') center center
      //   no-repeat;
      // background-size: cover;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      left: 0px;
      top: 0px;
      display: flex;
      flex-direction: column;
      padding: 40px 30px;
      background-color: #f3f7fa;
      flex-basis: 40%;

      @media screen and (max-width: 767.95px) {
        display: none;
      }

      .card-review-section {
        .card-body {
          box-shadow: 0px -1px 17px #e5e7e7;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          padding: 20px;

          .card-title {
            font-family: 'Poppins-Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            line-height: 25px;
            color: #00c1d5;
            margin: 0px 0px;
          }
          .card-quote-description {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #25282a;
            margin: 0px 0px;
          }
        }
      }
      .save-quote-header {
        border-bottom: none;

        .close {
          color: $colorWhite;
          background-color: $colorDarkGrey;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          margin-top: 10px;
          padding: 0;
        }
      }

      .review-details {
        background-color: $colorWhite;
        margin-bottom: 24px;
      }
    }
  }

  .account-review-section {
    background: transparent url('../../../assets/image/save-account.png') center center no-repeat;
    background-size: cover;
    .save-account-header {
      border-bottom: none;

      .close {
        color: $colorWhite;
        background-color: $colorDarkGrey;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        margin-top: 10px;
        padding: 0;
      }
    }
  }
}
.save-model-layout {
  max-width: 370px;
  &.lender-option-acknowledge {
    max-width: 520px;
    .modal-footer {
      padding-right: 10px;
      .btn-cancel {
        background: #f1f2f2;
        border-radius: 3px;
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-right: 20px;
        color: #66696c;
      }
      .acknowledge-footer {
        margin: 0;
        padding-right: 10px;
      }
    }
  }
  .save-container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    @media screen and (min-width: 768px) {
      padding: 30px 70px;
    }
    &.save-acknowledgement {
      padding: 30px 20px;
    }
    .success-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-bottom: 10px;
      .success-icon {
        border-radius: 50%;
        width: 34px;
        background: rgba(0, 215, 143, 0.1) url(/static/media/check.6e93454b.svg) center no-repeat;
        height: 34px;
        background-size: 20px;
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      padding: 0;
      .save-title {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 25px;
        color: $colorDarkGrey;
        text-align: center;
        margin-bottom: 12px;
        &.acknowledgment-title {
          margin-bottom: 20px;
        }
      }
      .save-subtitle {
        font-family: 'Poppins-Regular';
        color: #66686a;
        text-align: center;
        font-size: 14px;
      }
      .btn-close {
        height: 48px;
        border-radius: 3px;
        border: solid 1px #330072;
        padding: 12px 22px;
        &.btn-val {
          font-family: 'Poppins-Medium';
          font-size: 14px;
          color: #ffff;
        }
        &:hover {
          background-color: $colorhover !important;
        }
        &:disabled {
          background-color: $colorInactive;
          border-color: $colorInactive;
          color: #ffffff;
        }
        &:not(:disabled):focus,
        &:not(:disabled):active {
          background-color: $colorPrimaryBlue !important;
          color: #ffffff;
          opacity: 0.6;
        }
      }
    }
  }
}
