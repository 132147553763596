@import '../../../styles/keyframes.scss';

// use of keyframes mixin
@include keyframes(show-success) {
  from {
    display: none;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

// use of keyframes mixin
@include keyframes(slide-car) {
  from {
    top: -40px;
  }
  to {
    top: -2px;
  }
}
@include keyframes(another-animation) {
  from {
    top: 40px;
  }
  to {
    top: 70px;
  }
}
