.navigation-stepper {
  .stepper {
    .rc-steps-item {
      width: 100%;

      .rc-steps-item-container {
        width: 100%;

        &:hover,
        &:focus {
          outline: none;
          opacity: 1;
        }

        .rc-steps-item-tail {
          top: 10px;
          left: 19px;
        }

        .rc-steps-item-icon {
          width: 40px;
          height: 40px;

          display: flex;
          justify-content: center;
          align-items: center;

          .rc-steps-icon {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: normal;
          }
        }

        .rc-steps-item-content {
          margin-top: 0;
          width: 100%;
          overflow: visible;
          min-height: auto;
          margin-bottom: 20px;
          .rc-steps-item-title {
            display: flex;
            flex-flow: column-reverse;
            padding: 0;
            margin: 0;
            margin-right: 40px;
            margin-bottom: 15px;
            .step-title {
              text-align: inherit;
              letter-spacing: normal;
              color: $colorDarkGrey;
              font-family: 'Poppins-Bold';
              line-height: 24px;
              font-size: 16px;
            }

            .rc-steps-item-subtitle {
              font-size: 12px;
              color: $colorSlateGrey;
              margin: 0;
              font-weight: normal;
              font-family: 'Poppins-Regular';
            }

            .btn-link {
              &:focus,
              &:active {
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .rc-steps-item-active,
    .rc-steps-item-finish {
      .rc-steps-item-container {
        .rc-steps-item-tail {
          &::after {
            background-color: $colorTail;
          }
        }

        .rc-steps-item-icon {
          background-color: #00c1d5;
          border-color: #00c1d5;
          margin-right: 9px;
          .rc-steps-icon {
            color: $colorWhite;
          }
        }

        .rc-steps-item-content {
          .rc-steps-item-title {
            font-family: 'Poppins-Medium';
            padding: 0;
          }
        }
      }
    }

    .rc-steps-item-finish {
      .rc-steps-item-container {
        .rc-steps-item-icon {
          background-color: #00cd76;
          border-color: #00cd76;

          .rc-steps-icon {
            color: $colorWhite;
            top: -1px;
            width: 20px;
            height: 20px;
            .nav-checksuccess {
              width: 100%;
              height: 100%;
            }
          }
        }
        .rc-steps-item-tail {
          &::after {
            background-color: $colorDarkGrey;
          }
        }
      }
    }

    .rc-steps-item-wait {
      .rc-steps-item-container {
        .rc-steps-item-icon {
          background-color: $colorInactive;
          border-color: $colorInactive;
          .rc-steps-icon {
            color: $colorWhite;
          }
        }
        .rc-steps-item-content {
          .rc-steps-item-title {
            font-family: 'Poppins-Regular';
            font-weight: normal;

            .step-title {
              font-family: 'Poppins-Regular';
              color: $colorSubheadingDesc;
            }
          }
        }
      }
    }

    .rc-steps-item-description {
      //min-height: 42px;
      padding: 0 !important;

      .sub-steps {
        //padding: 31px 0 36px !important;

        .rc-steps-item {
          margin: 0 1rem;
          width: calc(100% - 2rem);

          .rc-steps-item-container {
            .rc-steps-item-tail {
              top: 22px;
              left: 3px;
              padding-top: 3px;
            }

            .rc-steps-item-icon {
              height: 14px;
              width: 14px;
              margin-top: 11px;
              background-color: $colorSubStepInactive;
              border-color: $colorSubStepInactive;
              margin-left: -1px;

              .rc-steps-icon {
                color: $colorWhite;
              }
            }

            .rc-steps-item-content {
              margin-bottom: 0px;
              .rc-steps-item-title {
                //margin: 6px 0 6px;
                margin: 0;
                margin-bottom: 10px;
                .step-title {
                  //padding: 5px 16px;
                  font-family: 'Poppins-Regular';
                  font-size: 15px;
                  line-height: 22px;
                  // color: $colorSubheadingDesc;
                }
              }
            }
          }

          &.rc-steps-item-active,
          &.rc-steps-item-finish {
            &:not(.rc-substep-acting-parent) {
              &:hover {
                background-color: $colorSecondaryGrey;
                border-radius: 30px;
                padding: 10px 20px 0 15px;
                margin: -10px 0 0 0px;
              }
              .rc-steps-item-container {
                .rc-steps-item-icon {
                  opacity: 1;
                  background-color: $colorDarkGrey;
                  border-color: $colorDarkGrey;
                }
              }
            }
          }

          &.rc-substep-hidden-icon {
            width: 100%;
            .rc-steps-item-icon {
              display: none;
            }
            .rc-steps-item-title {
              button {
                font-size: 12px !important;
              }
            }
          }

          &.rc-steps-item-active {
            .rc-steps-item-container {
              .rc-steps-item-content {
                // .rc-steps-item-title {
                //   margin: 0;
                //   padding-left: 4px;
                //   max-width: fit-content;

                //   .step-title {
                //     font-family: 'Poppins-SemiBold';
                //     text-align: inherit;
                //     font-size: 14px;
                //     font-weight: 600;
                //     padding: 10px 12px;
                //     color: $colorDarkGrey;
                //   }
                // }
              }
            }
          }
        }
      }
    }
    .rc-steps-item {
      .rc-steps-item-container {
        .rc-steps-item-content {
          .rc-steps-item-title {
            color: $colorSubheadingDesc;
            font-family: 'Poppins-Regular';
          }
        }
      }
    }
  }
}

.rc-steps-item-tail {
  display: none;
}
