@import '../../../styles/variable.scss';

.employer-details-page {
  background: $colorWhite;
  border-radius: 3px 3px 0px 0px;

  @media screen and (min-width: 768px) {
    margin-top: 30px;
  }

  .employer-details-headers {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: $colorDarkGrey;
    font-size: 14px;
    line-height: 21px;
    text-decoration: underline;

    @media screen and (min-width: 768px) {
      font-size: 17px;
      margin-bottom: 20px;
    }
  }

  .employer-title-section {
    padding: 10px 12px;

    @media screen and (min-width: 768px) {
      padding: 24px;
    }

    .employer-details-title {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: 600;
      color: $colorDarkGrey;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0.5rem;

      .employer-field-name{
         color: $colorEmpFieldName
      }

      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 0;
      }
    }

    .employer-details-subtitle {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: 400;
      color: $colorSubtitle;
      font-size: 14px;
      line-height: 21px;

      @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .employer-details-who {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: 600;
      color: #10b2c4;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 0.5rem;

      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  .employer-details-section {
    padding: 10px 12px;
    border-top: 1px solid $colorBackground;

    @media screen and (min-width: 768px) {
      padding: 24px;
    }

    .employer-type-wrapper {
       display: flex;
       margin-bottom: 20px;
      .employer-header {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        color: $colorSubtitle;
        font-size: 12px;
        line-height: 18px;
        padding: 10px 10px 10px 0px;

        @media screen and (min-width: 768px) {
          font-size: 14px;
          line-height: 21px;
        }
      }

      .employer-type {
        background-color: $colorBackgroundEmployer;
        padding: 10px 10px;
        width: fit-content;
        font-weight: bold;
      }
    }

    .employer-details {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
      }

      .employer-name {
        padding: 0;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        font-weight: 500;
        font-family: 'Poppins-Medium';

        @media screen and (min-width: 768px) {
          margin-right: 30px;
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 20px;
          flex: 1;
          flex-basis: 65%;
        }
      }

      .current-emp-date {
        padding: 0;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        margin-left: 0;
        font-weight: 500;
        font-family: 'Poppins-Medium';
        flex-basis: 100%;

        @media screen and (min-width: 768px) {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }

    .employer-occupation-details {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      justify-content: space-evenly;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        margin-bottom: 0;
      }


      .employer-industry-container {
        display: flex;
        flex-direction: column;
        flex-basis: 25%;
      }

      .employer-occupation-container {
        display: flex;
        flex-direction: column;
        flex-basis: 25%;

        @media screen and (min-width: 768px) {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 20px;
          margin-left: 20px;
          flex: 1;
          flex-basis: 30%;
        }
      }

      .employer-occupation {
        padding: 0;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        font-weight: 500;
        font-family: 'Poppins-Medium';
        flex-basis: 100%;

        @media screen and (min-width: 768px) {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 20px;
          flex-basis: 35%;
          margin-left: 20px;
        }
      }

      .employer-industry {
        padding: 0;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        margin-left: 0;
        font-weight: 500;
        font-family: 'Poppins-Medium';
        flex-basis: 100%;

        @media screen and (min-width: 768px) {
          font-size: 15px;
          line-height: 22px;
          flex: 1 1;
          flex-basis: 35%;
        }
      }

      .employer-abn {
        padding: 0;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        font-weight: 500;
        font-family: 'Poppins-Medium';

        @media screen and (min-width: 768px) {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 20px;
          flex: 1;
          flex-basis: 30%;
          margin-left: 20px;
        }
      }
    }

    .employee-address {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
      }

      .addressselection {
        padding: 0;
        flex: 1;
        flex-basis: 65%;
        margin-right: 30px;
      }

      .current-emp-date {
        flex: 1;
        flex-basis: 35%;
        padding: 0;
      }
    }

    .applicant-type {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 22px;
      text-transform: uppercase;
      color: $colorSecondaryBlue;
      margin-bottom: 20px;
    }

    .enter-manual-address {
      .could-not-find {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 21px;
        color: $colorSubheadingDesc;

        @media screen and (min-width: 768px) {
          font-size: 15px;
          line-height: 22px;
        }

        .enter-manually {
          background: $colorTexthighlight;
          border-radius: 3px;
          padding: 3px 8px;
          margin-left: 10px;

          .enter-manually-label {
            font-family: 'Poppins-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: $colorDarkGrey;
          }
        }
      }
    }
  }
}

.btn-address-next {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 12px;
  border-top: 1px solid $colorBackground;

  @media screen and (min-width: 768px) {
    padding: 20px 24px;
  }

  .btn-next-page {
    padding: 12px 22px;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    background-color: $colorPrimaryBlue;
    white-space: nowrap;

    &:active {
      background-color: $colorPrimaryBlue !important;
      opacity: 0.6;
    }

    &:focus,
    &:hover {
      background-color: $colorhover !important;
      opacity: 0.6;
    }

    .right-icon {
      height: 8px;
      width: 8px;
      margin-left: 10px;
    }
  }
}

#date-picker-inline-helper-text {
  color: $colorDatePickerHelper;
}