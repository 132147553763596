@import '../../styles/variable.scss';

.basic-auto-complete-input-search {
  display: inline-flex;
  border: 1px solid $colorButtonDisable;

  .input-group-text {
    background-color: $colorWhite;
    border:none;
  }

  .input-group-text {
    background-color: $colorWhite;
  }
  .input-clear-group {
    top: 3px;
    right: 0;
    z-index: 3;
  }

  .auto-suggest-input {
    border: none;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: #ced4da;
    }
  }

  .clear-action {
    border: none;
    padding-left: 2px;

    &.is-invalid {
      border-color: $colorRed;
    }

    .oi {
      font-size: 1em;
      color: #495057;
    }
  }

  .suggestion-text-container {
    width: 100%;

    .suggestion-text {
      width: 100%;
      white-space: normal;
    }
  }
}