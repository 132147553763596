@import '../../../styles/variable.scss';


.estimation-route-header,
.partnerheader {
    display: none;
}

.partnerhead {
    border-top: solid 2px $colorLightGrey;
    padding-top: 10px;
    padding-bottom: 10px;
}

.estimateRepayments-title {
    color: $colorText;
    font-family: 'Poppins-Bold';
    font-size: 27px;
    font-style: bold;
    font-weight: 500;
    line-height: 38.885px;

}

.partner-logo-img {
    margin: -5px 0px 2px 20px;
}

.estimateRepayments-subtitle {
    color: $colorSubtitle;
    font-family: 'Poppins-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.header-font {
    font-size: 20px;
    font-style: bold;
    line-height: 30px;
}

.subtitle-font {
    font-size: 12px;
}