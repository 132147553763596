@import '../../styles/variable.scss';

.car-grid {
  height: auto;
  border-radius: 4px;
  border: solid 1px #bebebf;
  background-color: #fff;
  margin-left: 0;
  margin-left: initial;
  display: grid;
  grid-template-columns: 25% 75%;
  //grid-template-rows: 1fr 0.5fr 0.25fr;
  grid-template-rows: 1fr 0.55fr;
  padding: 0;
  font-family: 'Poppins-Regular';

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    margin-top: 16px;

    > div {
      grid-column: 1/4 !important;
      grid-row: auto !important;

      &.car-estimate-val {
        .estimate-val {
          margin-left: 9px;
        }
      }
    }
  }

  &.car-loading {
    display: block;

    .skeleton-pulse-container {
      .skeleton-pulse-placeholder {
        height: 1.5em;
        margin-bottom: 1em;
        width: 40%;

        &:first-child {
          width: 80%;
        }

        &:nth-child(2) {
          width: 60%;
        }
      }
    }
  }

  .car-image {
    justify-self: stretch;
    grid-column: 1;
    grid-row: 1;

    .img-vehicle {
      width: 100%;
    }
  }

  .car-details {
    grid-column: 1/4;
    grid-row: 1;

    .car-details1 {
      font-size: 1.25rem;
      font-family: 'Poppins-SemiBold';
      color: $colorDarkGrey;
    }
    .car-details2 {
      color: #66696c;
      font-family: 'Poppins-Regular';
      font-size: 17px;
      letter-spacing: 0;
      line-height: 30px;
    }
  }

  .car-estimate-val {
    grid-column: 1;
    grid-row: 2;

    .estimate-field {
      font-size: 12px;
      color: $colorDarkGrey;
    }
    .estimate-val {
      height: 31px;
      width: 110px;
      border-radius: 2px;
      border: solid 2px $colorGreenSuccess;
      background-color: #f4f5f5;
      font-size: 14px;
      font-family: 'Poppins-SemiBold';
      color: $colorDarkGrey;
      border-left: none;
      font-family: 'Poppins-SemiBold';
      color: $colorDarkGrey;
      .estimate-icon {
        height: 24px;
        width: 24px;
        left: -9px;
        position: relative;
        top: 1px;
        display: inline-block;
        transform: rotate(50deg);
        border-radius: 3px;
        border: solid 1px $colorGreenSuccess;
        background-color: $colorGreenSuccess;
        color: white;
        .estimate-dollar {
          transform: rotate(127deg);
          padding-left: 9px;
        }
      }
    }
  }

  .car-list-val {
    grid-column: 1/4;
    grid-row: 2;
    overflow-y: auto;

    .chip {
      display: inline-block;
      height: 32px;
      font-size: 13px;
      font-family: 'Poppins-SemiBold';
      color: $colorDarkGrey;
      line-height: 32px;
      padding: 0 12px;
      border-radius: 4px;
      background-color: #f4f5f5;
      margin-bottom: 5px;
      margin-right: 5px;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }

      .close {
        float: right;
        line-height: 32px;
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.8);
        text-shadow: 0 1px 0 #fff;
        opacity: 0.5;
      }
    }
  }

  .car-footer {
    grid-column: 1/3;
    grid-row: 3;
    background-color: $colorBackground;
  }

  .factory-option {
    grid-column: 3;
    grid-row: 3;
    background-color: $colorBackground;
    color: #00aabb;
    .btn-link {
      white-space: normal;
      color: #00aabb;
    }
  }
}
.factory-options-manual {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .car-footer {
    color: #66696c;
    font-family: 'Poppins-Regular';
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    .manual-enter {
      color: $colorDarkGrey;
      font-family: 'Poppins-Regular';
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      border-radius: 3px;
      background-color: #e4e8ea;
    }
  }
  .factory-option {
    float: right;
    .manual-enter {
      color: $colorDarkGrey;
      font-family: 'Poppins-Regular';
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      border-radius: 3px;
      background-color: #e4e8ea;
    }
  }
}
