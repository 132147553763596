@import '../../../../../styles/variable.scss';

.finances-details-container {
  margin-bottom: 10px;
  margin-top: 2vh;
  border: none;

  @media screen and (min-width: 600px) {
    margin-bottom: 30px;
    margin-top: 30px !important;
  }
  @media screen and (width: 320px) {
    .button-frequency .MuiButtonGroup-root {
      width: 100%;
    } 
  }
  .finances-details-header-container {
    background-color: $colorWhite !important;

    .finances-details-header {
      margin-bottom: 0;
      font-family: 'Poppins-SemiBold';
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0;
    }

    .finances-frequency-header {
      margin-bottom: 10px;
      font-family: "Poppins-Medium";
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0;
    }

    .finances-details-description {
      color: $colorSubtitle;
    }

    .finances-details-button {
      position: relative;
      float: right;
      margin: 10px 24px;
      height: 32px;
      background-color: $colorPreviousBackground;
      border-radius: 3px;

      .btn-income {
        margin: 10px 0px;
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        padding: 7px 14px;
        color: $colorDarkGrey;
      }
    }
  }

  .finances-details-track-verify {
    //height: 60px;
    border-radius: none;
    border: solid 1px $colorBoder;
    background-color: $colorTrackVerify;

    .finances-track-verify-body {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .finances-track-verify-content {
        font-family: Poppins-Regular;
        color: $colorDarkGrey;
        size: 15px;
      }

      .finances-track-verify-button {
        width: 88px;
        height: 36px;
        position: relative;
        float: right;
        border-radius: 4px;
        background-color: $colorGreenSuccess;
      }
    }
  }

  .finances-body-container {
    .finances-body-table {
      .finances-card-table-body {
        .finances-card-table-row {
          padding: 10px 0px;
          margin: 0px 1px;
          font-family: 'Poppins-Regular';
          font-size: 14px;
          color: $colorDarkGrey;
          vertical-align: none;
          border-bottom: 1px solid $colorBorderBottom;
          border-top: none;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .MuiGrid-grid-sm-8 {
      flex-grow: 0;
      max-width: 58.666667%;
      flex-basis: 58.666667%;
    }
  }
}

.login-modal-wrapper {
  .finances-header-container {
    .finances-header-section {
      white-space: nowrap;
      width: 157px;
      height: 24px;
      font-family: 'Poppins-SemiBold';
      font-size: 17px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.41;
      letter-spacing: normal;
      color: $colorText;
    }
  }

  .show-grid {
    .footer-container {
      padding-bottom: 0;
      float: right;
      border-top: none;

      .footer-btn {
        height: 48px;
        width: 110px;
        border-radius: 3px;

        &:disabled {
          background-color: $colorInactive;
          border-color: $colorInactive;
        }

        &:not(:disabled),
        &:not(:disabled):hover,
        &:not(:disabled):active {
          background-color: $colorPrimaryBlue !important;
          color: $colorWhite !important;
        }
      }

      .footer-cancel {
        height: 48px;
        width: 110px;
        border-radius: 3px;
        border: solid 1px $colorInactive;
        background-color: $colorWhite;
        color: $colorDarkGrey;
      }
    }

    .add-income-col {
      .add-income-body-h1 {
        font-family: 'Poppins-SemiBold';
        font-size: 28px;
        font-weight: 600;
        color: $colorDarkGrey;
      }
    }

    .horizantal-line {
      margin-left: -3rem;
      margin-right: -3rem;
      margin-bottom: 0;
    }
  }
}

.frequency-select-container {
  display: flex;
  flex-flow: column;

  @media screen and (min-width: 350px) {
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
  }

  .select-button-field {
    margin-bottom: 6px;
    flex: 1;

    .frequency-select-buttons {
      font-size: 15px !important;
      line-height: 22px;
      background-color: $colorWhite;
      border: solid 1px $colorBorderBottom;
      color: $colorSecondaryButton !important;

      &.is-selected {
        color: $colorDarkGrey !important;
        background: $colorHoverBackground !important;
        border: 3px solid $colorSelectedBorder !important;
      }

      // @include select-buttons;
      white-space: normal;
      width: 100%;
      height: 48px;

      &:hover {
        border: 3px solid $colorSelectedBorder;
        background-color: $colorBackgroundHover;
        opacity: 0.6;
        color: $colorBlack !important;
      }
    }


    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;

      @media screen and (min-width: 350px) {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

  }
}

.frequency-details {
  display: flex;
  align-items: center;
}

.expenses-card-table-header {
  font-family: 'Poppins-Bold';
  font-size: 16px;
  color: $colorDarkGrey;


  &:nth-child(1) {
    width: 30%;
  }

  &:nth-child(2) {
    width: 20%;
  }

  &:nth-child(3) {
    width: 35%;
  }

  &:nth-child(4) {
    width: 15%;
  }

  // &:nth-child(5) {
  //   width: 10%;
  // }

  .header-padding {
    padding-left: 25px;
  }
}

.expenses-card-table-mobile-header {
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0;
  font-family: "Poppins-Bold";
  font-size: 16px;
  letter-spacing: 0;
  color: #212b36;
  background-color: #f1f2f2;
  border-bottom: 1px solid $colorBorderBottom;

  &:nth-child(1) {
    padding-left: 12px;
  }
}

.expenses-card-table-row {
  margin: 0px 1px;
  font-family: 'Poppins-Regular';
  font-size: 14px;
  color: $colorDarkGrey;
  border-bottom: 1px solid $colorBorderBottom;
  border-top: none;

  .expenses-card-table-detail {
    vertical-align: middle;
    border-bottom: 1px solid $colorBorderBottom;
    border-top: none;
    font-family: 'Poppins-Medium';
    text-align: start;

    .input-group-text {
      border-radius: 0;
    }

    .finance-card-total {
      font-weight: bold;
    }

    .form-amt-group {
      margin-bottom: -8px !important;
    }

    &.btn-delete {
      padding-left: 0;
    }

    &:nth-child(1) {
      width: 30%;
      padding-left: 25px;
    }

    &:nth-child(2) {
      width: 20%;
      padding-left: 15px;
    }

    &:nth-child(3) {
      width: 35%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    .finances-card-table-edit {
      color: $colorSlateGrey;
      font-size: 13px;
    }

    .finances-card-table-delete {
      color: $colorDeleteButton;
      font-size: 13px;
    }
  }
}

.label-font {
  font-weight: bold;
}

.expenses-card-table-detail-mobile {
  vertical-align: middle;
  border-top: none;
  font-family: 'Poppins-Medium';
  text-align: start;

  .align-text {
    text-align: end;
  }

  .item-align {
    align-self: end;

    .form-group {
      margin-bottom: 0 !important;
    }

    .finances-card-table-delete {
      color: $colorDeleteButton;
      font-size: 13px;
    }
  }
}

.expenses-card-table-total {
  vertical-align: none;
  border-top: none;
  font-family: 'Poppins-SemiBold';
  font-size: 16px;
  font-weight: 600;
  color: $colorDarkGrey;


  &:nth-child(1) {
    width: 25%;
    padding-left: 25px;
  }
}

.btn-freq-group {
  font-family: 'Poppins-Regular' !important;
  color: $colorBlack !important;
  text-transform: capitalize !important;

  &:hover {
    background-color: $colorHoverBackground !important;
    opacity: 0.6;
    color: $colorBlack !important;
  }
}

.freq-show {
  margin-right: 10px !important;
  margin-left: 10px !important;
  border: 1px solid $colorFreqShow !important;
  border-radius: 4px !important;
  padding: 8px 15px !important;
}

.selectedButton {
  color: $colorDarkGrey !important;
  background: $colorHoverBackground !important;
  border: 2px solid $colorSelectedBorder !important;
}

.frequency-details-field {
  margin: 0px !important;
}