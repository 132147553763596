.display-vendors {
  display: flex;
  flex-direction: row;
  margin: 20px;
  align-items: center;
  justify-content: space-evenly;
  .right-left-arrw {
    height: 14px;
    width: 14px;
  }
  .lenders-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -37px;
    margin-right: -37px;

    .image-carousel {
      width: 147px;
      height: 78px;
    }

    .lenderimages-container {
      height: 64px;
      width: auto;
      border-radius: 4px;
      border: solid 1px #e5e7e7;
      background-color: #f4f5f5;
      align-items: center;
      margin-left: 0;
    }
  }
}
