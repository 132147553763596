body {
  margin: 0;
  font-family: 'Poppins-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f8f9f9; */
  background-color: #f1f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

main {
  display: block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar-track {
  background-color: #f1f2f2;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f1f2f2;
}

::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  background-color: #66686a;
}
