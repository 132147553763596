.tooltip {
  padding: 12px;
  .tooltip-title {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 8px;
    text-align: start;
  }
  .tooltip-subtitle {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #ffffff;
    text-align: start;
  }
}
