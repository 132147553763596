@import '../../../styles/variable.scss';
@import '../../../styles/global.scss';
@import '../../components/Header/header.scss';
@import './animate.scss';

@mixin logo-align($image, $width, $height) {
  background: url($image) center center/contain no-repeat;
  width: $width;
  height: $height;
}

.estimateRepayments-container {
  display: flex;

  flex-direction: row;
  max-width: 100%;
  padding: 0;
  // min-height: calc(100vh - 80px);

  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
  &.compare-loan-opt-cont {
    justify-content: center;
    @media screen and (max-width: 768.75px) {
      flex-direction: column;
    }
    @media screen and (min-width: 1099px) {
      justify-content: baseline;
    }
  }
  .partnerheader {
    display: none;
  }
  .partner-logo {
    display: inline;
    margin: -3px 0px 2px 20px;
    @media screen and (min-width: 1099px) {
      float: right;
      padding-right: 60px;
      padding-top: 10px;
    }
  }
  .stratton-logo-mobile-view {
    display: inline;
    width: 34vw;
    height: auto;
    margin-left: 10px;
  }
  .partner-logo-mobile-view {
    display: inline;
    max-width: 22vw;
    height: auto;
    margin-left: auto;
  }
  .login-button-mobileView {
    display: inline;
  }
  .stratton-logo {
    margin: 0;
    display: inline;
    @media screen and (min-width: 1099px) {
      padding-left: 30px;
    }
  }
  .stratton-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .stratton-logo-container {
    display: inline;
    margin-left: 10px;
  }
  .estimateRepayments-section {
    background-color: $colorLightGrey;
    padding: 0;
    @media screen and (max-width: 767.95px) {
      display: none;
    }
    @media screen and (max-width: 980px) {
      background-position-x: 30%;
    }

    > img,
    .stratton-info-logo {
      margin-left: 45px;
      max-width: 200px;
      margin-bottom: 60px;
      padding-top: 16px;
      @media screen and (min-width: 1200px) {
        max-width: 100%;
        margin-bottom: 60px;
        margin-left: 64px;
      }
    }

    > img,
    .stratton-logo {
      padding-top: 16px;
      display: inline;
    }

    @media screen and (min-width: 768px) {
      display: flex;
      flex-flow: column;
    }

    .stratton-info-footer-container {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
    }

    .estimatedetails_bgImage,
    .finance_details_bgImage,
    .personal_details_section {
      @media screen and (min-width: 768px) {
        text-align: center;
        margin-bottom: 0;
      }
      .nav-estimate,
      .nav-finances,
      .nav-personal {
        size: auto;
        max-width: 90%;
      }
    }
    .step-content-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 30px 24px 0 32px;
      &.lender-options {
        margin: 30px 20px;
        margin-left: 60px;
        max-width: 208px;
      }
      .nav-animation {
        height: 344px;
        width: 60px;
        border-radius: 30px;
        background: linear-gradient(
          171.51deg,
          $colorNavigationBackground 0%,
          $colorBackground 100%
        );
        padding: 15px 12px 10px;
        margin-right: 20px;

        .nav-animation-line {
          box-sizing: border-box;
          height: 350px;
          width: 2px;
          border: 1px dashed $colorButtonDisable;
          margin-right: 16px;
          margin-left: 17px;
          .nav-animation-car {
            position: relative;
            left: -20px;
            top: -2px;
            &.nav-animation-compare-loan {
              position: relative;
              left: -20px;
              top: 45px;
            }

            &.with-animation {
              animation: slide-car 1s ease-in 0.2s, another-animation 1s ease-in 0.2s;
            }
          }

          .checksuccess-circle {
            border-radius: 50%;
            height: 30px;
            width: 30px;
            background-color: $colorLightGreen;
            margin-bottom: 22px;
            position: relative;
            left: -15px;
            top: -2px;
            opacity: 1;
            display: block;
            animation: show-success 0.7s ease-in;

            .nav-checksuccess {
              height: 14px;
              width: 17px;
              margin: 9px 7px 9px 7px;
            }
          }
        }
      }
      .stepper-contents {
        margin-top: 10px;
        .steps-section {
          .step {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 23px;
            &.enabled {
              color: $colorLightBlue;
              font-family: 'Poppins-SemiBold';
              font-weight: 600;
            }
            &.disabled {
              color: $colorSubtitle;
              font-family: 'Poppins-Regular';
            }
          }
          .step-message {
            font-size: 17px;
            letter-spacing: 0;
            line-height: 26px;
            &.enabled {
              color: $colorDarkGrey;
              font-family: 'Poppins-SemiBold';
              font-weight: 600;
            }
            &.disabled {
              color: $colorSubtitle;
              font-family: 'Poppins-Regular';
            }
          }
          margin-bottom: 60px;
        }
      }
    }
    &.compare-loan-opt-sec {
      display: none;
      @media screen and (min-width: 1099px) {
        display: flex;
      }
    }
  }
  .estimateRepayments-page-details {
    display: flex;
    flex-direction: column;
    background-color: $colorWhiteGrey;
    padding: 0;
    &.compare-loan-option-details {
      background: $colorLightGrey;
      @media screen and (min-width: 765.95px) and (max-width: 1099.95px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .user-dropdown {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      &.user-drop-down-loanoptions {
        margin-bottom: 20px;
      }
      .Need-help {
        display: none;
        @media screen and (min-width: 768px) {
          color: $colorDarkGrey;
          font-family: 'Poppins-Regular';
          font-size: 12px;
          float: right;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 44px;
        }

        .number-font {
          color: $colorDarkGrey;
          font-family: 'Poppins-SemiBold';
          font-size: 18px;
          font-weight: 600;
        }
      }

      .auth-dropdown {
        margin-right: 30px;
        .justify-content-end {
          .header-dropdown {
            @include common-names();
            display: none;
            height: 50px;
            // border-radius: 25.5px;
            // background-color: $colorBackground;
            @media screen and (min-width: 768px) {
              display: block;
            }
          }
        }
      }
    }

    .Need-help {
      display: none;
      @media screen and (min-width: 768px) {
        color: $colorDarkGrey;
        font-family: 'Poppins-Regular';
        font-size: 12px;
        float: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 44px;
      }

      .number-font {
        color: $colorDarkGrey;
        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-weight: 600;
      }
    }

    .dfs-docform {
      margin: 80px;
      margin-top: 0;
      .dfs-docform-title {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: bold;
        font-size: 42px;
        line-height: 55px;
        color: $colorDarkGrey;
      }
      .dfs-docform-fieldset {
        .dfs-docform-label {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 25px;
          color: $colorSubheadingDesc;
        }
        .finances-table-section {
          .finances-card-table-body {
            .finances-card-table-row {
              font-family: 'Poppins-Regular';
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 22px;
              color: $colorDarkGrey;
              .finances-card-table-detail {
                .income-details-content {
                  .income-type-name {
                  }
                }
              }
              .finances-card-table-amount {
                text-align: end;
              }
              .finances-card-table-total {
                font-family: 'Poppins-Medium';
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
              }
            }
          }
        }
        .dfs-question {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: $colorDarkGrey;
        }
        .dfs-answer {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 22px;
          color: $colorDarkGrey;
        }
      }
    }
    .estimateRepayments-message {
      padding-top: 1.5rem;
      margin-left: 20px;
      margin-right: 20px;
      @media screen and (max-width: 767.95px) {
        border-top: solid 2px $colorLightGrey;
        margin-top: 20px;
      }
      @media screen and (min-width: 768px) {
        padding: 1.5rem;
      }
      @media screen and (min-width: 1199.98px) {
        margin-left: 86px;
        margin-right: 134px;
      }
      &.loan-summary-button {
        padding: 0;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 768px) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 0;
          margin: 0;
        }
      }
      .estimateRepayments-name {
        color: $colorLightBlue;
        font-family: 'Poppins-Bold';
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        @media screen and (min-width: 768px) {
          font-size: 42px;
        }
      }
      .estimateRepayments-title {
        margin-bottom: 10px;
        color: $colorDarkGrey;
        font-family: 'Poppins-Bold';
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        @media screen and (min-width: 768px) {
          font-size: 42px;
          margin-bottom: 10px;
        }
      }

      .estimateRepayments-subtitle {
        color: $colorSubtitle;
        font-family: 'Poppins-Regular';
        font-size: 22px;
        letter-spacing: 0;
        line-height: 33px;
        margin-bottom: 20px;
      }
      .estimate-Repayments-quote {
        display: flex;
        flex-direction: column;
        .estimateRepayments-ratelabel {
          display: flex;
          color: $colorDarkGrey;
          font-family: 'Poppins-Regular';
          font-size: 17px;
          letter-spacing: 0;
          margin-bottom: 26px;
          .getrate {
            margin-left: 10px;
          }
        }
      }
      .review-information {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .estimateRepayments-ratelabel {
          display: flex;
          color: $colorDarkGrey;
          font-family: 'Poppins-Regular';
          font-size: 14px;
          letter-spacing: 0;
          margin-right: 10px;
          align-items: center;
          margin-bottom: 10px;
          .getrate {
            margin-left: 10px;
          }
          .check-circle-outline-color {
            fill: $colorLightBlue;
          }
        }
        @media screen and (min-width: 880px) {
          display: flex;
          flex-direction: row;
        }
      }
      .privacy-policy {
        color: $colorSubtitle !important;
        font-family: 'Poppins Regular';
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 30px;
        @include privacy-statement;
        .btn-policy-path {
          color: $colorSubtitle;
          font-family: 'Poppins-SemiBold';
          white-space: nowrap;
          text-decoration: underline;
        }
      }
      .email-quote {
        //display: flex;
        //flex-direction: column;
        margin-top: 3rem;
        &.compare-loan-options {
          text-align: end;
          width: 100%;
          @media screen and (min-width: 768px) {
            text-align: end;
            width: auto;
          }
        }

        &.nav-previous-page {
          text-align: end;
          width: 100%;
          @media screen and (min-width: 768px) {
            text-align: end;
            width: auto;
          }
        }
        .letsgetstarted-btn {
          height: 56px;
          border-radius: 3px;
          background-color: $colorPrimaryBlue;
          color: $colorWhiteGrey;
          font-family: 'Poppins-Medium';
          font-size: 17px;
          font-weight: 500;
          padding: 16px;
          &:hover {
            background-color: $colorhover;
          }
          &:disabled {
            background-color: $colorInactive;
            border-color: $colorInactive;
            color: $colorWhiteGrey;
          }
          &:not(:disabled):focus,
          &:not(:disabled):active {
            background-color: $colorPrimaryBlue !important;
            color: $colorWhiteGrey;
            opacity: 0.6;
          }
          &.compare-loan-options {
            width: 100%;
            @media screen and (min-width: 576px) {
              text-align: end;
              margin-top: 10px;
              width: auto;
            }
          }
          &.move-to-previous {
            width: 100%;
            color: $colorDarkGrey;
            background-color: $colorPreviousBackground;
            margin-bottom: 10px;
            @media screen and (min-width: 576px) {
              text-align: end;
              margin-top: 10px;
              width: auto;
            }
          }
        }
      }

      .asset-type-selector-container {
        margin-top: 3rem;
        max-width: 600px;

        & .text {
          font-size: 1.5rem;
          font-weight: bold;

          @media only screen and (max-width: 400px) {
            font-size: 1.3rem;
          }
        }

        & .text-container {
          max-width: 760px;

          @media only screen and (max-width: 400px) {
            margin-bottom: 3rem;
            margin-top: 2rem;
            max-width: 300px;
          }

          & .gridItemContainer {
            @media only screen and (max-width: 400px) {
              padding: 0.5rem;
            }

            .label {
              font-size: 12px;
            }
          }
          & .icon {
            color: $colorLightBlue;
          }
          & .label {
            font-size: 0.7rem;
          }
        }
      }
    }
    .loan-summary-page {
      margin: 0;
      @media screen and (min-width: 768px) {
        margin: 24px;
      }
      @media screen and (min-width: 960px) {
        margin: 30px 80px;
      }
      .loan-summary-page-name {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;

        padding: 12px;
        padding-bottom: 0;

        @media screen and (min-width: 768px) {
          font-size: 42px;
          line-height: 55px;
          padding: 0;
        }
        &.isValid {
          color: $colorLightBlue;
        }
        &.isInValid {
          color: $colorLightRed;
        }
      }
      .loan-summary-page-title {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: $colorDarkGrey;
        margin-bottom: 0;
        padding: 12px;
        padding-top: 0;
        @media screen and (min-width: 768px) {
          font-size: 42px;
          line-height: 55px;
          margin-bottom: 10px;

          white-space: normal;
          padding: 0;
        }
        @media screen and (min-width: 1120px) {
          font-size: 42px;
          line-height: 55px;
          margin-bottom: 10px;
          padding: 0;
          white-space: nowrap;
        }

        .borrow-amt {
          padding: 0 10px;
          border-radius: 6px;
          &.valid-loan-amount {
            background: $colorValid;
            border-radius: 6px;
          }
          &.invalid-loan-amount {
            background: $colorInvalid;
            border-radius: 6px;
          }
        }
      }
      .loan-summary-page-subtitle {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        color: $colorSubheadingDesc;
        padding: 12px;
        padding-top: 0;
        padding-bottom: 0;
        @media screen and (min-width: 768px) {
          font-size: 22px;
          line-height: 33px;
          margin-bottom: 20px;
        }
      }
      .loan-summary-page-fieldset {
        border: 1px solid $colorBorderColor;
        border-radius: 4px;
        box-sizing: border-box;
        margin: 12px;
        margin-top: 0;
        margin-bottom: 10px;
        .spouse-income-occupation-row {
          display: flex;
          flex-direction: column;
          margin: 0;
          @media screen and (min-width: 1000px) {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
          }
          .spouse-income-occupation {
            margin: 0;
            padding: 0;
            flex: 1;
            flex-basis: 100%;
            @media screen and (min-width: 1000px) {
              flex: 1;
              flex-basis: 50%;
            }
          }
          .spouse-income-employer {
            flex: 1;
            flex-basis: 100%;
            padding: 0;

            @media screen and (min-width: 1000px) {
              margin-right: 20px;
              flex: 1;
              flex-basis: 50%;
            }
          }
        }
        &.spouse-income-partners {
          margin: 12px;
          padding: 12px;

          @media screen and (min-width: 768px) {
            padding: 20px 30px 20px 30px;
          }
        }
        @media screen and (min-width: 768px) {
          padding-top: 0;
          margin: 0;
          margin-bottom: 10px;
        }
        .loan-summary-page-legend {
          width: auto;
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $colorSubheadingDesc;
          padding: 5px 10px;
          margin: 0;
          @media screen and (min-width: 768px) {
            font-size: 17px;
            line-height: 25px;
          }
        }
        .loan-estimate-edit {
          top: 0;
          padding-left: 22px;
          position: absolute;
          right: 20px;
          line-height: 16px;
          border-radius: 3px;
          color: $colorDarkGrey;
          font-family: 'Poppins-Medium';
          font-size: 14px;
          font-weight: 500;
          background: $colorLoanBackground url('../../../assets/image/Edit_Dashboard.svg') center
            left/auto no-repeat;
          background-size: 16px;
          height: 30px;
          width: 74px;
        }

        .loan-summary-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 12px;
          @media screen and (min-width: 768px) {
            margin: 20px 30px 20px 30px;
          }
          .loan-amt-term-section {
            display: flex;
            flex-direction: column;
            .amount {
              font-family: 'Poppins-Bold';
              font-style: normal;
              font-weight: 600;
              font-size: 30px;
              line-height: 45px;
              color: $colorDarkGrey;
              text-transform: capitalize;
              &.invalid-loan-amount {
                background: $colorInvalid;
                border-radius: 3px;
                width: max-content;
              }
              &.valid-loan-amount {
                background: $colorValid;
                border-radius: 3px;
                width: max-content;
              }
            }

            .label {
              font-family: 'Poppins-Regular';
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: $colorSubtitle;
            }
          }
          @media screen and (min-width: 1100px) {
            flex-direction: row;
          }
        }
        .loan-summary-row {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          margin: 10px 15px 10px 15px;
          align-items: center;
          @media screen and (min-width: 768px) {
            margin: 20px 30px 20px 30px;
          }
          cursor: pointer;

          &.non-clickable {
            cursor: inherit;
          }

          .loan-summary-row-content-section {
            display: flex;
            flex-flow: row;
            .image-border {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              background-color: $colorBorderBackground;
              margin-right: 10px;
              .arrowup {
                height: 20px;
                width: 20px;
                margin: 10px;
              }
              @media screen and (min-width: 768px) {
                margin-right: 17px;
              }
            }
            .loan-summary-content-columns {
              display: flex;
              flex-direction: column;
              .loan-summary-row-heading {
                font-family: 'Poppins-Medium';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: $colorDarkGrey;
                @media screen and (min-width: 768px) {
                  margin-right: 17px;
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              .loan-summary-row-description {
                font-family: 'Poppins-Regular';
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 15px;
                color: $colorSubheadingDesc;
                @media screen and (min-width: 768px) {
                  font-size: 14px;
                  line-height: 21px;
                }
              }
            }
          }
          .arrow-link-icon {
            height: 18px;
            width: 18px;
            display: block;
            @media screen and (min-width: 991px) {
              display: none;
            }
          }
          .btn-add {
            display: none;
            .add-btn-name {
              font-family: 'Poppins-Medium';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: $colorDarkGrey;

              .arrow-link {
                width: 14px;
                height: 14px;
                margin-left: 10px;
              }
            }
            @media screen and (min-width: 992px) {
              background: $colorPreviousBackground;
              border-radius: 3px;
              padding: 7px 14px;
              display: block;
            }
          }
          .loan-summary-horizantal {
            margin: 0;
          }
        }
        .spouse-income-line {
          margin-left: -12px;
          margin-right: -12px;
          @media screen and (min-width: 768px) {
            margin-left: -30px;
            margin-right: -30px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        .update-loandetails-footer {
          margin: 12px;
          margin-right: 0;
          @media screen and (min-width: 768px) {
            margin-bottom: 0;
            margin-right: -10px;
          }
        }
        .spouseEmpCurrDt {
          flex-basis: 100%;
          @media screen and (min-width: 1000px) {
            min-width: 50%;
          }
        }
      }
    }
  }
}
.error-message {
  display: flex;
  flex-flow: column;
  font-family: 'Poppins-Regular';
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: normal;
  color: $colorDarkGrey;
  padding: 0 30px 0 30px;
}

.login-button1 {
  @media screen and (max-width: 767.95px) {
    display: inline;
    float: right;
  }
  .btn-login1 {
    height: 48px;
    padding: 12px 22px;
    background: $colorPrimaryBlue;
    border-radius: 3px;
    margin: 0 20px;
    @media screen and (max-width: 767.95px) {
      padding: 12px 12px;
      margin-top: 10px;
    }
    .lock-icon1 {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
    .login-label1 {
      font-family: 'Poppins-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: $colorWhiteGrey;
    }
  }
}

.call-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  white-space: nowrap;
  padding-right: 8px;

  @media screen and (max-width: 767.95px) {
    display: none;
  }

  .call-us {
    color: $colorDarkGrey;
    font-family: 'Poppins-Regular';
    font-size: 12px;
  }
  .phone {
    color: $colorDarkGrey;
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
    font-weight: 600;
  }
}
.header-left-align {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.stratton-info-nav-container {
    justify-content: flex-start;
    align-items: center;
  }
}
.stratton-info-nav-container-mobile-view {
  display: flex;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
}
.contact-header {
  font-size: 20px;
  line-height: 28.1px;
  font-weight: 700;
  font-family: 'Poppins-Bold';
  color: $colorDarkGrey;
}

.contact-subheading {
  font-family: 'Poppins-Regular';
  color: $colorSecondaryText;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  padding-top: 3px;
}

.contact-header-mobileView {
  padding-bottom: 5px;
}

.contact-button-container {
  display: flex;
  align-items: flex-end;
}

.contact-button-container-mobileView {
  justify-content: flex-end;
  padding: 8px 0px;
}

.buttonContainer {
  border: solid 1px $colorButtonDisable;
}

.buttonContainer:hover {
  background-color: $colorHoverBackground !important;
  color: $colorDarkGrey;
  border: solid 1px $colorButtonDisable;
}
.header-right-align {
  display: flex;
  justify-content: flex-end !important;
  float: right;
  padding: 1rem 5rem;
}
