@import '../../styles/variable.scss';
.update-loan-details-form {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0;
  @media screen and (min-width: 768px) {
    margin-top: 30px;
    margin-left: 80px;
    margin-right: 140px;
  }
  .update-loan-details-title {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #25282a;

    padding: 12px;
    padding-bottom: 0;
    .loan-details-highlight {
      background-color: #e0f8fa;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 20px;
      font-size: 42px;
      line-height: 55px;
    }
  }
  .update-loan-details-fieldset {
    border: 1px solid #e4e8ea;
    border-radius: 4px;
    box-sizing: border-box;
    padding-top: 0px;
    margin: 12px;

    // @media screen and (min-width: 768px) {
    //   margin-bottom: 0px 0px 10px 0px;
    // }
  }
}

.amount-range-selector,
.estimate-range-slider {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #25252a;
  font-family: 'Poppins-Regular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  padding: 12px;

  @media screen and (min-width: 768px) {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
  }
  .amount-range-slider,
  .estimation-range-selector,
  .estimate-loan-amount {
    flex-basis: 100%;
    width: 100%;
    max-width: 100%;
    padding-top: 0px;
    @media screen and (max-width: 900px) and (min-width: 1100px), (min-width: 600px) {
      //width: auto;
      flex-basis: 50%;
      width: 50%;
      max-width: 50%;
      line-height: 1;
      padding-left: 30px;
      padding-bottom: 0px;
    }
    @media screen and (max-width: 899.95px) and (min-width: 768px) {
      //width: auto;
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
      padding-left: 30px;
      padding-bottom: 0px;
    }
  }
}
.estimate-range-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;
  padding-top: 0;

  .estimate-loan-amount {
    font-family: 'Poppins-SemiBold';
    font-size: 24px;
    font-weight: 600;
    color: $colorDarkGrey;
    padding-left: 0px;
    flex-basis: 100%;
    padding-bottom: 12px;
    @media screen and (min-width: 600px) {
      font-size: 36px;
      padding-left: 30px;
      flex-basis: 50%;
      margin-top: 0;
    }
    .loan-amt-label {
      font-family: 'Poppins-Regular';
      font-size: 12px;
      font-weight: normal;
      color: #66686a;
    }
  }
}
.estimate-slider {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-left: 12px;
  .estimate-loan-amount {
    font-family: 'Poppins-SemiBold';
    font-size: 36px;
    font-weight: 600;
    color: $colorDarkGrey;

    .loan-amt-label {
      font-family: 'Poppins-Regular';
      font-size: 15px;
      font-weight: normal;
      color: #66686a;
    }
  }
  .estimate-loan-term {
    .term-val {
      display: flex;
      color: $colorDarkGrey;
      font-family: 'Poppins-SemiBold';
      font-size: 30px;
      font-weight: 600;
    }
    .loan-amt-label {
      font-family: 'Poppins-Regular';
      font-size: 15px;
      font-weight: normal;
      color: #66686a;
    }
  }
  @media screen and (min-width: 768px) {
    padding-left: 0;
  }
}
.residual-switch {
  padding-left: 0;
  white-space: nowrap;
  align-items: center;
  &.residual-switch_update {
    padding-left: 0;
    white-space: nowrap;
    align-items: center;
    @media screen and (min-width: 600px) {
      padding-left: 30px;
    }
  }

  .residual-switch-label {
    max-width: calc(100% - 0.5rem);
    white-space: pre-wrap;
    color: $colorDarkGrey;
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;

    padding-right: 1rem;

    @media screen and (min-width: 1080px) {
      padding-right: 2rem;
    }

    .include-residual {
      display: block;
    }
  }

  .custom-control-label {
    &::before {
      top: 15px;
      left: -0.2rem;
      width: 50px;
      height: 30px;
      border-radius: 16.5px;
      background-color: $colorTail;
    }

    &.checked {
      &::before {
        background-color: $colorResidualSwitch;
        border-color: $colorResidualSwitch;
      }
    }

    &::after {
      top: 18px;
      height: 24px;
      width: 24px;
      border-radius: 15px;
      background-color: #ffffff;
      box-shadow: -1px 0 4px 0 rgba(18, 78, 84, 0.36);
      background-color: #fff;
      // left: calc(-0.2rem + 13px);
      left: 0px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background-color: $colorWhite;
    left: 8px;
  }
}
.update-loandetails-footer {
  text-align: end;
  margin: 20px;
  .update-cancel {
    height: 40px;
    padding: 0px 16px 0px 16px;
    background: #e4e8ea;
    border-radius: 3px;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $colorSubheadingDesc;
    /* identical to box height */
    margin-right: 10px;
    text-align: center;
  }
  .update-loanamt {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    background: #330072;
    border-radius: 3px;
    height: 40px;
  }
}
