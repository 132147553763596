@import '../../../styles/variable.scss';

.dashboard-dfs-docform {
  .dfs-docform-body-section {
    .quote-spinner {
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dfs-docform-fieldset {
      legend {
        margin-bottom: 0;
        font-size: 17px;
        line-height: 25px;
        color: $colorAcknowledge;
      }

      .table-responsive {
        width: calc(100% + 2rem);
        margin: -1rem -1rem 0;

        .finances-table-section {
          margin-bottom: 0;

          tr:first-child {
            td {
              border-top: none;
            }
          }

          tr:last-child {
            font-family: 'Poppins-SemiBold';
          }

          tr.dfs-question-table-row {
            font-family: 'Poppins-SemiBold';
          }

          td {
            padding: 0.75rem 1.5rem;
            font-size: 15px;
            line-height: 22px;
            color: $colorDarkGrey;

            .dfs-answer {
              font-family: 'Poppins-Regular';
              display: block;
              padding-top: 6px;
            }

            &.no-border {
              border-top: none;
            }
          }
          td:last-child:not(.dfs-question) {
            text-align: right;
          }
        }
      }

      .credit-card-note {
        font-family: 'Poppins-Regular';
        color: $colorDarkGrey;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
      }
    }

    .dfs-acceptance {
      label {
        font-family: 'Poppins-Regular';
        font-size: 12px;
        line-height: 18px;
        color: $colorSubtitle;
      }
    }

    .dfs-submit-action {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: 780px) {
        button {
          width: 50%;
        }
      }
    }
  }
}
