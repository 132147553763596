@import '../../../styles/variable.scss';

.navigatenextpagecontainer {
  .navigatenextpage {
    .navigatenextpagebtn {
      /* width: 211px; */
      height: 3rem;
      border-radius: 4px;
      background-color: $colorGreenSuccess;
      color: white;
      border: solid 1px $colorGreenSuccess;
      padding: 0px 32px 0px 32px;
    }

    .right-icon {
      //transform: rotate(180deg);
      height: 16px;
      padding-left: 2px;
    }
  }

  .savequotepage {
    .savequotebtn {
      /*  width: 211px; */
      height: 3rem; //14jan changes
      border-radius: 3px;
      border: solid 1px #bebebf;
      background-color: white;
      padding: 0px 32px 0px 32px;
    }
  }
}

.hyper-link {
  color: white !important;
  text-decoration: underline;
}

.margin-custum-card{
  margin-bottom: 24px;
}
.newCard {
  box-sizing: border-box;
  width: 100%;
  max-width: 496px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background: $colorWhite;
  border: 1.05543px solid #d0d0d0;
  line-height: 0.75 !important;
  font-family: 'Poppins-Regular' !important;
}

.cardBody {
  padding: 20px 30px;
  font-family: 'Poppins-Regular' !important;
  margin-top: 10px !important;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  letter-spacing: -0.007em;
}

ul {
  list-style: none;
  text-align: initial;
}

.tick {
  padding-left: 10px;
  line-height: 16px;
}

.complete {
  padding-left: 20px !important;
  padding-bottom: 20px !important;
  font-size: 0.9rem;
}

.list-custom {
  display: inline-block !important;
}

.sub-complete {
  padding-left: 18px !important;
  padding-bottom: 10px !important;
}

ul.complete li::before {
  content: url('../../../../src/assets/image/Tick.svg');
}

ul.sub-complete li::before {
  content: url('../../../../src/assets/image/circle.svg');
  padding-right: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .newCard {
    padding: 10px;
  }

  .cardBody {
    padding: 10px;
    font-size: 18px;
    line-height: 1.3;
  }

  ul.complete {
    padding-left: 20px !important;
    padding-bottom: 0 !important;
    line-height: 130%;

    .MuiListItem-gutters {
      padding: 10px 8px 10px 16px;
    }

    .MuiList-padding {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.price-slider-input {
  height: 45px;
  border-radius: 3px;
  border: solid 1px #bebebf;
  background-color: #ffffff;
}

.car-applicants-container {
  .fit-screen {
    margin-bottom: 75vh;
  }
}

.title-with-sub-label {
  &.car-details-full-screen {
    .car-details-main-label {
      font-family: 'Poppins-SemiBold';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: $colorDarkGrey;

      @media screen and (min-width: 576.95px) {
        font-size: 22px;
        line-height: 33px;
      }
    }

    .car-details-main-sublabel {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 22px;
      color: $colorSubtitle;
      margin-bottom: 12px;

      @media screen and (min-width: 576.95px) {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 30px;
      }
    }
  }

  .title-sub-label {
    color: $colorDisabledGrey;
    margin-right: 8px;
  }
}

.car-applicants-main-container {
  background-color: $colorBackground;

  .car-applicants-main-tab-container {
    &.car-subscreen-container {
      margin-top: 0;

      @media screen and (min-width: 960.95px) {
        margin-top: 30px;
      }

      .car-details-header {
        padding: 12px;

        @media screen and (min-width: 600px) {
          padding: 24px;
        }

        .car-details-title {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: $colorDarkGrey;

          @media screen and (min-width: 600px) {
            font-size: 18px;
            line-height: 27px;
          }
        }

        .car-details-subtitle {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $colorSubtitle;

          @media screen and (min-width: 600px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .car-details-border {
        margin: 0;
        color: $colorBackground;
      }
    }

    .car-applicants-main-subcontainer {
      margin-bottom: 10rem;

      &.car-details-content-block {
        padding: 12px;

        @media screen and (min-width: 600px) {
          padding: 24px;
        }
      }

      .car-applicants-main-section {
        .car-rego-question-container {
          max-width: 731px;

          .rego-manual {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            @media screen and (min-width: 600px) {
              display: flex;
              flex-direction: row;
              align-items: baseline;
            }

            .or-style {
              text-align: left;
              padding-right: 10px;
            }

            .manualenter-style {
              text-align: left;

              .manual-entry-btn {
                // padding-left: 0;
                white-space: pre;
                border-radius: 3px;
                background-color: #e4e8ea;
                color: $colorDarkGrey;
                font-family: 'Poppins-Medium';
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 21px;
              }
            }
          }
        }

        .car-applicants-main-rego-question {
          font-family: 'Poppins-Regular';
          //text-align: center;21feb
          color: #212b36;
        }

        .car-applicants-main-rego {
          display: flex;
          justify-content: center !important;
          text-align: left;
          align-items: center;
          flex-wrap: wrap;

          .rego-number {
            padding: 0;
            flex-basis: 100%;

            @media (min-width: 576px) {
              padding-right: 16px;
              flex-basis: 50%;
            }

            @media (min-width: 768px) {
              flex-basis: 50%;
              padding-right: 30px;
              flex: 2;
            }
          }

          .rego-state {
            padding: 0;
            flex-basis: 100%;

            @media (min-width: 576px) {
              flex-basis: 50%;
            }

            @media (min-width: 768px) {
              flex-basis: 25%;
              padding-right: 30px;
              flex: 1;
            }
          }

          .search-action {
            flex-basis: 100%;
            padding-left: 0;
            height: 100%;
            margin-bottom: 32px;

            @media (min-width: 768px) {
              margin-top: 8px;
              margin-bottom: 0;
              flex-basis: 15%;
              flex: 1;
            }

            .btn-primary {
              background-color: $colorPrimaryBlue;
              width: 100%;
            }
          }
        }

        .car-applicants-main-loanamount-label {
          color: #66686a;
          font-family: 'Poppins-Regular';
          font-size: 18px;
        }

        .car-applicants-loan-form {

          //text-align: end;
          .car-applicants-main-loanamount {

            // margin-bottom: 50vh;
            .loan-amount-value {
              font-size: 42px;
              color: $colorDarkGrey;
              font-family: 'Poppins-SemiBold';

              &.car-details-loan-amt {
                font-size: 34px;
              }
            }
          }
        }

        .car-applicants-main-form {
          //padding-top: 10vh;
          padding-bottom: 25vh;

          &.car-applicants-footer-wrap {
            padding-bottom: 0;
          }

          .fit-screen {
            margin-bottom: 50vh;
          }

          .skipped-action {
            margin-top: 45vh;
          }

          .label {
            font-family: 'Poppins-Medium';
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: $colorDarkGrey;

            .sub-label {
              font-family: 'Poppins-Regular';
            }

            &.car-main-screen-label {
              font-family: 'Poppins-SemiBold';
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;

              @media screen and (min-width: 960.95px) {
                font-size: 22px;
                line-height: 33px;
              }

              .sub-label {
                font-size: 17px;
                line-height: 25px;
                font-family: 'Poppins-Regular';
              }
            }
          }

          // .range-selector-label {
          //   font-size: 18px;
          //   font-family: 'Poppins-Regular';
          //   color: $colorDarkGrey;
          // }

          .year-class {
            .select-field-section {
              .btn-icon {
                display: none;
              }
            }
          }

          .car-applicants-main-prepopulate {
            flex-basis: 100%;
            max-width: 100%;
            margin-top: 2rem;
            margin-bottom: 1rem;
            padding: 0 !important;

            &.car-details-prepopulate {
              margin-bottom: 0;
            }

            @media (min-width: 768px) {
              margin-top: 0 !important;
            }

            .car-details-header {
              font-family: 'Poppins-Regular';
              font-size: 18px;
              font-weight: 300;
              color: #212b36;
            }
          }

          .car-applicants-main-loanamount-label {
            color: #66686a;
            font-family: 'Poppins-Regular';
            font-size: 18px;

            &.car-details-loan-amount-label {
              font-family: 'Poppins-Regular';
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: $colorSubtitle;
            }
          }

          .car-applicants-loan-form {

            //text-align: center;
            .car-applicants-main-loanamount {

              // margin-bottom: 50vh;
              .loan-amount-value {
                font-size: 42px;
                color: $colorDarkGrey;
                font-family: 'Poppins-SemiBold';

                &.car-details-loan-amt {
                  font-size: 34px;
                }
              }
            }
          }

          .purchase-price-selector {}
        }

        .footer-actions {
          .btn-next-page {
            border-radius: 4px;
            background-color: $colorPrimaryBlue;
            white-space: nowrap;
            color: #ffffff;
            height: 56px;
            width: 130px;

            &:active {
              background-color: $colorPrimaryBlue !important;
              opacity: 0.6;
            }

            &:focus,
            &:hover {
              background-color: $colorhover !important;
              opacity: 0.6;
            }

            .right-icon {
              height: 13px;
              width: 8px;
            }

            &:disabled {
              color: #a4a7aa;
              background-color: #eef2f5;
            }
          }

          .btn-navigate-page {
            height: 56px;
            //width: 130px;
            border-radius: 4px;
            background-color: $colorPrimaryBlue;
            color: #ffffff;
            border: solid 1px $colorPrimaryBlue;
            padding: 0px 32px 0px 32px;

            &:hover {
              background-color: $colorhover;
            }

            &:active,
            &:focus {
              background-color: $colorPrimaryBlue !important;
              opacity: 0.6;
            }

            .right-icon {
              height: 13px;
              width: 8px;
            }
          }

          .car-details-footer {
            border-top: 1px solid #f1f2f2;
            margin-left: -24px;
            margin-right: -24px;

            .car-details-next-btn {
              margin-top: 20px;
              padding-right: 24px !important;
            }
          }
        }

        .skip-action-container {
          position: relative;
          bottom: 0;
          background-color: transparent;
          width: auto;
          justify-content: flex-start;
        }
      }

      .footer-mainpage {
        height: 100px;
        box-shadow: 0 -6px 6px 0 rgba(99, 115, 129, 0.05);
        background-color: #ffffff;
        text-align: center;
        position: sticky;
        bottom: 0;
        z-index: 2000;
      }
    }
  }

  &.full-width-container {
    .car-applicants-main-subcontainer {
      margin-bottom: 0;

      .car-applicants-main-form {
        padding-top: 4vh !important;

        .label {
          font-size: 20px;
          font-family: 'Poppins-Regular';
          color: $colorDarkGrey;

          .sub-label {
            font-family: 'Poppins-SemiBold';
          }
        }

        .range-selector-label {
          font-size: 18px;
          font-family: 'Poppins-Regular';
          color: $colorDarkGrey;
        }

        .centered-field {
          max-width: 730px;
          font-family: 'Poppins-Regular';
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: auto;

          .title {
            font-size: 22px;
          }

          .container {
            .text {
              font-size: 22px;
              margin-bottom: 31px;
              text-align: center;
            }

            .form-label {
              width: 100%;
              text-align: left;
            }

            .button-container {
              display: flex;
              align-items: center;
              justify-content: right;
            }
          }

          & .small {
            max-width: 440px;
            padding: 0;

            .form-group {
              padding: 0;
            }
          }
        }

        >div {
          &:not(:first-child):not(.amount-range-container):not(.footer-actions):not(.car-details-options-section):not(.skip-action-container):not(.deposit-price-scroll-selector) {
            // padding-top: calc(6vh + 90px);
          }

          &.amount-range-container {
            &.purchase-price-selector {
              // padding-top: calc(6vh + 90px);
            }

            &.loan-term-selector {
              margin-top: 50vh;
            }
          }
        }

        .car-rego-question-container {
          max-width: 731px;
          margin: 0 auto;

          .rego-manual {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            @media screen and (min-width: 600px) {
              display: flex;
              flex-direction: row;
              align-items: baseline;
            }

            .or-style {
              text-align: left;
              padding-right: 10px;
            }

            .manualenter-style {
              text-align: left;

              .manual-entry-btn {
                // padding-left: 0;
                white-space: pre;
                border-radius: 3px;
                background-color: #e4e8ea;
                color: $colorDarkGrey;
                font-family: 'Poppins-Medium';
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 21px;
              }
            }
          }
        }

        .btn-skip-page {
          // margin-top: -100vh;

          .fa {
            padding-left: 12px;
          }
        }

        .form-row {
          justify-content: center;
          max-width: 1080px;
          margin: auto;
          margin-bottom: 10px;
        }

        .select-field-container {
          .select-field-section {
            @media (min-width: 520px) {
              justify-content: center;

              &.vehicle-variant-options {
                display: grid;

                justify-content: center;
              }

              &.select-field-largecontainer {
                justify-content: flex-start;
                margin-left: 60px;

                @media screen and (max-width: 597.95px) {
                  margin-left: 0;
                }
              }
            }

            &.select-field-mediumcontainer {
              @media screen and (max-width: 767.95px) {
                justify-content: flex-start;
                //margin-left: 100px;
              }

              @media screen and (max-width: 597.95px) {
                margin-left: 0;
              }
            }
          }
        }

        .car-applicants-main-rego {
          display: flex;
          justify-content: center !important;
          text-align: left;

          .rego-number {
            flex-basis: 100%;

            @media (min-width: 576px) {
              flex-basis: 50%;
            }

            @media (min-width: 768px) {
              flex-basis: 50%;
              padding-right: 30px;
              flex: 2;
            }
          }

          .rego-state {
            flex-basis: 100%;

            @media (min-width: 576px) {
              flex-basis: 50%;
            }

            @media (min-width: 768px) {
              flex-basis: 25%;
              padding-right: 30px;
              flex: 1;
            }
          }

          .search-action {
            flex-basis: 100%;
            padding-left: 0;

            @media (min-width: 768px) {
              // padding-left: 1.5rem;
              flex-basis: 15%;
              flex: 1;
            }

            .btn-primary {
              margin-top: 9px;
              background-color: $colorPrimaryBlue;
              width: 100%;
            }
          }
        }

        /*    .skip-action-container {
          justify-content: center;
          margin: 0;
         

          @extend: 80%;

          @media screen and (min-width: 645px) {
            max-width: 80%;
          }

          @media screen and (min-width: 767.95px) {
            max-width: 90%;
          }

          @media screen and (min-width: 1180px) {
            //max-width: 1080px;
            width: 100%;
          }

          
        } */
        .car-applicants-loan-form {
          text-align: center;

          .car-applicants-main-loanamount {

            // margin-bottom: 50vh;
            .loan-amount-value {
              font-size: 42px;
              color: $colorDarkGrey;
              font-family: 'Poppins-SemiBold';

              &.car-details-loan-amt {
                font-size: 34px;
              }
            }
          }
        }
      }
    }
  }
}

.footer-cardetails-page {
  box-shadow: 0 -6px 6px 0 rgba(99, 115, 129, 0.05);
  background-color: #ffff;
  //position: fixed;
  position: sticky;
  bottom: 0;
  z-index: 1050;
  padding: 0;
  margin-top: 32px;

  .partner-desc-header {
    background-color: $colorBackground;
    padding: 5px 20px 5px 20px;
    font-size: 15px;
    font-weight: bold;
  }

  .icon-expand {
    float: right;
  }

  .partner-desc-content {
    background-color: $colorBackground;
    padding: 10px 20px 10px 20px;
    font-size: 14px;

    @media screen and (max-width: 576.95px) {
      height: 100px;
      overflow-x: scroll;
    }

    .warning-text {
      font-weight: bold;
      color: $colorBlack;
    }
  }

  .progress-wrapper {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;

    .progress-info-container {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Poppins-Regular';
      text-align: center;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 27px;
      color: $colorDarkGrey;

      @media screen and (min-width: 576.95px) {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 33px;
      }

      .cardetailspage-loanoptions {
        color: #00cd76;
        font-family: 'Poppins-Bold';
        font-size: 42px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 63px;
        text-align: center;
        font-weight: bold;
      }
    }
  }
}