// Color Pallettes used

$colorWhite: #fff;
$colorBlack:#000;
$colorBackground: #f1f2f2;
$colorExtraLightGrey: #f8f9fa;
$colorLightGrey: #f1f2f2;
$colorDisabledGrey: #637381;
$colorSubtitle: #66696c;
$colorSlateGrey: #66686a;
$colorDarkGrey: #25282a;
$colorSecondaryGrey: #e4e8ea;
$colorButtonDisable: #c2c6c8;
$colorText: #212b36;
$colorSecondaryText: #2c3e50;
$colorSecondaryBlue: #00aabb;
$colorResidualSwitch: #00c1d5;
$colorPrimaryBlue: #330072;
$colorhover: #530280;
$colorTail: #e5e7e7;
$colorInactive: #bebebf;
$colorSubStepInactive: #929394;
$colorGreenSuccess: #05bd7f;
$colorSecondaryButton: #a4a7aa;
$colorSubheadingDesc: #7a7e80;
$colorTexthighlight: #e0f8fa;
$colorStatus: #ffdc73;
$colorAcknowledge: #474a4c;
$colorDisabled: #e9ecef;
$colorRed: #FF0000;
$colorLightRed: #e12f55;
$colorLightBlue: #01b0c2;
$colorLightGreen: #00cd76;
$colorWhiteGrey: #ffffff;
$colorPreviousBackground : #eef2f5;
$colorNavigationBackground : #dce2e4;
$colorBorderColor: #e4e8ea;
$colorLoanBackground : #f3f7fa;
$colorInvalid: #ffe5ea;
$colorValid: #dff8ec;
$colorBorderBackground : #b3ecf2;
$colorEmpFieldName:#dc3545;
$colorBackgroundEmployer: #F2F2F2;
$colorDatePickerHelper: #f44336;
$colorBorderBottom: #dee2e6;
$colorTrackVerify: #e7f9f3;
$colorSelectedBorder: #1EB9C9;
$colorDeleteButton: #f23457;
$colorHoverBackground: rgba(0, 193, 213, 0.1);
$colorBoder:rgba(5, 189, 127, 0.1);
$colorBackgroundHover: rgba(224, 248, 250, 0.5);
$colorFreqShow:rgba(0, 0, 0, 0.23);
