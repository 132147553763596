@import '../../../../styles/variable.scss';
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-right: 10px;
  color: $colorSubheadingDesc;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Poppins-Regular';
  font-size: 12px;
  // color: $colorDarkGrey;
  &.acknowledge-message {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $colorAcknowledge;
    text-align: justify;
  }
}
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  border: solid 1px #bebebf;
  background-image: linear-gradient(to bottom, #fff, #f9fafb);
}
.checkbox-container:hover input ~ .checkmark {
  opacity: 0.8;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  border-color: #00aabb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 2px;
  width: 6px;
  height: 13px;
  border: solid #00aabb;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.policy-confirmation-container {
  margin: 0;
  padding: 10px;
  .declaration-check {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
    @media screen and (min-width: 600px) {
      font-family: 'Poppins-Regular';
      font-size: 17px;
      letter-spacing: 0;
      line-height: 26px;
    }
    .foreseeDescription {
      margin-bottom: 20px;
    }
  }
  @media screen and (min-width: 600px) {
    padding: 24px 36px 24px 36px;
  }
}
