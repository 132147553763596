@import '../../../styles/variable.scss';
@import '../../../styles/global.scss';
@import '../ContextPages/context-pages.scss';
@mixin logo-align($image, $width, $height) {
  background: url($image) center center/contain no-repeat;
  width: $width;
  height: $height;
}
.estimateRepayments-page-details {
  .compare-loan-options-page {
    @media screen and (max-width: 575.95px) {
      margin: 10px;
    }
    @media screen and (min-width: 600px) and (max-width: 1099.95px) {
      margin: 20px;
    }
    @media screen and (min-width: 1099px) {
      margin-right: 50px;
      margin-top: 30px;
    }

    .compare-options-caption {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      color: #00c1d5;
      padding: 12px;
      padding-right: 0;
      padding-left: 0;
      @media screen and (min-width: 575.95px) {
        margin-bottom: 20px;
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: bold;
        padding: 0;
        font-size: 42px;
        line-height: 55px;
      }
      .compare-option-heading {
        font-size: 24px;
        line-height: 36px;
        color: #25282a;
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: bold;
        @media screen and (min-width: 575.95px) {
          font-family: 'Poppins-Bold';
          font-style: normal;
          font-weight: bold;
          font-size: 42px;
          line-height: 55px;
        }
      }
    }
    .compare-loan-card {
      border-radius: 3px 3px 0px 0px;
      background: #ffffff;
      border: solid 1px transparent;
      margin-bottom: 20px;
      .compare-loan-header {
        display: flex;
        justify-content: space-between;
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        color: #66696c;
        padding: 10px;
        background: #ffffff;
        align-items: center;
        border-bottom: 1px solid #f1f2f2;
        @media screen and (min-width: 575.96px) {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          padding: 10px 20px 10px 20px;
        }
        .edit-btn-click {
          width: 74px;
          height: 32px;
          background: #00c1d5;
          border-radius: 2px;
          padding: 0;
          .editbtn-icon {
            width: 14px;
            height: 14px;
            margin-right: 10px;
          }
          .edit-btn {
            font-family: 'Poppins-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: $colorWhite;
          }
        }
      }
      .compare-loan-body {
        padding: 10px;
        @media screen and (min-width: 575.96px) {
          padding: 20px;
        }
        .compare-loansummary-section {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;
          @media screen and (min-width: 575.96px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 10px;
            grid-row-gap: 20px;
          }
          .compare-loan-section1 {
            display: flex;
            flex-direction: column;
            .loan-amount-val {
              font-family: 'Poppins-Medium';
              font-style: normal;
              font-weight: 600;

              font-size: 18px;
              line-height: 26px;
              color: #25282a;

              .borrow-amount {
                background: #dff8ec;
                border-radius: 3px;
              }
            }
            .compare-loanamt-subtitle {
              font-family: 'Poppins-Regular';
              font-style: normal;
              font-weight: normal;

              font-size: 12px;
              line-height: 16px;
              color: #66696c;
              @media screen and (min-width: 575.95px) {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }
        .compare-loansummary-edit-section {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 10px;
          @media screen and (min-width: 575.96px) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 10px;
            grid-row-gap: 20px;
          }
          .compare-loan-section1 {
            display: flex;
            flex-direction: column;
            width: auto;
            .loan-amount-val {
              font-family: 'Poppins-Medium';
              font-style: normal;
              font-weight: 600;

              font-size: 18px;
              line-height: 26px;
              color: #25282a;

              .borrow-amount {
                background: #dff8ec;
                border-radius: 3px;
              }
              &.updated-loan-val {
                padding: 0;
                margin: 0;
                .form-label {
                  display: none;
                }
                .input-group {
                  .form-control {
                    font-family: 'Poppins-Medium';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 26px;
                    color: #25282a;
                  }
                }
              }
            }
            .compare-loanamt-subtitle {
              font-family: 'Poppins-Regular';
              font-style: normal;
              font-weight: normal;

              color: #66696c;
              font-size: 12px;
              line-height: 16px;
              @media screen and (min-width: 575.95px) {
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }
        .update-align-btn {
          display: flex;
          flex-flow: column;
          margin-top: 10px;

          .lender-btn-canecel {
            background: #ffffff;
            border: 1px solid #dadde3;
            box-sizing: border-box;
            border-radius: 3px;
            height: 39px;
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $colorDarkGrey;
            width: 100%;
            margin-bottom: 10px;
            &.lender-btn-save {
              background: #330072;
              color: #ffff;
              margin-right: 0;
            }
            @media screen and (min-width: 575.95px) {
              margin-right: 10px;
              width: 50%;
            }
          }
          @media screen and (min-width: 575.95px) {
            flex-flow: row;
            justify-content: space-between;
          }
        }
      }
    }
    .update-loan-details {
      height: 30vh;
      padding-top: 30px;
      .loan-options-message {
        text-align: center;
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 27px;
        color: #191d23;
      }
    }
    .frequency-options {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      .compare-loan-freq {
        display: flex;
        @media screen and (min-width: 575.95px) {
          justify-content: space-between;
        }
        .btn-group-freg {
          border-radius: 3px;
          margin-right: 20px;
          .btn-freq {
            background-color: transparent;
            height: 30px;
            color: #66696c;
            padding: 0px 10px 0px 10px;
            line-height: 1.33;
            &.is-selected {
              border-radius: 3px;
              font-family: 'Poppins-Medium';
              background: #e4e8ea;
              font-weight: 600;
              color: $colorDarkGrey;
              //border: solid 1px $colorDarkGrey;
            }

            &:focus,
            &:active {
              outline: none;
              background-color: rgba(255, 255, 255, 0.25) !important;
            }
            .freq-name {
              font-family: 'Poppins-Regular';
              font-size: 14px;
            }
          }
        }
      }
      .dropdown-sort {
        display: none;
        @media screen and (min-width: 575.95px) {
          display: block;
          height: 30px;
        }
        .btn-primary {
          color: #fff;
          background: #e4e8ea;
          border-radius: 3px;
          height: 30px;
          padding: 4.5px 10px;
          box-shadow: none;
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #66696c;
        }
      }
    }
    .populate-lenders {
      .lender-card-columns {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 576.95px) {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 15px;
        }

        .lender-card {
          min-width: 100%;
          margin-right: 10px;
          padding: 20px;
          border-color: transparent;
          @media screen and (max-width: 576.95px) {
            padding: 10px;
            margin-bottom: 10px;
            margin-right: 0;
          }
          .stratton-img {
            height: 40px;
            margin-bottom: 10px;
          }
          .lender-body {
            padding: 0;
            .repayment-section {
              margin-bottom: 10px;
              display: flex;
              margin-bottom: 10px;
              flex-flow: column;
              align-items: baseline;
              @media screen and (min-width: 700px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                grid-row-gap: 10px;
              }
              @media screen and (max-width: 575.95px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 15px;
                gap: 15px;
                grid-row-gap: 10px;
                margin: 10px;
              }
              .lender-data {
                display: flex;
                flex-direction: column;
                .lender-data-val {
                  font-family: 'Poppins-Bold';
                  font-style: normal;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 18px;
                  color: #25282a;
                }
                .lender-data-freq {
                  font-family: 'Poppins-Regular';
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 18px;
                  color: #66696c;
                }
              }

              .interest-rate {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .interest-rate-val {
                  font-family: 'Poppins-Bold';
                  font-style: normal;
                  font-weight: bold;
                  font-size: 22px;
                  line-height: 33px;
                  color: #25282a;
                }
                .interest-rate-subtitle {
                  font-family: 'Poppins-Regular';
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 21px;
                  color: #66696c;
                }
              }
            }
            .loan-amount {
              display: flex;
              flex-flow: row;
              font-family: 'Poppins-Bold';
              font-style: normal;
              font-weight: bold;
              font-size: 22px;
              line-height: 33px;
              color: #25282a;
            }
            .lender-residual {
              font-family: 'Poppins-Regular';
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
              color: #66696c;
              margin-bottom: 20px;
            }
            .lender-options-marker {
              margin: 0;
              margin-left: -20px;
              margin-right: -20px;
              border-color: #f1f2f2;
              margin-bottom: 0;
            }
            .show-more-expand {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .expand-btn {
                padding: 0;
                font-family: 'Poppins-Regular';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $colorDarkGrey;
              }
            }
            .feature-unordered-list {
              padding: 0;
              display: flex;
              flex-direction: row;
              align-items: center;
              .sucess-icon {
                height: 18px;
                width: 18px;
                margin: 10px;
                margin-left: 0;
              }
              .feature-class {
                font-family: 'Poppins-Regular';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #25282a;
              }
            }
            .lender-btn-continue {
              width: 100%;
              padding: 10px;
              font-family: 'Poppins-Medium';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              margin-top: 10px;
              margin-bottom: 0px;
              &:not(:disabled):hover,
              &:not(:disabled):focus,
              &:not(:disabled):active {
                background-color: $colorPrimaryBlue !important;
                opacity: 0.6;
              }
              @media screen and (min-width: 576.95) {
                margin-top: 20px;
                margin-bottom: 10px;
              }
            }
            .quote-det {
              font-family: 'Poppins-Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }
            .quote-det-multiple {
              margin: 10px 0px 10px 0px;
            }
            .repayments {
              display: flex;
              flex-flow: row;
              justify-content: space-between;
              margin: -20px -20px 10px -20px;
              font-family: 'Poppins-Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 18px;
              text-align: center;
              color: $colorDarkGrey;
              padding: 10px;
              background-color: transparent;
              @media screen and (max-width: 575.95px) {
                margin: 0;
              }
              &.best-repayments {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                font-family: 'Poppins-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: $colorWhite;
                margin: -20px -20px 10px -20px;
                padding: 10px;
                background-color: #00c1d5;

                @media screen and (max-width: 575.95px) {
                  margin: 0;
                }
              }
            }
            .display-logo {
              text-align: center;
            }
          }
        }
      }
      .lender-card-columns::-webkit-scrollbar-track {
        border: 1px solid #f1f2f2;
        padding: 0.5px 0;
        background-color: #ffff;
        //height: 2px;
        padding: 2px 0;
        background-color: #ffff;
      }

      .lender-card-columns::-webkit-scrollbar {
        width: auto;
        height: 3px;
      }

      .lender-card-columns::-webkit-scrollbar-thumb {
        border-radius: 2px;
        height: 2px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f1f2f2;
        border: 1px solid #f1f2f2;
        border-radius: 10px;
      }
      .load-more-align {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
        @media screen and (min-width: 576.95px) {
          margin-top: 20px;
        }
        .btn-load-more {
          height: 32px;
          background: #e4e8ea;
          border-radius: 3px;
          text-align: justify;
          padding: 0;
          padding-left: 10px;
          padding-right: 10px;
          .load-more {
            height: 14px;
            width: 16px;
            margin-right: 10px;
          }
          .load-more-name {
            font-family: 'Poppins-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
    .no-obligation {
      font-family: 'Poppins-Semibold';
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 54px;

      text-align: center;
    }
    .oblig-subtitle {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 20px;
      color: rgba(0, 0, 0, 0.6);
    }
    .disclaimer-footer {
      margin: 25px 0px 25px 0px;
      .disclaimer {
        font-family: 'Poppins-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: $colorDarkGrey;
      }
      .disclaim-repay {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
.card-columns {
  .lender-card {
    padding: 20px;
    .oblig-align-row {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      .oblig-img {
        height: 20px;
        width: 20px;
        margin-right: 15px;
      }
      .oblig-title {
        font-family: 'Poppins-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: $colorDarkGrey;
        margin-bottom: 20px;
      }
    }
    .lender-body {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.87);
      padding: 0;
    }

    @media screen and (min-width: 576px) {
      min-height: 280px;
    }
  }
}
