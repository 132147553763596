@import '../../../../styles/variable.scss';
@import '../../../../styles/global.scss';

.finances-details-container {
  margin-bottom: 10px;
  margin-top: 2vh;
  border: none;
  @media screen and (min-width: 600px) {
    margin-bottom: 30px;
    margin-top: 30px !important;
  }
  .finances-details-header-container {
    // border: 1px solid #dee2e6 !important;
    background-color: $colorWhite !important;

    .finances-details-header {
      margin-bottom: 0;
      font-family: 'Poppins-SemiBold';
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
      padding: 0 24px;
      @media screen and (min-width: 600px) {
        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 27px;
      }
    }

    .finances-details-description {
      color: $colorSubtitle;
      font-family: 'Poppins-Regular';
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
      padding: 0 24px 24px;
      @media screen and (min-width: 600px) {
        font-family: 'Poppins-Regular';
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

    @media screen and (min-width: 600px) {
      padding: 24px 0 0 !important;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .finances-details-track-verify {
    //height: 60px;
    border-radius: none;
    border: solid 1px rgba(5, 189, 127, 0.1);
    background-color: #e7f9f3;
    .finances-track-verify-body {
      .alert-info-section {
        display: flex;
        flex-direction: row;
        .secure-icon {
          height: 5%;
        }
        .finances-track-verify-content {
          font-family: 'Poppins-SemiBold';
          color: $colorDarkGrey;
          font-size: 15px;
          .finances-track-verify-detail {
            font-family: 'Poppins-Regular';
          }
        }
        .finances-track-verify-button {
          width: 88px;
          height: 3rem;
          position: relative;
          float: right;
          border-radius: 4px;
          background-color: $colorGreenSuccess;
        }
      }
    }
  }
  .finances-body-container {
    .border-align-warning {
      margin-top: 0;
      margin-bottom: 0;
    }
    .expense-warning {
      display: flex;
      flex-direction: row;
      margin: 12px;
      border-radius: 3px;
      background-color: $colorStatus;
      color: $colorDarkGrey;
      @media screen and (min-width: 768px) {
        margin: 12px 24px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
      .alert-icon {
        margin-right: 12px;
        margin-left: 14px;
      }
      .warning-message {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        color: $colorDarkGrey;
        font-size: 12px;
        line-height: 18px;
        @media screen and (min-width: 576px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .finances-details-button {
      position: relative;
      float: left;
      margin: 10px 12px;
      height: 32px;
      background-color: #eef2f5;
      border-radius: 3px;
      padding: 0;
      @media screen and (min-width: 600px) {
        margin: 10px 24px;
      }
      .btn-income {
        margin: 10px 0px;
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        padding: 7px 14px;
        color: $colorDarkGrey;
      }
    }
    .netincome {
      height: 155px;
      border-radius: 1px;
      border: solid 1px #e5e7e7;
      background-color: #ffffff;
      border-style: dashed;
      margin: 24px;
      .netincome-label {
        //width: 298px;
        //height: 20px;
        font-family: 'Poppins-Regular';
        font-size: 14px;

        line-height: 1.43;
        color: #66686a;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        > span {
          margin: 8px 0;
        }

        @media screen and (min-width: 768px) {
          flex-flow: row;

          > span {
            margin: 0 16px;
          }
        }
        .btn-add-income {
          border-radius: 3px;
          background-color: #eef2f5;
          font-size: 17px;
          color: #25282a;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          padding: 10px;
          font-family: 'Poppins-Medium';
        }
      }
    }

    .table-responsive {
      overflow: hidden;
    }

    .finances-body-table {
      padding: 10px 0px;
      margin: 0px 1px;
      .finances-card-table-body {
        .finances-card-table-row {
          padding: 10px 0px;
          margin: 0px 1px;
          font-family: 'Poppins-Regular';
          font-size: 14px;
          color: $colorDarkGrey;
          vertical-align: none;
          border-bottom: 1px solid #dee2e6;
          border-top: none;
          &.liability-card-table-row {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @media screen and (min-width: 768px) {
              display: contents;
            }
          }
          .finances-card-table-total {
            padding: 12px;
            vertical-align: none;
            border-top: none;
            font-family: 'Poppins-Bold';
            font-size: 15px;
            font-weight: 500;
            color: $colorDarkGrey;
            word-break: break-all;
            line-height: 22px;
            &.label-font {
              font-family: 'Poppins-Medium';
              font-weight: normal;
              font-size: 15px;
              line-height: 22px;
            }
            @media screen and (min-width: 600px) {
              padding: 10px 24px;
              margin: 12px 0px;
            }
            &.hidden {
              @media screen and (max-width: 767.95px) {
                display: none;
              }
            }
            &.liability-total-align {
              text-align: justify;
              padding-top: 0;
              @media screen and (min-width: 756px) {
                text-align: end;
                white-space: nowrap;
                padding-top: 10px;
              }
            }
          }
          &.finances-total-liab-repayment {
            font-family: 'Poppins-Medium';
            font-size: 14px;
            text-align: start;
            white-space: nowrap;
            background-color: #eef2f5;
          }
        }

        .finances-card-table-detail {
          padding: 12px;
          vertical-align: middle;
          border-bottom: 1px solid #dee2e6;
          border-top: none;
          font-family: 'Poppins-Medium';
          font-size: 14px;
          width: 15%;
          text-align: start;
          white-space: nowrap;
          @media screen and (min-width: 600px) {
            padding: 10px 24px;
            margin: 12px 0px;
          }
          @media screen and (min-width: 767.95px) {
            text-align: end;
          }
          &.btn-delete {
            padding-left: 0;
          }
          &:nth-child(1) {
            width: 30%;
          }
          &:nth-child(2) {
            width: 20%;
          }

          &:not(:first-child) {
            @media screen and (max-width: 767.95px) {
              display: none;
            }
          }

          &.hide-col {
            display: none;
          }

          &.delete-confirmation-col {
            width: 60% !important;
          }

          .delete-confirmation,
          &.delete-confirmation {
            .confirm-msg,
            .btn-confirm {
              margin-right: 8px;
            }

            .btn-confirm,
            .btn-cancel {
              font-size: 13px;
              font-family: 'Poppins-SemiBold';
            }

            .btn-cancel {
              background-color: $colorSecondaryGrey;

              :hover,
              :focus,
              :active {
                background-color: $colorButtonDisable;
              }
            }

            .btn-confirm {
              background-color: #f23457;
              color: $colorWhite;

              :hover,
              :focus,
              :active {
                background-color: #f2345799;
              }
            }
          }

          &.delete-confirmation {
            text-align: right;
          }

          .delete-confirmation {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }

          .finances-card-table-edit {
            font-family: 'Poppins-Medium';
            color: #66696c;
            font-size: 13px;
          }
          .finances-card-table-delete {
            color: #f23457;
            font-size: 13px;
          }

          .income-details-section-wrapper {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .income-details-content {
              margin-right: 16px;
            }

            .income-type-amount {
              padding-top: 6px;
              font-family: 'Poppins-SemiBold';
            }
          }
          &.total-liab-repayments {
            text-align: start;
          }
        }
      }
    }
  }
}

.finances-modal-wrapper {
  .modal-dialog {
    max-width: 400px;

    .finances-header-container {
      padding: 24px 20px;
      .finances-header-section {
        height: 24px;
        font-family: 'Poppins-SemiBold';
        font-size: 17px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.41;
        letter-spacing: normal;
        color: #212b36;
      }
    }
    .show-grid {
      .income-column {
        padding: 24px 20px;
        .finances-form-label {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
        }
        .frequency-select-container {
          display: flex;
          flex-flow: column;
          @media screen and (min-width: 350px) {
            display: flex;
            flex-wrap: wrap;
            flex-flow: row;
          }
          .select-button-field {
            margin-bottom: 6px;
            flex: 1;

            .frequency-select-buttons {
              font-size: 15px !important;
              line-height: 22px;
              color: #a4a7aa !important;
              &.is-selected {
                color: $colorDarkGrey !important;
              }
              @include select-buttons;
              white-space: normal;
              width: 100%;
              height: 48px;
            }

            /*      .oi-circle-check {
            top: -44px;
            left: 96px;
          } */

            &:not(:last-child) {
              margin-right: 0;
              margin-bottom: 10px;

              @media screen and (min-width: 350px) {
                margin-right: 20px;
                margin-bottom: 0;
              }
            }
          }
        }

        .employment-select-container {
          display: flex;
          flex-wrap: wrap;

          .select-button-field {
            margin-bottom: 8px;

            .employment-select-buttons {
              @include select-buttons;
              white-space: normal;
            }

            /*   .oi-circle-check {
            top: -114px;
            left: 96px;
          } */

            &:not(:last-child) {
              margin-right: 0.5rem;
            }
          }
        }

        .footer-container {
          font-family: 'Poppins-Medium';
          float: right;
          border-top: none;
          padding-bottom: 0;
          padding-top: 20px;
          .footer-btn {
            //width: fit-content;
            margin: 0;
            height: 48px;
            width: 110px;
            border-radius: 3px;
            &:disabled {
              background-color: $colorInactive;
              border-color: $colorInactive;
            }
            &:not(:disabled),
            &:not(:disabled):hover,
            &:not(:disabled):active {
              background-color: $colorPrimaryBlue !important;
              color: $colorWhite !important;
            }
          }
          .footer-cancel {
            //width: fit-content;
            margin: 0;
            margin-right: 20px;
            height: 48px;
            width: 110px;
            border-radius: 3px;
            border: solid 1px #bebebf;
            background-color: #ffff;
            color: $colorDarkGrey;
          }
        }
        .add-income-col {
          .add-income-body-h1 {
            font-family: 'Poppins-SemiBold';
            font-size: 28px;
            font-weight: 600;
            color: $colorDarkGrey;
          }
        }
        .horizantal-line {
          margin-left: -20px;
          margin-right: -20px;
          margin-bottom: 0;
        }
      }
    }
  }
}
