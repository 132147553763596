.gridItemContainer {
  display: flex;
  color: #66696c;

  .label {
    margin-left: 0.5rem;
  }
  .icon-container {
    width: 1.3em;
    height: 1.3rem;
    border-radius: 50%;
    font-size: 0.8rem;
    color: black;
    text-align: center;
    background: white;
    .icon {
      font-size: 1rem;
    }
  }
}
