@import '../../../../styles/variable.scss';
$border: 1px solid $colorLightGrey;

.finalise-car-details {
  padding: 30px;
  border-top: $border;
  border-bottom: $border;
  text-align: left;
  width: 100%;
  h6 {
    color: $colorDarkGrey;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
  }
  p {
    color: $colorSubheadingDesc;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.headingText {
  font-size: '32px';
  line-height: '48px';
  padding: 30px;
  @media (max-width: 768px) {
    display: none;
  }
}
.finalise-car-page-description {
  padding: 24px;
  border-bottom: $border;
  @media (max-width: 768px) {
    padding: 16px;
  }
  p:nth-child(1) {
    color: $colorDarkGrey;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  p:nth-child(2) {
    color: $colorSubtitle;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.finalise-car-finalisation-details {
  padding: 24px;
  border-bottom: 1px solid $border;
  @media (max-width: 768px) {
    padding: 16px;
  }
  h6 {
    color: $colorSubtitle;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  p {
    color: #5b5b5b;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 768px) {
      font-style: italic;
    }
  }
}
.rego-manual {
  display: flex;
  gap: 16px;
  align-items: baseline;
  justify-content: flex-start;
  padding-left: 24px;
  padding-bottom: 20px !important;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  p {
    color: $colorBlack;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  button {
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    border-radius: 3px;
    background: $colorBorderColor;
  }
}
.question {
  padding: 24px;
  @media (max-width: 768px) {
    padding: 16px;
  }
  p {
    color: $colorDarkGrey;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.next-action-button {
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  margin-top: 20px;
  border-top: $border;
}
.car-applicants-main-rego {
  display: flex;
  justify-content: center !important;
  text-align: left;
  align-items: center;
  flex-wrap: wrap;

  .rego-number {
    padding: 0;
    flex-basis: 100%;

    @media (min-width: 576px) {
      padding-right: 16px;
      flex-basis: 50%;
    }

    @media (min-width: 768px) {
      flex-basis: 50%;
      padding-right: 30px;
      flex: 2;
    }
  }

  .rego-state {
    padding: 0;
    flex-basis: 100%;

    @media (min-width: 576px) {
      flex-basis: 50%;
    }

    @media (min-width: 768px) {
      flex-basis: 25%;
      padding-right: 30px;
      flex: 1;
    }
  }

  .search-action {
    flex-basis: 100%;
    padding-left: 0;
    height: 50%;
    margin-bottom: 28px;
    min-width: 130px;

    @media (min-width: 768px) {
      margin-top: 18px;
      margin-bottom: 0;
      flex-basis: 15%;
      flex: 1;
    }
    .btn-primary {
      background-color: $colorPrimaryBlue;
      width: 100%;
    }
  }
}
.car-applicants-main-prepopulate {
  flex-basis: 100%;
  max-width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 0 !important;

  &.car-details-prepopulate {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin-top: 0 !important;
  }

  .car-details-header {
    font-family: 'Poppins-Regular';
    font-size: 18px;
    font-weight: 300;
    color: $colorText;
  }
}
.error-header-message {
  display: flex;
  justify-content: center;
}
.car-full-screen-finalise-car {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  // font-family: 'Poppins-Semibold';
  margin-bottom: 12px;
  @media screen and (min-width: 576.78px) {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
  }
}
.pa-left-24 {
  padding-left: 24px;
  @media (max-width: 768px) {
    padding-left: 16px;
  }
}
.pa-right-24 {
  padding-right: 24px;
  @media (max-width: 768px) {
    padding-right: 16px;
  }
}
.pa-rl-24 {
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.common-pa-mg {
  margin-bottom: 20px !important;
  padding-left: 9px;
  @media (max-width: 768px) {
    padding-left: 1px;
    margin-bottom: 8px !important;
  }
}
.border-tp {
  border-top: $border;
}
.border-btm {
  border-bottom: $border;
}
