@import '../../../styles/variable.scss';
.contact-details {
  max-width: 100%;
  margin: 20px;
  @media screen and (min-width: 768px) {
    max-width: 80%;
    margin: auto;
    margin-top: 60px;
  }

  .contact-info {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    /* identical to box height */
    color: $colorDarkGrey;
    text-align: center;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;

    @media screen and (min-width: 768px) {
      font-weight: 600;
      font-size: 22px;
      line-height: 33px;
    }
  }
  .contact-subheading {
    .contact-strattoninfo {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: normal;
      text-align: center;
      color: $colorSubtitle;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;

      @media screen and (min-width: 768px) {
        font-size: 17px;
        line-height: 25px;
      }
    }
    margin-bottom: 10px;
    @media screen and (min-width: 768px) {
      margin-bottom: 30px;
    }
  }

  .contact-card {
    background: $colorWhite;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 24px;
    }
    .contact-body {
      padding: 0;

      .contact-detail-info {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 768px) {
          display: flex;
          flex-direction: row;
          margin-bottom: 14px;
        }
        .title {
          flex-basis: 100%;

          @media screen and (min-width: 768px) {
            max-width: 50%;
          }
        }
        .first-name {
          flex-basis: 50%;
          padding: 0;
          margin-right: 30px;
        }
        .last-name {
          flex-basis: 50%;
          padding: 0;
        }
        .email-address {
          flex-basis: 100%;
          padding: 0;
          margin-right: 30px;
        }

        .phone-number {
          flex-basis: 50%;
          padding: 0;
          margin-right: 30px;
        }
        .post-code {
          flex-basis: 80%;
          padding: 0;
          @media screen and (min-width: 768px) {
            flex-basis: 50%;
            max-width: 50%;
            margin-right: 30px;
          }
        }
        .promo-code {
          flex-basis: 80%;
          padding: 0;
          @media screen and (min-width: 768px) {
            flex-basis: 50%;
            max-width: 50%;
            margin-right: 30px;
          }
        }
      }
      .email-notify {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        text-align: center;
        color: $colorSubtitle;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 20px;
        padding: 10px;
        text-align: inherit;
        padding-top: 0;
      }
    }
  }
  .contact-details-privacy {
    margin-top: 30px;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: $colorSubheadingDesc;
  }
  .account-exist {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.licencecardlabel {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
.license-card-number {
  .form-label {
    display: none !important;
  }
}
.identity-form-section {
  color: $colorDarkGrey;
  font-family: 'Poppins-Regular';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  @media screen and (min-width: 600px) {
    font-family: 'Poppins-Regular';
    font-size: 17px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .personal-body-section3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
  }
}
.no-license-check {
  padding-left: 0;
}
.privacy_wrapper {
  border: 1px solid #e4e8ea;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
}
.consent_wrapper {
  border: 1px solid #e4e8ea;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 20px 10px 20px;
  margin-top: 20px;
}
