@import '../../../styles/variable.scss';

.request-email-details {
  height: 93vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .request-email-message {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
    color: $colorBlack;
  }

  .root {
    margin-top: 10px !important;
  }
}
