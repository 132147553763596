@import '../../../styles/variable.scss';
.stratton-footer-message {
  width: 100%;
  &.mobile-screen {
    display: block;
    position: initial;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .copyright {
    color: #929394;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    text-align: center;
  }

  .footer-copyright {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin: 16px 46px 17px 46px;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
      justify-content: flex-start;
    }

    @media screen and (min-width: 980px) {
      justify-content: center;
    }

    .copyright-licence {
      font-family: 'Poppins-Regular';
      font-size: 12px;
      color: #66696c;
    }

    .copyright {
      //margin: 16px 46px 17px 0px;
      .copyright-privacy {
        color: $colorDarkGrey;
        font-family: 'Poppins-Regular';
        font-size: 12px;
      }
    }
  }
}
