@import '../../../styles/variable.scss';

.button-field-container {
  font-family: 'Poppins-Regular';
  font-size: 13px;
  letter-spacing: 0;
  margin-bottom: 10px;
  @media screen and (min-width: 600px) {
    font-family: 'Poppins-Regular';
    font-size: 17px;
  }
  .button-field-group {
    padding-bottom: 0 !important;
    .button-field {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 2px;
      border: solid 1px #bebebf;
      background-color: $colorWhite;
      font-family: 'Poppins-Medium';
      font-size: 14px;
      color: $colorDarkGrey;

      &.is-selected {
        box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.09);
        border: solid 3px #00aabb;
        background-color: rgba(0, 193, 213, 0.1);
        color: $colorDarkGrey;
        font-family: 'Poppins-Medium';
      }
    }
  }
}
