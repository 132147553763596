@import '../../../styles/variable.scss';

.personal-details-container {
  margin-bottom: 10px;
  margin-top: 2vh;
  margin-left: 12px;
  margin-right: 12px;
  @media screen and (min-width: 600px) {
    margin-bottom: 30px;
    margin-top: 30px !important;
  }

  .personal-details-header-container {
    // border: 1px solid #dee2e6 !important;
    background-color: $colorWhite !important;

    .personal-details-header {
      font-family: 'Poppins-Medium';
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 0;
      font-weight: 500;
      @media screen and (min-width: 597.95px) {
        font-family: 'Poppins-SemiBold';
        letter-spacing: 0;
        margin-bottom: 0;
        font-size: 15px;
      }
    }

    .personal-details-description {
      color: $colorDisabledGrey;
      font-family: 'Poppins-Regular';
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;

      @media screen and (min-width: 597.95px) {
        color: $colorDisabledGrey;
        font-size: 14px;
        line-height: 21x;
      }
    }
  }

  .container-footer {
    margin-left: 0px;
    margin-right: 0px;
    &.employment-details-footer {
      margin-left: -14px;
      margin-right: -14px;
      @media screen and (min-width: 576px) {
        margin-left: -36px;
        margin-right: -36px;
      }
    }
  }

  .applicant-body-container {
    padding: 12px;
    @media screen and (min-width: 600px) {
      padding: 0;
    }
    .input-append {
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      // background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      margin-right: 25px;
    }
    .message-heading {
      font-size: 12px;
      font-family: 'Poppins-Regular';
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media screen and (min-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
      }
      /* Add a green text color and a checkmark when the requirements are right */
      .message-title {
        margin-bottom: 0;
        padding: 10px;
        color: $colorDarkGrey;
      }
      .valid-password {
        color: green;
      }

      .valid-password:before {
        position: relative;
        content: '✔';
      }

      /* Add a red text color and an "x" when the requirements are wrong */
      .invalid-password {
        color: $colorDarkGrey;
      }

      .invalid-password:before {
        position: relative;
        content: '✖';
      }
    }
    .error-alert {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      background-color: #fceaee;
      .risk-img {
        background-color: #dc2849;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        padding: 5px;
      }
      .error-message {
        font-family: 'Poppins-Regular';
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: normal;
        color: $colorDarkGrey;
        padding: 0 30px 0 30px;
      }
      .redirect-login {
        background-color: $colorPrimaryBlue;
        position: absolute;
        right: 0;
        border-radius: 4px;
        height: 2rem;
      }
    }
    .applicant-body-section1 {
      padding: 0 12px 0 12px !important;
      @media screen and (min-width: 600px) {
        padding: 24px !important;
        margin-left: 0;
        margin-right: 0;
      }

      .applicant-body-section2 {
        padding: 0 10px 0 10px;
        .first-name,
        .email-address,
        .last-name,
        .post-code {
          margin-bottom: 10px;
          padding-left: 0;
          padding-right: 0;
          flex-basis: 100%;
        }

        @media screen and (min-width: 600px) {
          margin-bottom: 0 !important;
          .email-address {
            padding-right: 1rem;
          }
          .post-code {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
          }
        }

        @media screen and (min-width: 767.95px) {
          margin-bottom: 0 !important;
          .first-name {
            // padding-left: 1rem;
            padding-right: 1rem;
            // margin-left: 0.5rem;
            margin-bottom: 30px !important;
            flex-basis: 50%;
          }
          .last-name {
            //padding-right: 1rem;
            margin-bottom: 30px !important;
            flex-basis: 50%;
          }
          .email-address {
            padding-right: 1rem;
            flex-basis: 35%;
          }
          .phone-number {
            flex-basis: 50%;
          }
          .post-code {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
            flex-basis: 50%;
          }
        }
        .applicant-body-section3 {
          color: #66696c;
          font-family: 'Poppins-Regular';
          font-size: 12px;
          letter-spacing: 0;
          line-height: 20px;
          .btn-privacy-link {
            color: $colorDarkGrey;
            text-decoration: underline;
          }
        }
        .applicant-form-section {
          color: $colorDarkGrey;
          font-family: 'Poppins-Regular';
          font-size: 13px;
          letter-spacing: 0;
          line-height: 20px;
          @media screen and (min-width: 600px) {
            font-family: 'Poppins-Regular';
            color: $colorDarkGrey;
            font-size: 17px;
            letter-spacing: 0;
            line-height: 26px;
          }
        }
        .email-password {
          display: flex;
          flex-direction: column;
          &.verify-password {
            display: flex;
            flex-direction: column;

            margin-bottom: 10px;
            @media screen and (min-width: 600px) {
              flex-direction: row;
              align-items: center;
              margin-bottom: 30px;
            }

            .send-code {
              margin-left: 0px;
              margin-top: 10px;
              @media screen and (min-width: 600px) {
                margin-left: 10px;
                margin-top: 20px;
              }
            }
          }
          .login-input-group {
            .input-label-font {
              font-family: 'Poppins-Medium';
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              color: #25282a;
            }
            .email-input-group-append {
              .email-icon-input-append {
                border: 2px solid #e4e8ea;
                border-right: none;
                padding: 10px;
                &.invalid {
                  border-color: #dc3545;
                }
              }
              .email-border {
                border: 2px solid #e4e8ea;
                border-left: none;
                border-right: none;
                border-radius: 0;
                &.invalid {
                  border-color: #dc3545;
                }
                &.mobile-border {
                  border: 2px solid #e4e8ea;
                  border-left: none;
                }
              }

              .password-border {
                border: 2px solid #e4e8ea;
                border-radius: 0;
                border-right: none;
              }

              .input-append {
                border: 2px solid #e4e8ea;
                border-radius: 0;
                border-left: none;
                margin-right: 0;
                &.invalid {
                  border-color: #dc3545;
                }
              }
              &.disabled {
                background-color: $colorDisabled;
              }
            }
          }
          .error-message {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            .invalid-feedback {
              width: auto;
              margin-bottom: 10px !important;
            }
          }
        }
        .title {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 22px;
          color: #10b2c4;
          margin-bottom: 20px;
          text-transform: uppercase;
        }
        .subtitle {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 20px;
        }
        .coapp-passportdetails {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @media screen and (min-width: 600px) {
            flex-direction: row;
          }
        }
      }
    }
  }
  .verifyyourself-body-container {
    background: none;
    //margin: 23px 90px 50px 36px;
    margin: 0px !important;
    @media screen and (min-width: 568px) {
      background: url('../../../assets/image/mobile-verification.svg') center 50% / auto no-repeat;
      background-position-x: right;
      padding: 0;
    }

    .details-container-wrapper {
      margin-bottom: 36px;
      .custom-badge {
        text-transform: uppercase;
        padding: 8px 16px;
        border-radius: 100px;

        &.pending {
          background-color: rgba(255, 193, 7, 0.7);
        }

        &.verified {
          background-color: rgba(115, 246, 202, 0.7);
        }
      }
      .details-container-verify {
        .verifyyourself-content1 {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: $colorAcknowledge;
          .mobile-number {
            color: #28c9da;
          }
          @media screen and (min-width: 568px) {
            font-size: 17px;
          }
        }
      }
    }

    .verifyyourself-content2 {
      font-family: 'Poppins-Medium';
      font-size: 16px;
      color: #929394;
      // text-align: center;
    }
    .verifyyourself-content3 {
      font-family: 'Poppins-Regular';
      font-size: 14px;
      color: $colorDarkGrey;
      font-size: 17px;
      letter-spacing: 0;
      line-height: 26px;
      margin-bottom: 15px;
      // text-align: center;
    }
    .verifyyourself-otp {
      display: flex;
      margin-bottom: 40px;
      // justify-content: center;
      .otp-input-field {
        width: 1.85rem !important;
        height: 1.85rem;
        font-size: 1.25rem;
        margin: 0 4px 0 0;
        border-radius: 3px;
        border: 2px solid #28c9da;
        box-sizing: 'border-box';

        @media screen and (min-width: 600px) {
          margin-right: 1rem;
        }

        @media screen and (min-width: 768px) {
          width: 2.5rem !important;
          height: 2.5rem;
          font-size: 1.75rem;
        }

        @media screen and (min-width: 992px) {
          width: 48px !important;
          height: 48px;
          font-size: 2rem;
        }
      }
    }
    .otp-info-container {
      //height: 60px;
      border-radius: 3px;
      // background-color: #f4f5f5;
      display: flex;
      margin-bottom: 40px;
      // align-items: center;
      // justify-content: center;

      .verifyyourself-content6 {
        color: $colorDarkGrey;
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #66696c;

        .btn {
          padding: 0 6px 0 0;
        }

        .verifyyourself-content7 {
          font-family: 'Poppins-Medium';
          font-size: 16px;
          color: $colorDarkGrey;
        }

        .verifyyourself-content8 {
          font-family: 'Poppins-SemiBold';
          font-size: 16px;
          color: $colorDarkGrey;
          padding: 5px;
          background-color: #e0f8fa;
        }
      }
    }
    .otp-verify-container {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 599.95px) {
        align-items: center;
      }

      .otp-progress {
        color: $colorGreenSuccess;
      }

      .verified-icon-container {
        display: flex;
        // justify-content: center;
        align-items: center;
        margin-bottom: 8px;

        .success-icon {
          border-radius: 50%;
          width: 60px;
          height: 60px;
          border: solid 4px $colorGreenSuccess;
          background-color: #ffffff;
          background: rgba(0, 215, 143, 0.1) url('../../../assets/image/check.svg') center no-repeat;
        }
      }

      .verified {
        font-size: 20px;
        // text-align: center;
        color: #212b36;
        font-family: 'Poppins-Regular';
      }
    }
  }

  .personal-body-container {
    padding: 12px;
    @media screen and (min-width: 600px) {
      padding: 0;
    }
    .personal-body-section1,
    &.personal-body-section1 {
      padding: 0 10px 0 10px;
      @media screen and (min-width: 600px) {
        padding: 24px 36px 24px 36px !important;
        margin-left: 0;
        margin-right: 0;
      }
      .personal-body-section2,
      &.personal-body-section2 {
        padding: 0 10px 0 10px;
        .no-aus-address {
          .checkbox-container {
            width: fit-content;
          }
        }

        .personal-body-section3 {
          margin-bottom: 20px;
          @media screen and (max-width: 767.95px) {
            .license-number,
            .no-license-check {
              margin-bottom: 0;
            }

            .license-number {
              .form-group {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
          .checkbox-container {
            display: block;
            position: relative;
            padding-left: 36px;
            padding-top: 8px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            font-family: 'Poppins-Regular';
            font-size: 14px;
            color: $colorDarkGrey;

            @media screen and (min-width: 768px) {
              padding-top: 38px;
              padding-left: 44px;
            }
          }
          .checkbox-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            top: 6px;
            left: -12px;
            width: 24px;
            height: 24px;
            border-radius: 3px;
            box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
            border: solid 1px #bebebf;
            background-image: linear-gradient(to bottom, #fff, #f9fafb);

            @media screen and (min-width: 768px) {
              top: 35px;
              left: -8px;
            }
          }
          .checkbox-container:hover input ~ .checkmark {
            opacity: 0.8;
          }

          /* When the checkbox is checked, add a blue background */
          .checkbox-container input:checked ~ .checkmark {
            border-color: #00aabb;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: '';
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .checkbox-container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .checkbox-container .checkmark:after {
            left: 7px;
            top: 1px;
            width: 8px;
            height: 14px;
            border: solid #00aabb;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
.header-container {
  .header-section {
    width: 157px;
    height: 24px;
    font-family: 'Poppins-SemiBold';
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    color: #212b36;
  }
  .btn-close {
    margin-top: -1rem;
    margin-bottom: -1rem;
    margin-left: auto;
    margin-right: 0;
  }
  .header-description {
    width: 377px;
    height: 20px;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #637381;
  }
}
.footer-container {
  display: flex;
  direction: row;
  justify-content: flex-start;
  flex-direction: row;
  font-style: normal;
  font-size: 14px;
  .footer-description {
    font-family: 'Poppins-Regular';
    color: #637381;
    font-size: 14px;
  }
  .footer-desc2 {
    font-family: 'Poppins-SemiBold';
    font-size: 14px;
    font-weight: 600;
    color: #00aabb;
  }
}
.content-container {
  display: flex;
  flex-direction: column;

  .content-section1 {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    text-align: center;
  }
  .content-section2 {
    font-family: 'Poppins-Regular';
    color: #637381;
    font-size: 14px;
  }
  .content-section3 {
    font-family: 'Poppins-SemiBold';
    font-size: 14px;
    font-weight: 600;
    color: #00aabb;
  }
}

.container-section {
  .tab-container {
    .container {
      .privacy-header-container {
        border-radius: 3px;
        box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15), 0 0 0 1px rgba(63, 63, 68, 0.05);
        background-color: #fff;
        .privacy-header {
          font-family: 'Poppins-SemiBold';
          font-size: 17px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.41;
          letter-spacing: normal;
          color: #212b36;
        }
        .privacy-description {
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.42;
          letter-spacing: normal;
          color: #66686a;
        }
      }
      .checkbox-container {
        display: block;
        position: relative;
        padding-left: 1px;
        padding-top: 32px;
        margin-bottom: 12px;
        cursor: pointer;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-family: 'Poppins-Regular';
        font-size: 13px;
        line-height: 20px;
        color: $colorDarkGrey;
        @media screen and (min-width: 600px) {
          font-size: 22px;
        }
      }
      .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 28px;
        left: -44px;
        width: 24px;
        height: 24px;
        border-radius: 3px;
        box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
        border: solid 1px #bebebf;
        background-image: linear-gradient(to bottom, #fff, #f9fafb);
      }
      .checkbox-container:hover input ~ .checkmark {
        opacity: 0.8;
      }

      /* When the checkbox is checked, add a blue background */
      .checkbox-container input:checked ~ .checkmark {
        border-color: #00aabb;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .checkbox-container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .checkbox-container .checkmark:after {
        left: 7px;
        top: 1px;
        width: 8px;
        height: 14px;
        border: solid #00aabb;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}
.identity-form-section {
  color: #25282a;
  font-family: 'Poppins-Regular';
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  @media screen and (min-width: 600px) {
    font-family: 'Poppins-Regular';
    font-size: 17px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .personal-body-section3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    &.address-living-details {
      margin-bottom: 0;
    }
  }

  .living-details-manual {
    margin-bottom: 10px;
  }
  .abn-number {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    .abn-serach-btn {
      height: 48px;
      width: 100%;
      border-radius: 3px;
      font-family: 'Poppins-Medium';
      border-color: transparent;
      &.disabled {
        background-color: $colorButtonDisable !important;
      }
      &:not(:disabled):hover {
        background-color: $colorhover !important;
      }

      &:not(:disabled):active,
      &:not(:disabled):focus {
        background-color: $colorPrimaryBlue !important;
        opacity: 0.6;
      }

      @media screen and (min-width: 600px) {
        height: 48px;
        width: 135px;
        margin-left: 20px;
        margin-top: 22px;
        background-color: $colorPrimaryBlue !important;
      }
    }

    .is-invalid-control {
      + .abn-serach-btn {
        margin-top: -12px;
      }
    }

    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .current-emp-details {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    .employer-name {
      margin-right: 0;
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
      flex: 1;
      flex-basis: 100%;
      @media screen and (min-width: 768px) {
        margin-right: 30px;
        flex: 1;
        flex-basis: 65%;
      }
    }
    .emp-curr-start-dt {
      padding: 0;
      margin-right: 0;
      flex: 1;
      flex-basis: 100%;
      @media screen and (min-width: 768px) {
        flex: 1;
        flex-basis: 35%;
      }
    }
  }

  .error-alert {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #fceaee;
    .risk-img {
      background-color: #dc2849;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      padding: 5px;
    }
    .error-message {
      font-family: 'Poppins-Regular';
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: normal;
      color: $colorDarkGrey;
      padding: 0 30px 0 30px;
    }
  }
  .border-top-add-employment {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -14px;
    margin-right: -14px;
    @media screen and (min-width: 576px) {
      margin-left: -36px;
      margin-right: -36px;
    }
  }
  .btn-add-employment {
    background: #eef2f5;
    border-radius: 3px;
    padding: 7px 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    .add-emp-label {
      font-family: 'Poppins-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: $colorDarkGrey;
    }
  }
  .prevEmpStartDt {
    margin-right: 0;
    @media screen and (min-width: 576px) {
      margin-right: 30px;
    }
  }
}
