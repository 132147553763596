@import '../../../styles/variable.scss';

@mixin common-names() {
  .content-initial {
    background-color: #f1f2f2;
    font-family: 'Poppins-SemiBold';
    font-size: 13px;
    color: $colorDarkGrey;
    height: 40px;
    width: 40px;
    border-radius: 25.5px;
    padding: 10px !important;
    text-transform: uppercase;
  }
  .nav-link {
    padding: 1rem;
    color: $colorDarkGrey;
  }
  .dropdown-menu {
    left: auto !important;
    transform: none !important;
    right: 0;
    top: auto;
    border-radius: 2px;
    box-shadow: 0 5px 18px 0 rgba(102, 104, 106, 0.15);
    background-color: #ffff;

    @media screen and (max-width: 767.95px) {
      display: none;
    }
  }
  .content-initial {
    margin-top: -4px;

    @media screen and (min-width: 768px) {
      margin-right: 10px;
    }

    &.account-avatar {
      font-size: 23px;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
  .content-username {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: $colorDarkGrey;

    display: none;
    @media screen and (min-width: 767.95px) {
      display: initial;
    }
  }
  .content-item {
    display: flex;
    align-items: center;

    &:active,
    &:focus,
    &:hover {
      background-color: $colorBackground;
      outline: none;
      box-shadow: none;
    }

    .content-editimage {
      padding-right: 8px;
    }
    .content-edit {
      color: $colorDarkGrey;
      font-family: 'Poppins-Regular';
      font-size: 15px;
    }
  }
  .dashboard-item {
    display: flex;
    align-items: center;

    &:hover,
    &:active {
      background-color: $colorLightGrey;
    }

    .dashboard-img {
      padding-right: 8px;
    }
    .content-Dashboard {
      color: $colorDarkGrey;
      font-family: 'Poppins-Regular';
      font-size: 15px;
    }
  }
}
.header-container {
  top: 0;
  z-index: 110;
  background-color: $colorWhite;
  box-shadow: 0 -1px 17px 0 $colorTail;

  .navbar {
    padding-top: 3px;
    padding-bottom: 3px;

    &.estimation-route-header {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 767.95px) {
    padding-left: 0;
    .navbar {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.container {
  /*   display: flex;
  flex-direction: row; */
  .header {
    .stratton-logo {
      max-width: 160px;
      @media screen and (min-width: 575.95px) {
        max-width: 100%;
      }
    }
  }
  .header-left-align {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .dashboard-icon {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      white-space: nowrap;
      padding-right: 8px;
      align-items: center;

      @media screen and (max-width: 767.95px) {
        display: none;
      }

      .dashboard-img {
        padding-right: 8px;
      }
      .dashboard-text {
        color: $colorDarkGrey;
        font-family: 'Poppins-Medium';
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23px;
      }
    }

    .call-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      white-space: nowrap;
      padding-right: 8px;

      @media screen and (max-width: 767.95px) {
        display: none;
      }

      .call-us {
        color: $colorDarkGrey;
        font-family: 'Poppins-Regular';
        font-size: 12px;
      }
      .phone {
        color: $colorDarkGrey;
        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-weight: 600;
      }
    }
    .header {
    }
    .justify-content-end {
      .header-dropdown {
        // height: 50px;
        // border-radius: 25.5px;
        // background-color: $colorBackground;

        //   .content-initial {
        //     background-color: rgba(0, 170, 187, 0.1);
        //     font-family: 'Poppins-SemiBold';
        //     font-size: 13px;
        //     color: #00aabb;
        //     border-radius: 25.5px;
        //     text-transform: uppercase;
        //   }
        //   .nav-link {
        //     padding: 1rem;
        //     color: $colorDarkGrey;
        //   }
        //   .dropdown-menu {
        //     left: auto;
        //     right: 0;
        //     border-radius: 2px;
        //     box-shadow: 0 5px 18px 0 rgba(102, 104, 106, 0.15);
        //     background-color: #ffff;

        //     @media screen and (max-width: 767.95px) {
        //       display: none;
        //     }
        //   }
        //   .content-initial {
        //     margin-top: -4px;

        //     @media screen and (min-width: 768px) {
        //       margin-right: 8px;
        //     }

        //     &.account-avatar {
        //       font-size: 23px;

        //       @media screen and (min-width: 768px) {
        //         display: none;
        //       }
        //     }
        //   }
        //   .content-username {
        //     font-family: 'Poppins-Regular';
        //     font-size: 14px;
        //     color: $colorDarkGrey;

        //     display: none;
        //     @media screen and (min-width: 767.95px) {
        //       display: initial;
        //     }
        //   }
        //   .content-item {
        //     display: flex;
        //     align-items: center;
        //     .content-editimage {
        //       padding-right: 8px;
        //     }
        //     .content-edit {
        //       color: $colorDarkGrey;
        //       font-family: 'Poppins-Regular';
        //       font-size: 15px;
        //     }
        //   }
        //   .dashboard-item {
        //     display: flex;
        //     align-items: center;
        //     .dashboard-img {
        //       padding-right: 8px;
        //     }
        //     .content-Dashboard {
        //       color: $colorDarkGrey;
        //       font-family: 'Poppins-Regular';
        //       font-size: 15px;
        //     }
        //   }
        // }
        @include common-names();
        .dropdown-toggle::after {
          display: none;
          @media screen and (min-width: 768px) {
            display: inline-block;
          }
        }
      }
    }
    .login-button {
      .btn-login {
        height: 48px;
        padding: 12px 22px;
        background: #330072;
        border-radius: 3px;
        margin-left: 20px;
        .lock-icon {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
        .login-label {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
}

.dropdown-wrapper {
  display: flex;
  flex-direction: column;

  .title {
    .content-initial {
      background-color: #f1f2f2;
      font-family: 'Poppins-SemiBold';
      font-size: 13px;
      color: #25252a;
      border-radius: 25.5px;
      text-transform: uppercase;
      margin-right: 12px;
      height: 36px;
      width: 36px;
    }
  }

  .sidebar {
    @include common-names();
  }
}
