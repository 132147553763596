@import '../../../styles/variable.scss';
@import '../../../styles/global.scss';
@import '../../components/Header/header.scss';

@mixin logo-align($image, $width, $height) {
  background: url($image) center center/contain no-repeat;
  width: $width;
  height: $height;
}

.login-page-container {
  display: flex;

  flex-direction: row;
  max-width: 100%;
  padding: 0;
  // min-height: calc(100vh - 80px);

  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }

  .login-page-section {
    background-color: #f1f2f2;
    display: none;
    padding: 0;

    @media screen and (max-width: 980px) {
      background-position-x: 30%;
    }

    > img,
    .stratton-info-logo {
      margin-left: 45px;
      max-width: 200px;
      margin-bottom: 60px;
      @media screen and (min-width: 1200px) {
        max-width: 100%;
        margin-bottom: 60px;
        margin-left: 64px;
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
      flex-flow: column;
    }

    .stratton-info-footer-container {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
    }

    .login_details_section {
      @media screen and (min-width: 768px) {
        text-align: center;
        margin-bottom: 80%;
      }
      .nav-estimate,
      .nav-finances,
      .nav-personal {
        size: auto;
        max-width: 90%;
      }
    }
    .step-content-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 24px 0 32px;

      .nav-animation {
        height: 344px;
        width: 60px;
        border-radius: 30px;
        background: linear-gradient(171.51deg, #dce2e4 0%, #f1f2f2 100%);
        padding: 15px 12px 10px;
        margin-right: 20px;

        .nav-animation-line {
          box-sizing: border-box;
          height: 150px;
          width: 2px;
          border: 1px dashed #c2c6c8;
          margin-right: 16px;
          margin-left: 17px;
          .nav-animation-car {
            position: relative;
            left: -20px;
            top: -2px;

            &.with-animation {
              animation: slide-car 1s ease-in 0.2s;
            }
          }

          .checksuccess-circle {
            border-radius: 50%;
            height: 30px;
            width: 30px;
            background-color: #00cd76;
            margin-bottom: 22px;
            position: relative;
            left: -15px;
            top: -2px;
            opacity: 1;
            display: block;
            animation: show-success 0.7s ease-in;

            .nav-checksuccess {
              height: 14px;
              width: 17px;
              margin: 9px 7px 9px 7px;
            }
          }
        }
      }
      .stepper-contents {
        margin-top: 10px;
        .steps-section {
          .step {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 23px;
            &.enabled {
              color: #01b0c2;
              font-family: 'Poppins-SemiBold';
              font-weight: 600;
            }
            &.disabled {
              color: #66696c;
              font-family: 'Poppins-Regular';
            }
          }
          .step-message {
            font-size: 17px;
            letter-spacing: 0;
            line-height: 26px;
            &.enabled {
              color: $colorDarkGrey;
              font-family: 'Poppins-SemiBold';
              font-weight: 600;
            }
            &.disabled {
              color: #66696c;
              font-family: 'Poppins-Regular';
            }
          }
          margin-bottom: 60px;
        }
      }
    }
  }
  .login-page-details {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0;

    .user-dropdown {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .Need-help {
        display: none;
        @media screen and (min-width: 768px) {
          color: $colorDarkGrey;
          font-family: 'Poppins-Regular';
          font-size: 12px;
          float: right;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 44px;
        }

        .number-font {
          color: $colorDarkGrey;
          font-family: 'Poppins-SemiBold';
          font-size: 18px;
          font-weight: 600;
        }
      }
      .auth-dropdown {
        margin-right: 30px;
        .justify-content-end {
          .header-dropdown {
            @include common-names();
            display: none;
            height: 50px;
            // border-radius: 25.5px;
            // background-color: $colorBackground;
            @media screen and (min-width: 768px) {
              display: block;
            }
          }
        }
      }
    }

    .Need-help {
      display: none;
      @media screen and (min-width: 768px) {
        color: $colorDarkGrey;
        font-family: 'Poppins-Regular';
        font-size: 12px;
        float: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 44px;
      }

      .number-font {
        color: $colorDarkGrey;
        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-weight: 600;
      }
    }
    .login-page-message {
      @media screen and (min-width: 768px) {
        margin-left: 80px;
        margin-top: 70px;
        margin-right: 80px;
        margin-bottom: 129px;
      }
      @media screen and (min-width: 1199.98px) {
        margin-top: 70px;
        margin-left: 86px;
        margin-right: 0;
        margin-bottom: 129px;
      }

      .login-page-name {
        font-family: 'Poppins-Bold';
        line-height: 36px;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        padding: 12px;
        color: #25282a;
        align-self: center;
        margin: 0px 0px;

        .logo-highlight {
          background: #e0f8fa;
          border-radius: 6px;
          padding: 0px 10px;
          white-space: nowrap;
        }
        @media screen and (min-width: 768px) {
          font-size: 32px;
          margin-bottom: 20px;
          line-height: 55px;
          padding: 0;
        }
        @media screen and (min-width: 1199.98px) {
          font-size: 42px;
          margin-bottom: 20px;
          line-height: 55px;
          margin-right: 45px;
        }
      }
      .forgot-password {
        font-family: 'Poppins-Bold';
        line-height: 36px;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        font-style: normal;
        padding: 12px;
        color: #25282a;
        align-self: center;
        margin-bottom: 10px;
        padding-bottom: 0;
        .caption-highlight {
          background: #e0f8fa;
          border-radius: 6px;
          padding: 0px 10px;
        }
        @media screen and (min-width: 768px) {
          font-size: 32px;
          line-height: 55px;
          padding: 0;
        }
        @media screen and (min-width: 1199.98px) {
          font-size: 42px;
          line-height: 55px;
        }
      }
      .reset-caption {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 12px;
        padding: 12px;
        padding-top: 0;
        @media screen and (min-width: 768px) {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 22px;
          line-height: 33px;
          color: $colorSubheadingDesc;
          margin-bottom: 30px;
          padding: 0;
        }
      }

      .login-form-section {
        padding: 12px;
        @media screen and (min-width: 768px) {
          width: 350px;
          //height: 288px;
          margin-bottom: 123px;
          padding: 0;
        }
        .verification-spinner {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $colorResidualSwitch;
        }
        .verifyyourself-otp {
          margin-bottom: 12px;
          @media screen and (min-width: 768px) {
            margin-bottom: 17px;
          }
          .otp-input-field {
            width: 40px !important;
            height: 40px;
            background: #ffffff;
            border: 1px solid #28c9da;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 10px;
            text-align: center;
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 22px;
            margin-right: 10px;

            @media screen and (min-width: 768px) {
              width: 48px !important;
              height: 48px;
              background: #ffffff;
              border: 1px solid #28c9da;
              box-sizing: border-box;
              border-radius: 3px;
              padding: 12.5px 14.5px;
              text-align: center;
              font-family: 'Poppins-Regular';
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 22px;
              margin-right: 10px;
            }
          }
        }
        .otp-info-container {
          .verifyyourself-content6 {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #66696c;

            .btn-resend {
              background: #e0f8fa;
              border-radius: 3px;
              padding: 0;
            }

            .verifyyourself-content8 {
              font-family: 'Poppins-Medium';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: $colorDarkGrey;
              padding: 3px 8px;
              border-radius: 3px;
              margin-left: 7px;
              margin-right: 7px;
            }
          }
        }
        .login-input-group {
          margin-bottom: 12px;
          @media screen and (min-width: 768px) {
            margin-bottom: 20px;
          }
          .input-label-font {
            font-family: 'Poppins-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 4px;
          }
          .email-input-group-append {
            .email-icon-input-append {
              border: 1px solid #c2c6c8;
              border-radius: 3px;
              border-right: none;
              padding: 10px;
            }
            .email-border {
              height: 48px;
              background-color: #ffffff;
              font-family: 'Poppins-Regular';
              font-size: 15px;
              border: 1px solid #c2c6c8;
              border-left: none;
              padding: 0;
            }
          }
          .login-input-group-append {
            .password-border {
              height: 48px;
              background-color: #ffffff;
              font-family: 'Poppins-Regular';
              font-size: 15px;
              border: 1px solid #c2c6c8;
              border-right: none;
            }
            .password-icon-input-append {
              border: 1px solid #c2c6c8;
              border-radius: 3px;
              border-left: none;
              padding: 10px;
            }
          }
        }
        .password-remember {
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          margin-bottom: 20px;
          &.acknowledge-section {
            margin-bottom: 30px;
          }
          .forgot-password-link {
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #66696c;
          }
          &.password-signup {
            font-size: 12px;
            line-height: 18px;
            color: $colorSubheadingDesc;
          }
        }

        .btn-primary-call-to-action {
          height: 56px;
          padding: 15px 24px;
          margin-bottom: 40px;
          background-color: #330072;

          &:hover {
            background-color: $colorhover;
          }
          &:disabled {
            background-color: #f1f2f2;
            border-color: #f1f2f2;
            color: #a4a7aa;
          }
          &:not(:disabled):focus,
          &:not(:disabled):active {
            background-color: $colorPrimaryBlue !important;
            color: #ffffff;
            opacity: 0.6;
          }
          .button-font {
            font-family: 'Poppins-Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 25px;
            // color: #ffff;
            // text-align: center;
            // &:disabled {
            //   color: #a4a7aa;
            // }
          }
        }
        .password-message {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          align-self: flex-start;
          margin-bottom: 20px;
          color: $colorSubheadingDesc;
        }
      }
      .login-horizantal-line {
        margin-top: 30px;
        margin-bottom: 0;
        @media screen and (min-width: 768px) {
        }
      }
      .login-page-navigation {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px 12px 43px 12px;
        @media screen and (min-width: 768px) {
        }
        .looking-for {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: $colorDarkGrey;
          margin-bottom: 2px;
          @media screen and (min-width: 768px) {
            font-size: 26px;
            line-height: 39px;
          }
        }
        .get-started {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;

          font-size: 14px;
          line-height: 21px;

          color: #66696c;
          margin-bottom: 10px;
          @media screen and (min-width: 768px) {
            font-size: 17px;
            line-height: 25px;
            margin-bottom: 30px;
          }
        }
        .btn-primary-get-quote {
          background: #f1f2f2;
          border-radius: 3px;
          padding: 10px 16px;
          .get-quote-message {
            font-family: 'Poppins-medium';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #25282a;
            margin: 10px 0px;
            .right-icon {
              height: 16px;
              width: 16px;
              margin-left: 10px;
            }
          }
        }
      }
      .btn-reset {
        padding: 0;
        .back-btn {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $colorDarkGrey;
          .left-arrow {
            height: 20px;
            width: 20px;
            margin-right: 8px;
          }
        }
      }
      .code-sent-message {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        background: #dff8ec;
        border-radius: 3px;
        margin: 12px;
        .success-icon {
          margin-right: 10px;
        }
        .code-sent-text {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          color: $colorDarkGrey;
          @media screen and (min-width: 768px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        @media screen and (min-width: 768px) {
          margin: 0;
          margin-bottom: 30px;
          max-width: 450px;
        }
      }
      .signup-title {
        font-size: 32px;
        margin-bottom: 10px;
        line-height: 55px;
        padding: 0;
        color: $colorDarkGrey;
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        padding: 0px 12px 0px 12px;
        &.signup-name {
          padding-top: 12px;
          color: #01b0c2;
        }
        @media screen and (min-width: 768px) {
          font-size: 42px;
          line-height: 55px;
          padding: 0;
        }
      }
      .signup-subtitle {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        color: $colorSubheadingDesc;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        padding: 0px 12px 0px 12px;
        @media screen and (min-width: 768px) {
          font-size: 22px;
          line-height: 33px;
          margin-bottom: 20px;
          padding: 0;
        }
      }
    }
  }
}
.save-model-layout {
  .modal-container-section {
    .success-icon-container {
      display: flex;
      flex-direction: column;
      .save-title {
        .btn-close-modal {
        }
      }
    }
  }
}
.account-settings-container {
  .account-settings {
    font-family: 'Poppins-SemiBold';
    font-size: 30px;
    font-weight: 600;
    line-height: 2;
    max-width: 920px;
    margin: 0 auto;

    //color: #f1f2f2;
  }
  .account-seetings-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }

    .password-navigation-card {
      flex: 1;
      flex-basis: 100%;
      margin-bottom: 32px;

      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-right: 30px;
        max-width: 350px;
        flex-basis: 28%;
      }

      .password-navigation-body {
        background-color: #fff;
        padding: 1.5rem;

        .password-navigation-security {
          font-family: 'Poppins-Regular';
          font-size: 16px;
          //font-weight: 600;
          color: #66696c;
          display: flex;
          align-items: center;
          .nav-image {
            padding-right: 16px;
          }
          &.isactive {
            font-family: 'Poppins-SemiBold';
          }
        }
        .password-navigation-mobile {
          font-family: 'Poppins-Regular';
          font-size: 16px;
          //font-weight: 600;
          color: #66696c;
          display: flex;
          align-items: center;
          .nav-image {
            padding-right: 16px;
          }
        }
        .character-message {
          color: #66696c;
          font-family: 'Poppins-Regular';
          font-size: 14px;
        }
      }
    }
    .password-change-card {
      background-color: #ffffff;
      flex: 1;
      flex-basis: 100%;
      margin-bottom: 32px;

      @media screen and (min-width: 768px) {
        max-width: 540px;
        flex-basis: 65%;
      }

      .password-change-body {
        .password-change-title {
          font-family: 'Poppins-SemiBold';
          font-size: 16px;
          font-weight: 600;
          //color: #f1f2f2;
        }
        .change-password-message {
          padding: 18px 0px 18px 20px;
          border-radius: 3px;
          text-align: center;
          background-color: rgba(223, 248, 236, 0.6);
          display: flex;
          align-items: center;
          .change-password-quote {
            font-family: 'Poppins-Regular';
            font-size: 17px;
            color: $colorDarkGrey;

            .success {
              padding-right: 8px;
            }
          }
        }
        .password-border {
          border-right: none;
        }
        .input-append-bg {
          background-color: #fff;
        }
      }
      .footer-color {
        background-color: #ffffff;

        .footer-update-password {
          height: 40px;
          border-radius: 3px;
          border-radius: 3px;
          background-color: #a4a7aa;
          font-family: 'Poppins-Medium';
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          float: right;
          &:disabled {
            background-color: $colorInactive;
            border-color: $colorInactive;
          }
          &:not(:disabled),
          &:not(:disabled):hover,
          &:not(:disabled):active {
            background-color: $colorPrimaryBlue !important;
            color: $colorWhite !important;
          }
        }
      }
    }
  }
}

.login-modal {
  .login-modal-header {
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
    font-weight: 600;
    color: $colorDarkGrey;
    border-radius: 3px;
    background-color: #ffffff;
  }
  .login-modal-body {
    .login-modal-content {
      text-align: center;
      font-family: 'Poppins-Regular';
      font-size: 16px;
      color: $colorDarkGrey;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .login-img {
        height: 30px;
      }
      .login-text-msg {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 26px;
        text-align: center;
        padding: 30px;
      }
    }
    .login-modal-btn {
      display: flex;
      justify-content: center;
      .modal-continue {
        height: 48px;
        border-radius: 3px;
        color: #66696c;
        font-family: 'Poppins-Medium';
        font-size: 16px;
        color: #ffffff;
        background-color: $colorPrimaryBlue;
        font-family: 'Poppins-Medium';

        &:not(:disabled):hover,
        &:not(:disabled):focus,
        &:not(:disabled):active {
          background-color: $colorPrimaryBlue !important;
          opacity: 0.6;
        }
      }
      .modal-cancel {
        height: 48px;
        border-radius: 3px;
        background-color: #e4e8ea;
        color: #66696c;
        font-family: 'Poppins-Medium';
        font-size: 16px;
      }
    }
  }
  .login-modal-footer {
    font-family: 'Poppins-Regular';
    font-size: 15px;
    color: $colorDarkGrey;
    border-top: none;
    border-radius: 3px;
    background-color: #ffffff;
  }
}
