@import '../../../styles/variable.scss';

.address-details-page {
  background: #ffffff;
  border-radius: 3px 3px 0px 0px;
  @media screen and (min-width: 768px) {
    margin-top: 30px;
  }
  .address-title-section {
    padding: 10px 12px;

    @media screen and (min-width: 768px) {
      padding: 24px;
    }
    .address-details-title {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: 600;
      color: $colorDarkGrey;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0.5rem;
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 27px;
      }
    }
    .address-details-subtitle {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: 400;
      color: $colorSubtitle;
      font-size: 14px;
      line-height: 21px;
      @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .address-details-who {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: 600;
      color: #10b2c4;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 0.5rem;
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
  .landlord-details-section {
    padding: 10px 12px;
    border-top: 1px solid #f1f2f2;

    @media screen and (min-width: 768px) {
      padding: 24px;
    }
    .current-landlord-header {
      font-family: 'Poppins-Medium';
      font-style: normal;
      font-weight: 500;
      color: $colorSubtitle;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 20px;
      @media screen and (min-width: 768px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
      }

      .address-status {
        font-family: 'Poppins-SemiBold';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: $colorDarkGrey;
        background: $colorStatus;
        padding: 3px 8px;
        @media screen and (min-width: 768px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .current-address-subtitle {
      font-style: italic;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #5b5b5b;
      margin-bottom: 20px;
      @media screen and (min-width: 768px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
    .current-address-default-section {
      display: flex;
      flex-direction: column;
      .currentaddress-select {
        flex: 1;
        flex-basis: 100%;
        margin-right: 0;
        @media screen and (min-width: 768px) {
          flex-basis: 60%;
          margin-right: 30px;
        }
      }
      .current-address-to-date {
        flex: 1;
        flex-basis: 100%;
        @media screen and (min-width: 768px) {
          flex-basis: 40%;
          padding: 0;
        }
      }
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
      .appointer {
        flex: 1;
        flex-basis: 100%;
        @media screen and (min-width: 768px) {
          flex-basis: 65%;
          padding: 0;
          margin-right: 30px;
        }
      }
      .settlement-amount {
        flex: 1;
        flex-basis: 100%;
        @media screen and (min-width: 768px) {
          flex-basis: 35%;
          padding: 0;
        }
      }
    }
    .landlord-name-phone {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
      }

      .landlord-name {
        padding: 0;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 10px;
        font-weight: 500;
        font-family: 'Poppins-Medium';
        flex: 1;
        flex-basis: 100%;

        @media screen and (min-width: 768px) {
          margin-right: 30px;
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 0;
          flex: 1;
          flex-basis: 60%;
        }
      }
    }
    .landlord-phone {
      padding: 0;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;
      font-weight: 500;
      font-family: 'Poppins-Medium';
      flex-basis: 100%;
      @media screen and (min-width: 768px) {
        font-size: 15px;
        line-height: 22px;
        flex: 1;
        flex-basis: 40%;
      }
    }
  }
  .second-address-section {
    padding: 10px 12px;
    padding-bottom: 0;
    @media screen and (min-width: 768px) {
      padding: 24px;
    }

    .addressselection {
      padding: 0;
    }
    .second-address-label {
      font-family: 'Poppins-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $colorSubtitle;
      margin-bottom: 10px;
      @media screen and (min-width: 768px) {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 21px;
      }
      &.previous-address-duration {
        margin-bottom: 10px;
        margin-top: 10px;
        @media screen and (min-width: 768px) {
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }
    }
    .benefiter-details {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
      .benefiter-type {
        flex: 1;
        flex-basis: 100%;
        @media screen and (min-width: 768px) {
          flex-basis: 65%;
          padding: 0;
          margin-right: 30px;
        }
      }
      .benefiter-entity-type {
        flex: 1;
        flex-basis: 100%;
        @media screen and (min-width: 768px) {
          flex-basis: 35%;
          padding: 0;
        }
      }
    }
    .addressselection {
      display: flex;
      flex: 1;
      flex-basis: 70%;
      padding: 0;
    }

    .previous-address-date {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
      .prev-address-from-date {
        padding: 0;
        margin-bottom: 10px;
        margin-right: 0;
        input {
          padding-top: 15px;
          padding-bottom: 14px;
        }
        @media screen and (min-width: 768px) {
          padding: 0;
          margin-bottom: 0;
          margin-right: 30px;
        }
      }
      .prev-address-to-date {
        padding: 0;
        margin-bottom: 0;
      }
      .name {
        flex: 1;
        flex-basis: 50%;
        margin-right: 30px;
      }
    }
    .benefiter-address-details {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 768px) {
        flex-direction: row;
      }
      .benefiter-addressselection {
        flex: 1;
        flex-basis: 100%;
        @media screen and (min-width: 768px) {
          flex-basis: 65%;
          padding: 0;
          margin-right: 30px;
        }
      }
      .date-of-birth {
        flex: 1;
        flex-basis: 100%;
        padding: 0;
        @media screen and (min-width: 768px) {
          flex-basis: 35%;
          padding: 0;
        }
      }
    }
  }
}

.adress-manual-enter {
  margin-bottom: 20px;
  .search-your-address {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;

    color: $colorSubheadingDesc;
    .search-address {
      background: $colorTexthighlight;
      border-radius: 3px;
      margin-left: 10px;
      font-family: 'Poppins-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $colorDarkGrey;
      padding: 3px 8px;
    }
  }
}
.adress-unit-streetname {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 576.65px) {
    flex-direction: row;
    margin-bottom: 0px;
  }
  .unit-number {
    flex: 1;
    flex-basis: 35%;
    padding: 0;
    margin-right: 30px;
    margin-bottom: 10px;
    @media screen and (min-width: 576.65px) {
      margin-right: 30px;
    }
  }
  .street-name {
    flex: 1;
    flex-basis: 65%;
    padding: 0;
  }
}
.adress-suburb-postcode {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 576.65px) {
    flex-direction: row;
    justify-content: space-between;
  }
  .suburb {
    flex: 1;
    flex-basis: 37%;
    padding: 0;
    margin-right: 30px;
    margin-bottom: 10px;
    @media screen and (min-width: 576.65px) {
      margin-right: 30px;
    }
  }
  .postcode {
    flex: 1;
    flex-basis: 35%;
    padding: 0;
    margin-right: 30px;
    margin-bottom: 10px;
    @media screen and (min-width: 576.65px) {
      margin-right: 30px;
    }
  }
  .state {
    flex: 1;
    flex-basis: 30%;
    padding: 0;
  }
}
.addition-info-progress {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  .completed {
    height: 7px;
    background: #10b2c4;
    border-radius: 5px;
    width: 54px;
    margin-right: 10px;
  }
  .pending {
    height: 7px;
    background: #dddbda;
    border-radius: 5px;
    width: 54px;
    margin-right: 10px;
  }
}
.delete-align {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.enter-manual-address {
  .could-not-find {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    color: $colorSubheadingDesc;
    @media screen and (min-width: 768px) {
      font-size: 15px;
      line-height: 22px;
    }
    .enter-manually {
      background: $colorTexthighlight;
      border-radius: 3px;
      padding: 3px 8px;
      margin-left: 10px;
      .enter-manually-label {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $colorDarkGrey;
      }
    }
  }
}
