@import '../../../styles/variable.scss';

.upload-document-page {
  background: #ffffff !important;
  border-radius: 3px 3px 0px 0px;

  @media screen and (min-width: 768px) {
    margin-top: 30px;
  }
}

.modal-application-wrapper {
  max-width: 750px;
  margin-top: 5rem;
}

.warpper-desc {
  font-family: "Poppins-Medium";
  flex-direction: row;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  color: $colorSecondaryText ;

  .row-title {
    margin: 40px 10px 35px 10px;

    .txt-title {
      font-size: 30px;
      font-weight: bold;

    }
  }

  .row-highlited {
    margin-top: 2rem;

    .txt-highlighted {
      color: $colorSecondaryBlue
    }
  }

  .row-description {
    margin: 0 3rem 0 3rem;
  }

  .row-description-direct {
    margin: 0 3rem 0 3rem;
    text-align: left;
    line-height: 1.5rem;
    margin-bottom: 2.5rem;

    .text-number-highlight {
      font-weight: bold;
      font-size: 18px;
      color: $colorBlack;
    }
  }

  .row-footer {
    margin: 3rem 0 1rem 0;

    .txt-footer {
      color: $colorBlack;
    }
  }

  .footer-button-container {
    display: flex;
    font-family: "Poppins-Medium";
    font-size: 16px;
    margin: 2rem 0 2rem 0;
    line-height: 5rem;
    justify-content: center;
    text-align: center;
    color: $colorDarkGrey !important;
    background-color: $colorWhite !important;
    overflow: hidden;
    overflow-wrap: break-word;

    @media screen and (max-width: 550px) {
      line-height: 2rem;
      font-size: 14px;

    }

    .footer-button {
      height: 5rem;
      border-radius: 3px;
      border: solid 1px $colorSecondaryButton !important;
      width: 45%;
      float: left;

      &:active,
      &:focus,
      &:hover {
        background-color: $colorHoverBackground;
        outline: none;
        box-shadow: none;
      }


      @media screen and (max-width: 550px) {
        height: 4rem;
        width: 49%;
      }
    }

    .footer-left-Button {
      margin-right: 10px;

      @media screen and (max-width: 550px) {
        margin: 0;
      }
    }

    .footer-right-Button {
      margin-left: 10px;

      @media screen and (max-width: 550px) {
        margin: 0;
      }
    }
  }
}

.additional-document-list-wrapper, .payslip-wrapper{
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}