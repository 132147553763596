@import '../../../../styles/variable.scss';
.content-initial {
  background-color: #f1f2f2;
  font-family: 'Poppins-SemiBold';
  font-size: 13px;
  color: $colorDarkGrey;
  height: 40px;
  width: 40px;
  border-radius: 25.5px;
  padding: 10px !important;
  text-transform: uppercase;
}
.content-username {
  font-family: 'Poppins-Regular';
  font-size: 14px;
  color: $colorDarkGrey;

  display: none;
  @media screen and (min-width: 767.95px) {
    display: initial;
  }
}
.nav-link {
  padding: 1rem;
  color: $colorDarkGrey;
}
