// Poppins Regular
@font-face {
  font-family: 'Poppins-Regular';
  src: url('./Poppins-Regular.ttf') format('truetype');
  font-display: swap;
}

// Poppins Bold
@font-face {
  font-family: 'Poppins-Bold';
  src: url('./Poppins-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./Poppins-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./Poppins-SemiBold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url('./Poppins-Medium.ttf') format('truetype');
  font-display: swap;
}
