@import '../../styles/variable.scss';
@import '../../styles/global.scss';

.select-field-container {
  .select-field-label {
    margin-bottom: 10px;
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0.5rem;
    }
    &.car-full-screen-label {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      font-family: 'Poppins-Semibold';
      margin-bottom: 12px;
      @media screen and (min-width: 576.78px) {
        font-size: 22px;
        line-height: 33px;
        margin-bottom: 30px;
      }
    }
  }

  &.vehicle-make-container {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .select-field-section {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 600px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      @media screen and (max-width: 460px) {
        .select-field-group {
          .select-field {
            height: 70px !important;
            @media screen and (max-width: 360px) {
              width: 120px !important;
              height: 70px !important;

              &.full-screen-mode {
                width: 120px !important;
                height: 70px !important;
              }
            }
          }
        }
      }

      .select-field-group {
        padding: 0 !important;
      }
    }

    @media screen and (max-width: 767.95px) {
      &.vehicle-variant-options {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
      }
    }

    .select-field-group {
      .select-field {
        @include select-buttons;

        &.vehicle-make-select {
          width: 160px;
          height: 90px;
          //padding-right: 8px;
          flex-wrap: wrap;
          flex-direction: column;

          @media screen and (max-width: 600px) {
            width: 160px;
            height: 90px;
            flex-flow: row;
          }

          &.lengthy-label {
            .btn-icon {
              margin-left: 0;
            }
            // width: 200px;
            // word-break: break-all;
          }

          &.load-others {
            background-color: #f4f5f5;
            color: #25282a;
          }

          .vehicle-make {
            width: 30px;
            height: 30px;
            //margin-left: -30px;
            background: transparent no-repeat center;

            &.btn-icon {
              display: none;
            }
          }
        }
      }
      .vehicle-variant-nvic {
        display: flex;
        justify-content: center;
        align-items: center;
        //height: 75px;
        border-radius: 3px;
        background-color: rgba(0, 193, 213, 0.1);
        background-color: #fff;
        font-family: 'Poppins-Regular';
        font-size: 16px;
        color: #929394;
        //text-transform: capitalize;

        @media screen and (min-width: 768px) {
          width: 620px;

          .skeleton-pulse-placeholder {
            width: 586px !important;
          }
        }

        @media screen and (max-width: 600px) {
          height: auto !important;
        }
      }
    }
  }
}

.grouped-list-container {
  .select-field-label {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;

    &.car-full-screen-label {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      font-family: 'Poppins-Semibold';
      margin-bottom: 12px;
      @media screen and (min-width: 576.78px) {
        font-size: 22px;
        line-height: 33px;
        margin-bottom: 20px;
      }
    }
  }
  .select-label {
    &.label-search-largecontainer {
      justify-content: flex-start;
      max-width: 690px;
      margin-left: 55px;
      @media screen and (max-width: 597.95px) {
        margin-left: 0;
      }
    }
    &.wide-side-nav {
      margin-left: 0;
      margin-bottom: 20px;
    }

    &.label-search-mediumcontainer {
      @media (max-width: 952.95px), (min-width: 992px) and (max-width: 1049.95px) {
        max-width: 516px;
      }

      @media screen and (max-width: 767.95px) {
        justify-content: flex-start;
        max-width: 510px;
        margin-left: 95px;
      }

      @media (max-width: 641.95px) {
        max-width: 316px;
      }

      @media (max-width: 600px) {
        max-width: initial;
        width: auto;
      }

      @media screen and (max-width: 597.95px) {
        margin-left: 0;
      }
    }
  }

  // .select-field-container {
  //   .select-field-section {
  //     @media screen and (max-width: 600px) {
  //       // grid-template-columns: repeat(1, 1fr);
  //       .select-field-group {
  //         .select-field {
  //           width: 115px !important;
  //           height: 115px !important;

  //           @media screen and (max-width: 360px) {
  //             width: 80px !important;
  //             height: 80px !important;

  //             &.full-screen-mode {
  //               width: 115px !important;
  //               height: 115px !important;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.btn-next {
  background-color: $colorGreenSuccess;
  color: $colorWhite;
  border-radius: 4px;

  &:hover {
    background-color: $colorhover;
  }
  &:focus,
  &:active {
    background-color: $colorGreenSuccess;
    color: $colorWhite;
    opacity: 0.8;
  }
}
.chevron-right-custom {
  color: inherit;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 0.9rem;
}
.data-transfer-custom {
  border-radius: 4px;
  font-size: 0.9rem;
  border-style: solid;
}

.question-custom {
  color: #00c1d5;
}
