@import '../../../styles/variable.scss';

.dashboard-doc-verification-container {
  background: #ffffff;
  border-radius: 3px 3px 0px 0px;
  @media screen and (min-width: 768px) {
    margin-top: 30px;
  }

  &.dashboard-doc-container {
    margin-top: 0;
  }
  .doc-title-section {
    padding: 10px 12px;

    @media screen and (min-width: 768px) {
      padding: 24px;
    }
    .doc-details-title {
      font-family: 'Poppins-Bold';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: $colorDarkGrey;
      font-size: 14px;
      line-height: 21px;
      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 27px;
      }
    }
    .doc-details-subtitle {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: normal;
      color: $colorSubtitle;
      font-size: 12px;
      line-height: 18px;
      @media screen and (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  .dashboard-doc-identification-container {
    padding: 10px 12px;
    border-top: 1px solid #f1f2f2;

    @media screen and (min-width: 768px) {
      padding: 24px;
      padding-bottom: 8px;
    }
    &.identification-section {
      padding: 0;
      border-top: none;
    }
    &.income-verification-section {
      padding: 0;
      border-top: none;
    }
    .identity-documents-block {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      margin-top: 0px;
      @media screen and (min-width: 576px) {
        margin-bottom: 20px;
        // margin-top: 24px;
      }
      &.income-verify-border {
        margin-top: 24px;
      }
      .identity-documents-label {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $colorSubStepInactive;
        margin-right: 10px;
        @media screen and (min-width: 576px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
      .doc-status {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: $colorDarkGrey;
        background: #ffdc73;
        border-radius: 3px;
        padding: 3px 8px;
        @media screen and (min-width: 576px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .income-type-flex {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      @media screen and (min-width: 576px) {
        margin-bottom: 20px;
      }
      .income-type-label {
        font-family: 'Poppins-SemiBold';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        color: $colorDarkGrey;
        margin-right: 10px;
        @media screen and (min-width: 576px) {
          font-size: 15px;
          line-height: 22px;
        }
      }
      .doc-status {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        color: $colorDarkGrey;
        background: #ffdc73;
        border-radius: 3px;
        padding: 3px 8px;
        @media screen and (min-width: 576px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .doc-type {
      margin-bottom: 20px;
    }
    .multiple-doc {
      flex: wrap;
      @media screen and (min-width: 576px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}
.document-line-separator {
  margin: 10px -12px;
  color: #f1f2f2;
  @media screen and (min-width: 576px) {
    margin: 8px -24px 24px -24px;
  }
}
