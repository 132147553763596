@import '../../../styles/variable.scss';

.car-details-options-section {
  display: grid;
  justify-content: center;

  &.contained-section {
    justify-content: flex-start;
    padding-left: 5px;
    margin-bottom: 1rem;
  }

  .any-factory-options {
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 5px;
    color: $colorDarkGrey;
    &.car-details-any-factory {
      font-weight: 500;
      font-size: 15px;
      font-family: 'Poppins-Medium';
      margin-bottom: 0;
    }
    @media screen and (min-width: 576.78px) {
      font-size: 22px;
      line-height: 33px;
    }
  }
  .no-factory-options {
    display: flex;
    flex-direction: row;
    height: 120px;
    width: 730px;
    border-radius: 3px;
    background-color: $colorWhite;
    .factory-options-message {
      color: #66696c;
      font-family: 'Poppins-Regular';
      font-size: 17px;
      letter-spacing: 0;
      line-height: 26px;
      margin: 36px 86px 36px 65px;
    }
    .continue {
      height: 56px;
      //width: 153px;
      border-radius: 3px;
      background-color: #330072;
      margin: 32px 24px 32px;
      display: flex;
      .continue-name {
        color: $colorWhite;
        font-family: 'Poppins-Regular';
        font-size: 17px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 26px;
        margin: 16px 15px 16px 24px;
      }
      .image {
        margin: 16px 24px 16px 0px;
        .right-icon {
          width: 10px;
        }
      }
    }
  }
  .factory-options-a {
    font-family: 'Poppins-Regular';
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22x;
    color: $colorSubheadingDesc;
    margin-bottom: 30px;
    &.car-details-factory-a {
      font-family: 'Poppins-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    @media screen and (min-width: 576.78px) {
      font-size: 17px;
      line-height: 26px;
    }
  }
  .rectangle {
    box-sizing: border-box;
    height: 140px;
    border: 1px dashed #c2c6c8;
    border-radius: 3px;
    text-align: center;

    @media screen and (min-width: 600px) {
      width: 540px;
    }

    &.rectangle-border {
      background-color: $colorWhite;
      border: none;
      padding: 24px;
      max-height: 200px;
      height: 100%;
      text-align: left;

      @media screen and (min-width: 600px) {
        height: 140px;
      }
    }

    &.nowhite-background {
      // background-color: $colorBackground;
      border: 1px dashed #c2c6c8;
    }

    .add-factory-option-btn {
      height: 40px;
      width: 190px;
      border-radius: 3px;
      background-color: #e4e8ea;
      color: $colorDarkGrey;
      font-family: 'Poppins-Medium';
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      margin: 50px auto;
    }

    .order-list-factory-options {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      padding: 0;
      overflow-y: auto;
      flex-wrap: wrap;
      max-height: 100%;

      .list-factory-option {
        height: 30px;
        border-radius: 3px;
        background-color: #f3f7fa;
        margin-right: 10px;
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-bottom: 10px;

        .option-name {
          color: $colorDarkGrey;
          font-family: 'Poppins-Medium';
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          margin: 6px 16px 7px 12px;
          white-space: nowrap;
        }
        .close {
          margin: 11px 12px 11px 0px;
        }
      }
    }
  }
  .factory-options-add {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .add-selected-options {
      height: 32px;
      border-radius: 3px;
      background-color: #e4e8ea;
      color: $colorDarkGrey;
      font-family: 'Poppins-Medium';
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 3rem;
    }
  }
}
