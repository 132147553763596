@import '../../../styles/variable.scss';

@mixin logo-align($image, $width, $height) {
  background: url($image) center center/contain no-repeat;
  width: $width;
  height: $height;
}

.estimationComplete-container,
.estimationLoadingPage-container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  padding: 0;

  .estimationComplete-section {
    background-color: #f1f2f2;
    display: none;
    padding: 0;
    min-height: 100vh;

    >img,
    .stratton-info-logo {
      margin-left: 45px;
      max-width: 200px;
      margin-bottom: 60px;

      @media screen and (min-width: 1200px) {
        max-width: 100%;
        margin-bottom: 60px;
        margin-left: 64px;
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
      flex-flow: column;
    }

    .stratton-info-footer-container {
      flex: 1;
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: center;

      &.stratton-info-footer-loadingpage {
        justify-content: center;
      }
    }

    .estimatedetails_loanImage {
      .gridItemContainer {
        display: flex;
        color: #66696c;

        .label {
          margin-left: 0.5rem;
        }

        .icon-container {
          width: 1.3em;
          height: 1.3rem;
          border-radius: 50%;
          font-size: 0.8rem;
          color: black;
          text-align: center;
          background: white;

          .icon {
            font-size: 1rem;
          }
        }
      }

      &.estimatedetails_loadImagecenter {
        display: flex;
        flex: 1;
      }

      @media screen and (min-width: 768px) {
        text-align: center;
        margin-bottom: 20px;
      }

      .nav-loan {
        size: auto;
        max-width: 90%;
      }
    }

    .step-content-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0 24px 0 32px;

      .nav-animation {
        height: 400px;
        width: 60px;
        border-radius: 30px;
        background: linear-gradient(171.51deg, #dce2e4 0%, #f1f2f2 100%);
        padding: 15px 12px 10px;
        margin-right: 20px;

        .nav-animation-line {
          box-sizing: border-box;
          height: 150px;
          width: 2px;
          border: 1px dashed #c2c6c8;
          margin-right: 16px;
          margin-left: 17px;

          .nav-animation-car {
            position: relative;
            left: -20px;
            top: -2px;

            &.with-animation {
              animation: slide-car 1s ease-in 0.2s;
            }
          }

          .checksuccess-circle {
            border-radius: 50%;
            height: 30px;
            width: 30px;
            background-color: #00cd76;
            margin-bottom: 22px;
            position: relative;
            left: -15px;
            top: -2px;
            opacity: 1;
            display: block;
            animation: show-success 0.7s ease-in;

            .nav-checksuccess {
              height: 14px;
              width: 17px;
              margin: 9px 7px 9px 7px;
            }
          }
        }
      }

      .stepper-contents {
        margin-top: 10px;

        .steps-section {
          .step {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 23px;

            &.enabled {
              color: #01b0c2;
              font-family: 'Poppins-SemiBold';
              font-weight: 600;
            }

            &.disabled {
              color: #66696c;
              font-family: 'Poppins-Regular';
            }
          }

          .step-message {
            font-size: 17px;
            letter-spacing: 0;
            line-height: 26px;

            &.enabled {
              color: $colorDarkGrey;
              font-family: 'Poppins-SemiBold';
              font-weight: 600;
            }

            &.disabled {
              color: #66696c;
              font-family: 'Poppins-Regular';
            }
          }

          margin-bottom: 60px;
        }
      }
    }
  }

  .estimationComplete-page-details {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 0;

    .Need-help {
      display: none;

      @media screen and (min-width: 768px) {
        color: $colorDarkGrey;
        font-family: 'Poppins-Regular';
        font-size: 12px;
        float: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 44px;
      }

      .number-font {
        color: $colorDarkGrey;
        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-weight: 600;
      }
    }

    .estimationComplete-message {
      padding: 0;
      margin-left: 20px;
      margin-right: 20px;

      @media screen and (min-width: 768px) {
        padding: 1.5rem;
      }

      @media screen and (min-width: 1199.98px) {
        margin-left: 86px;
        margin-right: 134px;
      }

      .font-emphasis {
        font-family: 'Poppins-Bold';
        font-size: 32px;
        font-weight: bold;
        line-height: 1.5;

        @media screen and (min-width: 768px) {
          font-size: 42px;
        }
      }

      .estimationComplete-name {
        color: $colorSecondaryBlue;
        font-family: 'Poppins-Bold';
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;

        @media screen and (min-width: 768px) {
          font-size: 42px;
          line-height: 55px;
        }
      }

      .estimationComplete-title {
        margin-bottom: 30px;
        color: $colorDarkGrey;
        font-family: 'Poppins-Bold';
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        overflow-wrap: break-word;

        @media screen and (min-width: 768px) {
          font-size: 20px;
        }

        .loan-options-highlight {
          background-color: $colorTexthighlight;
          padding: 0 10px 0 10px;
        }
      }

      .Quote-estimate-section {
        border: 1px solid #e4e8ea;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 10px;
        margin-bottom: 30px;
        padding-bottom: 20px;
        padding-top: 0px;

        @media screen and (min-width: 768px) {
          //padding: 30px 30px;
          padding: 20px 20px;
          padding-top: 0px;
        }

        .horizantal {
          margin-left: -25px;
          margin-right: -25px;

          @media screen and (max-width: 768px) {
            margin-left: -12px;
            margin-right: -12px;
          }
        }

        .footer-update {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          //padding: 0px 0 20px 0;
          background-color: white;

          //margin-right: -20px;
          @media screen and (max-width: 768px) {
            margin-right: 0;
          }

          .update-cancel {
            border: 1px solid #a4a7aa;
            border-radius: 3px;
            font-family: 'Poppins-Medium';
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 21px;
            height: 40px;
            padding: 10px 25px 10px 25px;
          }

          .update-loanamt {
            border-radius: 3px;
            background-color: #330072;
            color: #ffffff;
            font-family: 'Poppins-Medium';
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 21px;
            text-align: center;
            height: 40px;
            padding: 10px 27px 10px 27px;
            display: flex;
            align-items: center;
          }
        }

        .quote-estimate-label {
          color: $colorSubheadingDesc;
          font-family: 'Poppins-Regular';
          font-size: 18px;
          letter-spacing: 0;
          line-height: 33px;
          width: auto;

          @media screen and (min-width: 768px) {
            font-size: 17px;
            line-height: 25px;
          }
        }

        .amount-range-selector,
        .estimate-range-slider {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          color: #25252a;
          font-family: 'Poppins-Regular';
          font-size: 16px;
          letter-spacing: 0;
          line-height: 25px;

          @media screen and (min-width: 1200px) {
            flex-wrap: nowrap;
          }

          .amount-range-slider,
          .estimation-range-selector,
          .estimate-loan-amount {
            flex-basis: 100%;
            width: 100%;
            max-width: 100%;

            @media screen and (max-width: 900px) and (min-width: 1100px),
            (min-width: 600px) {
              //width: auto;
              flex-basis: 50%;
              width: 50%;
              max-width: 50%;
              line-height: 1;
            }

            @media screen and (max-width: 899.95px) and (min-width: 768px) {
              //width: auto;
              flex-basis: 100%;
              width: 100%;
              max-width: 100%;
            }
          }
        }

        .estimate-range-slider {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 0;

          @media screen and (min-width: 600px) {
            margin-bottom: 30px;
          }

          .estimate-loan-amount {
            font-family: 'Poppins-SemiBold';
            font-size: 24px;
            font-weight: 600;
            color: $colorDarkGrey;
            padding-left: 0;
            flex-basis: 100%;
            margin-top: 24px;

            @media screen and (min-width: 600px) {
              font-size: 36px;
              padding-left: 20px;
              flex-basis: 50%;
              margin-top: 0;
            }

            .loan-amt-label {
              font-family: 'Poppins-Regular';
              font-size: 15px;
              font-weight: normal;
              color: #66686a;
            }
          }
        }

        .btn-group-freg {
          // background-color: white;
          border-radius: 3px;
          // border: solid 1px #bebebf;
          padding-bottom: 20px;
          padding-top: 8px;

          .btn-freq {
            background-color: transparent;
            height: 30px;
            color: #66696c;
            padding: 0px 10px 0px 10px;
            line-height: 1.33;

            &.is-selected {
              border-radius: 3px;
              background-color: #f3f7fa;
              font-weight: 600;
              color: $colorDarkGrey;
              //border: solid 1px $colorDarkGrey;
            }

            &:focus,
            &:active {
              outline: none;
              background-color: rgba(255, 255, 255, 0.25) !important;
            }

            .freq-name {
              font-family: 'Poppins-Medium';
              font-size: 14px;
              font-weight: 500;
              color: $colorDarkGrey;
            }
          }
        }

        .estimation-header {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 30px;

          &>div {
            margin-bottom: 30px;
          }

          .estimate-payment-range {
            display: flex;
            flex-direction: column;
            font-family: 'Poppins-Regular';
            color: #66686a;
            font-size: 15px;

            .repayment-range {
              display: flex;
              flex-direction: row;
              font-family: 'Poppins-SemiBold';
              font-size: 36px;
              font-weight: 600;
              color: $colorDarkGrey;
              line-height: 1;
              flex-wrap: wrap;
            }
          }

          .estimate-interest-range {
            display: flex;
            flex-direction: column;
            font-family: 'Poppins-Regular';
            color: #66686a;
            font-size: 15px;

            .intrest-range {
              display: flex;
              flex-direction: row;
              font-family: 'Poppins-SemiBold';
              font-size: 36px;
              font-weight: 600;
              color: $colorDarkGrey;
              line-height: 1;
              flex-wrap: wrap;

              .pa-text-change {
                font-size: 16px;
                height: 24px;
                display: flex;
                margin-left: 5px;
                place-self: flex-end;
              }
            }
          }
        }

        .estimate-slider {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-bottom: 30px;

          .estimate-loan-amount {
            font-family: 'Poppins-SemiBold';
            font-size: 36px;
            font-weight: 600;
            color: $colorDarkGrey;

            .loan-amt-label {
              font-family: 'Poppins-Regular';
              font-size: 15px;
              font-weight: normal;
              color: #66686a;
            }
          }

          .estimate-loan-term {
            .term-val {
              display: flex;
              color: $colorDarkGrey;
              font-family: 'Poppins-SemiBold';
              font-size: 30px;
              font-weight: 600;
            }

            .loan-amt-label {
              font-family: 'Poppins-Regular';
              font-size: 15px;
              font-weight: normal;
              color: #66686a;
            }
          }
        }

        .residual-switch {
          padding-left: 0;
          white-space: nowrap;
          align-items: center;

          &.residual-switch_update {
            padding-left: 0;
            white-space: nowrap;
            align-items: center;

            @media screen and (min-width: 600px) {
              padding-left: 20px;
            }
          }

          .residual-switch-label {
            max-width: calc(100% - 0.5rem);
            white-space: pre-wrap;
            color: $colorDarkGrey;
            font-family: 'Poppins-SemiBold';
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 27px;

            padding-right: 1rem;

            @media screen and (min-width: 1080px) {
              padding-right: 2rem;
            }

            .include-residual {
              display: block;
            }
          }

          .custom-control-label {
            &::before {
              top: 15px;
              left: -0.2rem;
              width: 50px;
              height: 30px;
              border-radius: 16.5px;
              background-color: $colorTail;
            }

            &.checked {
              &::before {
                background-color: $colorResidualSwitch;
                border-color: $colorResidualSwitch;
              }
            }

            &::after {
              top: 18px;
              height: 24px;
              width: 24px;
              border-radius: 15px;
              background-color: #ffffff;
              box-shadow: -1px 0 4px 0 rgba(18, 78, 84, 0.36);
              background-color: #fff;
              // left: calc(-0.2rem + 13px);
              left: 0px;
            }
          }

          .custom-control-input:checked~.custom-control-label::after {
            background-color: $colorWhite;
            left: 8px;
          }
        }
      }

      .details-header {
        color: $colorDarkGrey;
        font-family: 'Poppins-Regular';
        font-size: 26px;
        line-height: 39px;
        font-weight: 500;
      }

      .details-body {
        color: $colorSubheadingDesc;
        font-family: 'Poppins-Regular';
        font-size: 16px;
        line-height: 24px;
      }

      .email-quote {
        display: flex;
        //flex-direction: column;

        .email-quote-btn {
          display: flex;
          align-items: center;
          padding: 15px 24px;
          background: #e4e8ea;
          border: 0.5px solid #e4e8ea;
          border-radius: 3px;
          margin: 0px 0px;
          font-family: 'Poppins-Medium';
          font-size: 17px;
          margin-bottom: 10px;

          @media screen and (min-width: 470px) {
            margin-bottom: 0;
            margin-right: 20px;
          }

          .email-icon {
            padding-right: 10px;
            // width: 20px;
            height: 20px;
          }
        }

        .letsgetstarted-btn {
          display: flex;
          align-items: center;
          border-radius: 3px;
          background-color: #330072;
          border-color: #330072;
          color: #ffffff;
          font-family: 'Poppins-Medium';
          font-size: 17px;
          padding: 15px 24px;
          border: 0.5px solid #330072;

          &:hover {
            background-color: $colorhover;
          }

          .right-icon-wrapper {
            padding-left: 10px;

            .right-icon {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

/*estimation loading page css*/
.estimationLoadingPage-container {
  display: flex;
  flex-direction: row;

  .estimationLoadingPage-page-details {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    .Need-help {
      display: none;

      @media screen and (min-width: 768px) {
        color: $colorDarkGrey;
        font-family: 'Poppins-Regular';
        font-size: 12px;
        float: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 44px;
        margin-bottom: 53px;
      }

      .number-font {
        color: $colorDarkGrey;
        font-family: 'Poppins-SemiBold';
        font-size: 18px;
        font-weight: 600;
      }
    }

    .estimationLoadingPage-message {
      margin-left: 15px;
      margin-right: 15px;

      @media screen and (min-width: 991.98px) {
        //padding: 1.5rem;
        margin-left: 80px;
        margin-right: 85px;
        padding: 0;
      }

      .font-emphasis {
        font-family: 'Poppins-Bold';
        font-size: 32px;
        font-weight: bold;
        line-height: 1.5;

        @media screen and (min-width: 768px) {
          font-size: 42px;
        }
      }

      .loading-headers {
        margin-bottom: 30px;

        .lenders-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 5px;

          .estimationLoadingPage-name {
            color: #66696c;
            font-family: 'Poppins-Medium';
            font-size: 18px;
            font-weight: 500;
            padding-top: 13px;
            padding-bottom: 13px;
            margin-left: 10px;
            line-height: 1;

            @media screen and (min-width: 576px) {
              font-size: 30px;
              margin-left: 10px;
              padding-top: 13px;
              padding-bottom: 13px;
            }
          }
        }
      }

      .estimationLoadingPage-title {
        margin-bottom: 2rem;
        color: $colorDarkGrey;
        font-family: 'Poppins-Bold';
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 1;
        margin-bottom: 5px;
        padding-top: 15px;
        padding-bottom: 15px;

        @media screen and (min-width: 576px) {
          font-size: 32px;
          margin-bottom: 5px;
          padding-top: 15px;
          padding-bottom: 15px;
        }

        .loan-options-loadingpage {
          background-color: $colorTexthighlight;
          padding: 0 10px;
        }
      }

      .estimationLoadingPage-subtitle {
        color: #66696c;
        font-family: 'Poppins-Regular';
        font-size: 16px;
        margin-bottom: 60px;
        padding-top: 6.5px;
        padding-bottom: 6.5px;
        line-height: 1;

        @media screen and (min-width: 576px) {
          font-size: 22px;
          margin-bottom: 60px;
          padding-top: 6.5px;
          padding-bottom: 6.5px;
        }
      }

      .lenders-logo-container {
        display: grid;
        //@extend: repeat(2, auto);
        row-gap: 24px;
        grid-template-columns: repeat(2, minmax(90px, 1fr));

        .angle_finance {
          @include logo-align('../../../assets/image/angle-finance-logo.png', 94px, 44px);
        }

        .metro-finance {
          @include logo-align('../../../assets/image/metro-finance-logo.png', 100px, 42px);
        }

        .PepperMoney {
          @include logo-align('../../../assets/image/pepper-money.png', 90px, 25px);
        }

        .liberty-financial {
          @include logo-align('../../../assets/image/liberty-financial-logo.png', 90px, 21px);
        }

        .plenti {
          @include logo-align('../../../assets/image/Plenti-logo.svg', 40px, 41px);
        }

        .volkswagen {
          @include logo-align('../../../assets/image/volkswagen.png', 40px, 41px);
        }

        .racv {
          @include logo-align('../../../assets/image/RACV.png', 70px, 39px);
        }

        .Westpac {
          @include logo-align('../../../assets/image/Westpac.png', 80px, 16px);
        }

        .anz {
          @include logo-align('../../../assets/image/anz.png', 77px, 26px);
        }

        .BOQcolour {
          @include logo-align('../../../assets/image/BOQ - colour.png', 89px, 24px);
        }

        .latititude {
          @include logo-align('../../../assets/image/Latitude-transparent.png', 89px, 35px);
        }

        .firstMac {
          @include logo-align('../../../assets/image/Firstmac.svg', 89px, 35px);
        }

        .moneyPlace {
          @include logo-align('../../../assets/image/Moneyplace.svg', 89px, 35px);
        }

        .financelogo {
          @include logo-align('../../../assets/image/finance-1-logo.png', 92px, 24px);
        }

        .money3 {
          @include logo-align('../../../assets/image/money3.png', 92px, 24px);
        }

        @media screen and (min-width: 576px) {
          grid-template-columns: repeat(2, auto);
          row-gap: 70px;

          .angle_finance {
            @include logo-align('../../../assets/image/angle-finance-logo.png', 125px, 72px);
          }

          .metro-finance {
            @include logo-align('../../../assets/image/metro-finance-logo.png', 122px, 58px);
          }

          .PepperMoney {
            @include logo-align('../../../assets/image/pepper-money.png', 145px, 41px);
          }

          .liberty-financial {
            @include logo-align('../../../assets/image/liberty-financial-logo.png', 123px, 28px);
          }

          .plenti {
            @include logo-align('../../../assets/image/Plenti-logo.svg', 117px, 39px);
          }

          .volkswagen {
            @include logo-align('../../../assets/image/volkswagen.png', 62px, 63px);
          }

          .racv {
            @include logo-align('../../../assets/image/RACV.png', 101px, 56px);
          }

          .Westpac {
            @include logo-align('../../../assets/image/Westpac.png', 166px, 33px);
          }

          .anz {
            @include logo-align('../../../assets/image/anz.png', 120px, 40px);
          }

          .BOQcolour {
            @include logo-align('../../../assets/image/BOQ - colour.png', 119px, 33px);
          }

          .latititude {
            @include logo-align('../../../assets/image/Latitude-transparent.png', 160px, 64px);
          }

          .firstMac {
            @include logo-align('../../../assets/image/Firstmac.svg', 160px, 64px);
          }

          .moneyPlace {
            @include logo-align('../../../assets/image/Moneyplace.svg', 160px, 64px);
          }

          .financelogo {
            @include logo-align('../../../assets/image/finance-1-logo.png', 148px, 38px);
          }

          .money3 {
            @include logo-align('../../../assets/image/money3.png', 148px, 38px);
          }
        }

        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(3, auto);
          row-gap: 70px;
          column-gap: 164px;
        }

        .lenderimages-container {
          height: 64px;
          width: auto;
          border-radius: 4px;
          border: solid 1px #e5e7e7;
          background-color: #f4f5f5;
          align-items: center;
          margin-left: 0;
          //display: flex;
          //height: 80%;
          //float: left;
          //margin: 15px 10px 10px 37px;
          //text-align: center;
        }
      }
    }
  }
}

.disclaimer {
  position: relative;
  font-family: 'Poppins-Regular';
  overflow-wrap: break-word;
  color: $colorSubheadingDesc;
  margin-top: 40px;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 20px;
  font-size: 16px;

  @media screen and (min-width: 768px) {
    margin-top: 60px;
    padding-left: 32px;
    padding-right: 32px;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 24px;
  }

  @media screen and (min-width: 1199px) {
    margin-left: 80px;
    margin-right: 134px;
  }
}