@import '../../../styles/global.scss';
@import '../../../styles/variable.scss';

// added changes for dashboard
.dashboard-main-container {
  .dashboard-main-tab-container {
    .dashboard-main-subcontainer {
      .dashboard-buttons {
        .dashboard-main-section {
          .dashboard-header {
            display: flex;
            flex-flow: column;
            .dashboard-title {
              font-family: 'Poppins-Medium';
              //font-size: 30px;
              font-weight: 500;
              color: $colorDarkGrey;
            }
            .dashboard-subtitle {
              font-family: 'Poppins-Regular';
              font-size: 16px;
              color: #66696c;
            }
          }

          .card {
            .card-subtitle {
              color: #66696c;
              font-family: 'Poppins-Regular';
              font-size: 17px;
            }
            .card-title {
              color: $colorDarkGrey;
              font-family: 'Poppins-Regular';
              font-size: 26px;

              &.card-title-capitalise {
                text-transform: capitalize;
              }
            }

            .dashboard-loandetails {
              display: flex;
              flex-flow: row;
              justify-content: flex-start;
              text-align: start;
              .dashboard-loanamt-form {
                .dashboard-loanamt-group {
                  display: flex;
                  flex-flow: column;
                  align-items: baseline;
                  justify-content: flex-start;
                  .dashboard-loanamt-value {
                    font-family: 'Poppins-Regular';
                    font-size: 26px;
                    font-weight: 600;
                    color: $colorDarkGrey;
                  }

                  .label-color {
                    color: #66696c;
                    font-family: 'Poppins-Regular';
                    font-size: 12px;
                  }
                }
              }
              .last-update {
                font-family: 'Poppins-Italic';
                font-size: 12px;
                color: #66696c;
                font-style: italic;
              }
            }
            .new-app-btn {
              float: left;
              border-radius: 3px;
              font-size: 17px;
            }
          }
          .newapplication {
            border: dashed 2px #c2c6c8;
            flex: 1;
            .newapplication-section {
              text-align: center;
              .newapplication-title {
                color: $colorDarkGrey;
                font-family: 'Poppins-SemiBold';
                font-size: 26px;
                line-height: 30px;
              }
              .newapplication-subtitle {
                font-family: 'Poppins-Regular';
                color: #66696c;
                text-align: center;
                //font-size: 17px;
              }
              .new-app-btn {
                border-radius: 3px;
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
}


.message-title {
  margin-top: 50px;
  text-align: center;
  color: #25282a;
  font-family: "Poppins-Bold";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;

  @media screen and (min-width: 768px) {
    font-size: 36px;
    line-height: 40px;
  }
}

.dashboard-modal {
  .dashboard-modal-header {
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
    font-weight: 600;
    color: $colorDarkGrey;
    border-radius: 3px;
    background-color: #ffffff;
  }
  .dashboard-modal-body {
    .dashboard-modal-content {
      text-align: center;
      font-family: 'Poppins-Regular';
      font-size: 16px;
      color: $colorDarkGrey;
    }
    .dashboard-modal-btn {
      display: flex;
      justify-content: center;
      .modal-continue {
        height: 48px;
        border-radius: 3px;
        color: #66696c;
        font-family: 'Poppins-Medium';
        font-size: 16px;
        color: #ffffff;
        background-color: $colorPrimaryBlue;
        font-family: 'Poppins-Medium';

        &:not(:disabled):hover,
        &:not(:disabled):focus,
        &:not(:disabled):active {
          background-color: $colorPrimaryBlue !important;
          opacity: 0.6;
        }
      }
      .modal-cancel {
        height: 48px;
        border-radius: 3px;
        background-color: #e4e8ea;
        color: #66696c;
        font-family: 'Poppins-Medium';
        font-size: 16px;
      }
    }
  }
  .dashboard-modal-footer {
    font-family: 'Poppins-Regular';
    font-size: 15px;
    color: $colorDarkGrey;
    border-top: none;
    border-radius: 3px;
    background-color: #ffffff;
  }
}
//dashboard new changes

.application-header {
  font-family: 'Poppins-Bold';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: $colorDarkGrey;
  @media screen and (min-width: 575.95px) {
    font-size: 30px;
    line-height: 45px;
  }
}
.applciation-description {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: $colorSubheadingDesc;
  margin-bottom: 20px;
  @media screen and (min-width: 576.95px) {
    margin-bottom: 30px;
    font-size: 17px;
    line-height: 25px;
  }
}
.applicant {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  @media screen and (min-width: 575.95px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.co-applicant-align {
      flex-direction: column;
    }
  }

  .existing-application-block {
    padding: 12px;
    flex: 1 1;
    margin-right: 0;
    margin-bottom: 20px;
    border-style: none;
    background-color: #ffffff;
    flex-basis: 100%;
    max-width: 100%;
    @media screen and (min-width: 575.95px) {
      margin-right: 45px;
      padding: 54.5px 31.5px 54.5px 31.5px;
      flex-basis: 50%;
      max-width: 50%;
      &.co-applicant-align {
        width: 50%;
      }
    }
    .existing-app-card {
      text-align: start;
      .progress-bar {
        background-color: #f1f2f2;
        margin: 30px -12px;
        @media screen and (min-width: 575.95px) {
          background-color: #f1f2f2;
          margin: 20px -30px;
        }
      }
      .opp-make-model-display {
        margin-bottom: 12px;
        @media screen and (min-width: 576.95px) {
          margin-bottom: 30px;
        }
        .opp-year {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21fnewpx;
          color: $colorSubheadingDesc;
          @media screen and (min-width: 576.95px) {
            font-size: 17px;
            line-height: 25px;
          }
        }
        .opp-make-model {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          color: $colorDarkGrey;
          text-transform: capitalize;
          @media screen and (min-width: 576.95px) {
            font-size: 26px;
            line-height: 39px;
          }
        }
      }
      .opp-stage {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        color: $colorSubheadingDesc;
      }
      .progress-bar {
        margin-bottom: 12px;
        @media screen and (min-width: 576.95px) {
          margin-bottom: 30px;
        }
      }
      .dashboard-loandetails {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        .loan-amount-val {
          font-family: 'Poppins-Bold';
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 33px;
          color: $colorDarkGrey;
        }
        .label-color {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: $colorSubtitle;
        }
      }
    }
  }
  .newapplication {
    border: dashed 2px #c2c6c8;
    flex: 1;
    padding: 12px;
    margin-bottom: 16px;
    @media screen and (min-width: 576.95px) {
      padding: 54.5px 31.5px 54.5px 31.5px;
    }
    .newapplication-section {
      text-align: center;
      .newapplication-title {
        color: $colorDarkGrey;
        font-family: 'Poppins-SemiBold';
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        @media screen and (min-width: 576.95px) {
          font-size: 26px;
          line-height: 30px;
        }
      }
      .newapplication-subtitle {
        font-family: 'Poppins-Regular';
        color: $colorSubheadingDesc;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        @media screen and (min-width: 576.95px) {
          font-size: 17px;
          line-height: 25px;
        }
      }
    }
  }
}
.start-new-application {
  margin-top: 20px;
  &.existing-application {
    margin-top: 20px;
    display: block;
    @media screen and (min-width: 576.95px) {
      display: none;
    }
  }

  .letsgetstarted-btn {
    min-width: 100%;
    &.continue-existing-application {
      min-width: 100%;
      // text-align: justify;
      @media screen and (min-width: 768px) {
        text-align: center;
        min-width: 195px;
        font-size: 16px;
        line-height: 24px;
      }
    }
    @media screen and (min-width: 768px) {
      text-align: center;
      min-width: 50%;
    }
  }
  @media screen and (min-width: 576.95px) {
    margin-top: 30px;
    white-space: nowrap;
  }

  .image-align {
    margin-left: 10px;
  }
}

@media screen and (min-width: 576.95px) {
  .email-quote.start-new-application.text-right {
    margin-top: 0px;
    white-space: nowrap;
    margin-bottom: 5%;
  }
}
.nav-dashboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-bottom: 30px;
  .leftarrow-img {
    height: 22px;
    width: 22px;
    margin-right: 10px;
  }
  .dashboard-label {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 33px;
    color: $colorDarkGrey;
  }
}
.customer-dashboard {
  .customer-dashboard-wrapper {
    display: flex;
    flex-flow: column-reverse;
    flex-wrap: wrap;

    @media screen and (min-width: 960px) {
      flex-flow: row;
    }
  }

  .customer-dashboard-container {
    .line-across {
      border-top: 1px solid #f1f2f2;
    }

    &.customer-dashboard-doc-Verification-sec {
      flex-basis: 100%;
      // .MuiBox-root {
      //   padding: 12px;
      //   @media screen and (min-width: 576px) {
      //     padding: 24px;
      //   }
      // }
    }
    .default-car-details {
      padding: 20px;
      padding-left: 51px;

      .section-details-label {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: $colorSubtitle;

        @media screen and (min-width: 600px) {
          font-size: 14px;
        }
      }
      .car-details {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        .car-icon {
          width: 120px;
          height: 120px;
          margin-right: 30px;
        }
        .year-make {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: $colorDarkGrey;
          word-break: break-all;
        }
        .engine-bodytype {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: $colorSubheadingDesc;
        }
      }
      .loan-summary-section {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        margin-bottom: 12px;

        @media screen and (min-width: 600px) {
          flex-flow: row;
          margin-bottom: 20px;
        }

        .info-row {
          display: flex;
          flex-flow: column;
          margin-bottom: 12px;

          @media screen and (min-width: 600px) {
            margin-bottom: 0;
            flex: 1;
          }

          &.info-row-align {
            align-items: flex-start;
          }
          .info-details-label {
            font-family: 'Poppins-Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 33px;
            color: $colorDarkGrey;
          }
          .info-details-value {
            font-size: 12px;
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: normal;
            line-height: 21px;
            color: $colorSubheadingDesc;
            text-transform: capitalize;
            @media screen and (min-width: 600px) {
              font-size: 14px;
            }
          }
        }
      }
    }
    .default-car-details-loan {
      padding: 1rem 25px;
      .section-details-label {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 21px;
        color: $colorSubtitle;

        @media screen and (min-width: 600px) {
          font-size: 14px;
        }
      }
      .car-details {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        .car-icon {
          width: 120px;
          height: 120px;
          margin-right: 30px;
        }
        .year-make {
          font-family: 'Poppins-Medium';
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: $colorDarkGrey;
          word-break: break-all;
        }
        .engine-bodytype {
          font-family: 'Poppins-Regular';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: $colorSubheadingDesc;
        }
      }
      .loan-summary-section {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        margin-bottom: 12px;

        @media screen and (min-width: 600px) {
          flex-flow: row;
          margin-bottom: 20px;
        }

        .info-row {
          display: flex;
          flex-flow: column;
          margin-bottom: 12px;

          @media screen and (min-width: 600px) {
            margin-bottom: 0;
            flex: 1;
          }

          &.info-row-align {
            align-items: flex-start;
          }
          .info-details-label {
            font-family: 'Poppins-Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 33px;
            color: $colorDarkGrey;
          }
          .info-details-value {
            font-size: 12px;
            font-family: 'Poppins-Regular';
            font-style: normal;
            font-weight: normal;
            line-height: 21px;
            color: $colorSubheadingDesc;
            text-transform: capitalize;
            @media screen and (min-width: 600px) {
              font-size: 14px;
            }
          }
        }
      }
    }
    .loan-summary-container {
      border-radius: 9px;
      border: 1px solid #f1f2f2;
      padding: 2rem 2rem;
    }

    .loan-summary-text {
      padding: 2rem 0px;
      padding-left: 25px;
    }

    .email-quote {
      &.show-xs {
        display: block;
        @media screen and (min-width: 960px) {
          display: none;
        }

        button {
          width: 100%;
          @media screen and (min-width: 600px) {
            width: auto;
          }
        }
      }
    }
  }
  .hide-stepper-dashboard {
    display: none;
  }
  .stepper-dashboard {
    margin-bottom: 5px;
    display: block;

    @media screen and (min-width: 960px) {
      background: #ffffff;
      border-radius: 3px 3px 0px 0px;
      flex-basis: 30%;
      margin-left: 30px;
      padding: 20px;
      margin-bottom: 0;
    }

    .application-process-label {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $colorSubtitle;
      margin-bottom: 30px;
    }

    .customer-dashboard-horizantal {
      margin: 0;
      margin-left: -20px;
      margin-right: -20px;
    }

    @media screen and (max-width: 959.95px) {
      .application-process-label,
      .customer-dashboard-horizantal,
      .email-quote {
        display: none;
      }
    }

    .opportunity-select {
      margin: 20px 0px 0px auto;
      text-align: left;
      .image-align {
        margin-left: 10px;
      }
    }
  }
}

.custom-dashboard-stepper {
  .stepper {
    .rc-steps-item {
      .rc-steps-item-container {
        .rc-steps-item-tail {
          &::after {
            display: none;
          }
        }
        .rc-steps-item-content {
          .rc-steps-item-title {
            flex-flow: column !important;
          }
          .rc-steps-item-description {
            .sub-steps {
              .rc-steps-item {
                .rc-steps-item-container {
                  .rc-steps-item-tail {
                    &::after {
                      display: none;
                    }
                  }
                }

                &.rc-steps-item-finish {
                  .rc-steps-item-container {
                    .rc-steps-item-icon {
                      background-color: $colorSubStepInactive;
                      border-color: $colorSubStepInactive;
                    }
                  }
                }

                &.rc-steps-item-active {
                  .rc-steps-item-container {
                    .rc-steps-item-content {
                      .rc-steps-item-title {
                        .step-title {
                          font-family: 'Poppins-Bold';
                          color: $colorDarkGrey;
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.document-list-content {
  margin-left: -20px;
  margin-right: -20px;
  .document-list-table {
    border-collapse: collapse;
    width: 100%;
    .document-header-label {
      font-family: 'Poppins-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $colorSubtitle;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 0;
      padding-top: 0;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
    .document-list-body-row {
      .document-list-body-label {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $colorDarkGrey;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        padding-right: 0;
        text-align: left;
        border-bottom: 1px solid #ddd;
      }

      .document-list-view-label {
        font-family: 'Poppins-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $colorSubtitle;
        padding: 20px;
        padding-left: 20px;
        text-align: left;
        border-bottom: 1px solid #ddd;
        .download-icon {
          margin-right: 10px;
          .downlaod-img {
            height: 18px;
            width: 18px;
          }
        }
      }
    }
  }
}

.dashboard-privacy-container {
  .modal-dialog {
    transform: translateY(10px) !important;
  }
  .finances-header-container {
    .privacy-header-section {
      div {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
      }
      @media screen and (min-width: 568px) {
        div {
          font-weight: bold;
          font-size: 24px;
          line-height: 36px;
        }
      }
      .welcome {
        color: $colorSecondaryBlue;
      }
      .stratton-finance-quote {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $colorSubheadingDesc;
      }
    }
  }

  .privacy-body-section {
    &.in-progress {
      text-align: center;
    }

    .applicant-name {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $colorSubheadingDesc;
      .applicant-name-font {
        color: $colorDarkGrey;
      }
    }

    .privacy-subtitle {
      font-size: 14px;
      line-height: 21px;
      color: $colorSubheadingDesc;
      margin-bottom: 12px;
    }

    .fieldset-section {
      margin-bottom: 1rem;
      .privacy-consent {
        max-height: 160px;
        &.credit-quote-consent {
          max-height: 20vh;
        }
      }
      .scroll-to-up-bottom {
        position: absolute;
        left: 45%;
        bottom: -14px;
        width: 24px;
        height: 24px;
        color: transparent;
        background-color: transparent;
        border: none;
        outline: none;
        &.to-bottom {
          background: url('../../../assets/image/Chevron-down.svg');
        }
        &.to-up {
          background: url('../../../assets/image/Chevron-Up.svg');
        }
        &:focus,
        &:hover,
        &:active {
          opacity: 0.75;
        }
      }
    }

    .privacy-policy {
      margin-top: 12px;

      .checkbox-container {
        font-size: 12px;
        line-height: 18px;
        color: $colorSubtitle;
        .credit-quote-checkbox-color {
          color: $colorAcknowledge;
        }
      }
    }
  }
}

.details-application-text {
  font-size: 15px;
  line-height: 22.5px;
  font-weight: 600;
  font-family: 'Poppins-Regular';
}

.details-application-description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Poppins-Regular';
}

.details-application-number {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: 'Poppins-Regular';
  color: #01b0c2;
  display: flex;
}
.details-application-number-text {
  margin-left: 7px;
}
