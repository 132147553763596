@import './variable.scss';

@mixin select-buttons {
  display: flex;

  justify-content: center;
  align-items: center;
  width: 160px;
  height: 90px;
  border-radius: 3px;
  border: solid 1px #c2c6c8;
  background-color: #fff;
  font-family: 'Poppins-Regular';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  color: $colorDarkGrey;
  //text-transform: capitalize;
  line-height: 26px;

  &.with-description {
    flex-flow: column;
    justify-content: space-evenly;

    hr {
      width: 50%;
    }

    .select-field-btn-desc {
      font-size: 12px;
    }
  }

  &.select-btn-img {
    font-family: 'Poppins-Medium';
    flex-direction: column;
    font-size: 12px;
    line-height: 18px;
    width: 120px !important;
    height: 70px !important;
    padding: 0;
    .icon-width {
      margin-bottom: 10px;
      width: 24px;
      height: 24px;
      @media screen and (min-width: 576px) {
        width: 26.97px;
        height: 36.67px;
      }
    }
    @media screen and (min-width: 576px) {
      width: 160px !important;
      height: 117px !important;
      font-size: 15px;
      line-height: 22px;
    }
  }
  &:focus,
  &:active {
    // color: $colorWhite;
    //box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    //background-color: #01b0c2;
    box-shadow: none;
    outline: none;
  }

  &.is-selected {
    box-sizing: border-box;
    border: 2px solid #01b0c2;
    background-color: rgba(224, 248, 250, 0.5);
    color: $colorDarkGrey;
    font-family: 'Poppins-SemiBold';
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    &.with-description {
      hr {
        border-color: #01b0c2;
      }
    }
    &.select-btn-img {
      font-family: 'Poppins-Medium';
      flex-direction: column;
      font-size: 12px;
      line-height: 18px;
      width: 120px !important;
      height: 70px !important;
      padding: 0;
      .icon-width {
        margin-bottom: 10px;
        width: 24px;
        height: 24px;
        @media screen and (min-width: 576px) {
          width: 26.97px;
          height: 36.67px;
        }
      }
      @media screen and (min-width: 576px) {
        width: 160px !important;
        height: 117px !important;
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
  @media screen and (min-width: 600px) {
    font-family: 'Poppins-Regular';
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: $colorDarkGrey;
  }
}

@mixin privacy-statement {
  .checkbox-container {
    display: block;
    position: relative;
    padding-left: 36px;
    //padding-top: 8px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Poppins-Regular';
    font-size: 14px;
    color: $colorDarkGrey;

    @media screen and (min-width: 768px) {
      padding-top: 30px;
      padding-left: 44px;
    }
    &.checkbox-color {
      color: #66696c;
      font-size: 12px;
    }
    &.remember-me {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $colorDarkGrey;
      margin: 0;
    }
    &.acknowledge-message {
      font-family: 'Poppins-Regular';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $colorAcknowledge;
      text-align: justify;
    }
  }
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 6px;
    left: -12px;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    border: solid 1px #bebebf;
    background-image: linear-gradient(to bottom, #fff, #f9fafb);

    @media screen and (min-width: 768px) {
      top: 35px;
      left: -8px;
    }
  }
  .checkbox-container:hover input ~ .checkmark {
    opacity: 0.8;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    border-color: #00aabb;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 9px;
    top: 2px;
    width: 6px;
    height: 13px;
    border: solid #00aabb;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.scrollable-anchor-tag {
  position: relative;

  > a {
    position: absolute;
    visibility: hidden;
    left: 0px;
    top: -314px;

    @media screen and (min-width: 600px) {
      top: -205px;
    }
  }
}

.scrollable-anchor-tag {
  &.with-sidebar {
    > a {
      top: -144px;

      @media screen and (min-width: 600px) {
        top: -172px;
      }

      @media screen and (min-width: 960px) {
        top: -112px;
      }
    }
  }
}

.full-width-container {
  .scrollable-anchor-tag {
    > a {
      top: -105px;
    }
  }
}

.btn-dummy-page {
  padding: 0;
  margin: 0;
  border: none;
}

.deposit {
  &.scrollable-anchor-tag {
    > a {
      top: -298px;
    }
  }
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #c2c6c8;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c2c6c8;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c2c6c8;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
fieldset {
  position: relative;
}
.legend {
  position: absolute;
  top: -0.2em;
  right: 20px;
  line-height: 1.2em;
  height: 30px;
  border-radius: 3px;
  background-color: #f3f7fa;
}

//common main pages button size
.email-quote {
  //display: flex;
  //flex-direction: column;
  &.compare-loan-options {
    text-align: end;
    width: 100%;
    @media screen and (min-width: 768px) {
      text-align: end;
      width: auto;
    }
  }
  .letsgetstarted-btn {
    height: 56px;
    border-radius: 3px;
    background-color: #330072;
    color: #ffffff;
    font-family: 'Poppins-Medium';
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    &:hover {
      background-color: $colorhover;
    }
    &:disabled {
      background-color: $colorInactive;
      border-color: $colorInactive;
      color: #ffffff;
    }
    &:not(:disabled):focus,
    &:not(:disabled):active {
      background-color: $colorPrimaryBlue !important;
      color: #ffffff;
      opacity: 0.6;
    }
    &.compare-loan-options {
      width: 100%;
      @media screen and (min-width: 576px) {
        text-align: end;
        margin-top: 10px;
        width: auto;
      }
    }
    &.submit-privacy {
      width: 100%;
    }
  }
}
.form-label {
  font-family: 'Poppins-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $colorDarkGrey;
  @media screen and (min-width: 768px) {
    font-size: 15px;
    line-height: 22px;
  }
}
.form-group {
  margin-bottom: 10px;

  .custom-date-picker {
    margin-top: 0;
    margin-bottom: 0;
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #80bdff;
    }
    input {
      padding-top: 15px;
      padding-bottom: 14px;
    }
  }
}

.address-search-container {
  padding: 0;
  margin-bottom: 10px;
  margin: 0;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    flex: 1;
    flex-basis: 65%;
  }

  .content-prepend {
    .address-input-search {
      margin-bottom: 10px;
      @media screen and (min-width: 576px) {
        margin-bottom: 20px;
      }
      .address-input-prepend {
        .address-input-text {
          background-color: transparent;

          &.is-invalid {
            border-color: red;
          }
        }
      }
      .auto-suggest-input {
        border-left: none;
      }
    }
  }
}
//icon foe text field

.icon-group {
  .text-input-prepend {
    .text-input-icon {
      background-color: transparent;
      border-right: none;
      &.is-invalid {
        border-color: red;
      }
    }
  }
  .text-border-remove {
    border-left: none;
  }
}
//button not to have borders
input[type='button'],
input[type='submit'],
button {
  border-width: inherit;
}
.btn-link {
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.finances-body-container {
  .alert-danger {
    margin: 1rem;

    .error-message {
      margin: 0;
    }
  }
}

.modal-95w {
  max-width: 960px;
  margin-top: 5rem;
}
.add-employment-delete-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-top: 1px solid #f1f2f2;
  border-bottom: 1px solid #f1f2f2;
  margin-bottom: 10px;
  margin-left: -12px;
  margin-right: -12px;
  @media screen and (min-width: 576px) {
    padding: 20px;
    padding-left: 36px;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 27px;
    margin-left: -36px;
    margin-right: -36px;
  }
  .add-employment-title {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    @media screen and (min-width: 576px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
  .delete-btn {
    display: none;
    @media screen and (min-width: 576px) {
      display: block;
    }
  }
  .delete-icon {
    display: block;
    @media screen and (min-width: 576px) {
      display: none;
    }
  }
}
.prev-delete-block {
  text-align: end;
}
